import { request } from '@/utils'

export default {
  getTicket: (id: any) => request.get(`/purchase/purchase/partner/queryTicket/${id}`),
  editTicket: (data = {}) => request.post('/purchase/purchase/partner/updateTicket', data),
  getPartnerType: () => request.get('/system/diction/findByCode/purchase_partner_type'),
  getsettleCycle: () => request.get('/system/diction/findByCode/purchase_settle_cycle'),
  getPartnerContact: () => request.get('/system/diction/findByCode/purchase_partner_contact'),
  getCategory: () => request.get('/purchase/purchase/supply/service/type/categoryCascadeQuery'),
  getBrand: () => request.get('/purchase/purchase/supply/service/type/brandCascadeQuery'),
  download: (data: Recordable) =>
    request.post('/purchase/purchase/partnerV2/export', data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),

  addPartner: (data = {}) => request.post('/purchase/purchase/partnerV2/add', data),
  editPartner: (data = {}) => request.post('/purchase/purchase/partnerV2/edit', data),
  getPartner: (id: any) => request.get(`/purchase/purchase/partnerV2/getDetail?id=${id}`),
  getInvoiceDetail: (id: any) => request.get(`/purchase/purchase/partnerV2/getInvoiceDetail?id=${id}`),
  getPartnerList: (params: PageParams) =>
    request.post(`/purchase/purchase/partnerV2/pageList?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}`, params.body || {}),
  addTicket: (data = {}) => request.post('/purchase/purchase/partnerV2/saveOrUpdateInvoiceDetail', data),
  pageListCategory: (data = {}) => request.post('/purchase/purchase/partnerV2/pageListCategory', data),
  getChinaProvinceCityRegion: () => request.get('/system/system/open/getChinaProvinceCityRegion'),
}

import { request } from '@/utils'

export default {
  getProjectPerson: (data = {}) => request.post('/project/proj/userList', data),
  getDirectionByCode: (code: string) => request.get(`/system/diction/findByCode/${code}`),
  getBrand: () => request.get('/purchase/purchase/supply/service/type/brandCascadeQuery'),
  getMainPrincipal: (data: any) => request.post('/project/project/open/queryProjectUserAppoint', data),
  brandReportList: (params: PageParams) =>
    request.post(`project/start/brandReportList?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}`, params || {}),
  getCategoryCascade: () => request.get('/purchase/purchase/supply/service/type/categoryCascadeQuery'),
  getBrandCascade: () => request.get('/purchase/purchase/supply/service/type/brandCascadeQuery'),
  applyReportSubmit: (data: any) => request.post('project/start/applyReportSubmit', data),
  findApplyReport: (projectId: string) => request.get(`/project/start/findApplyReport?projectId=${projectId}`),
}

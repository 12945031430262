import { saveAs } from 'file-saver'

export const MAX_FILE_SIZE = 2 * 1024 * 1024 * 1024

export function downloadFile(resp: any) {
  const contentDisposition = resp.headers['content-disposition'] || resp.headers['Content-Disposition']
  let fileName
  if (contentDisposition && contentDisposition.length) {
    fileName = decodeURIComponent(contentDisposition.substring(contentDisposition.indexOf('=') + 1))
  }

  fileName = fileName || 'demo.xls'
  window.$message?.success(`${fileName} 正在下载`)
  saveAs(new Blob([resp.data]), fileName)
}

export function filePreview(url: string) {
  if (url) {
    window.open(`${import.meta.env.VITE_KKFILE_URL}?url=${encodeURIComponent(window.btoa(url))}`)
  } else {
    window.$message?.error('当前文件暂无预览')
  }
}

export function createDownload(blob: Blob, fileName: string, fileType: string) {
  if (!blob || !fileName || !fileType) {
    return
  }
  const element = document.createElement('a')
  const url = window.URL.createObjectURL(blob)
  element.style.display = 'none'
  element.href = url
  element.download = `${fileName}.${fileType}`
  document.body.appendChild(element)
  element.click()
  if (window.URL) {
    window.URL.revokeObjectURL(url)
  } else {
    window.webkitURL.revokeObjectURL(url)
  }
  document.body.removeChild(element)
}

export const download = (url: string, name: string) => {
  if (!url) {
    return
  }
  const a = document.createElement('a')
  a.href = url
  if (name !== undefined) {
    a.download = name
  }
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export const uploadWithCmd = (onChange: (files: FileList | null) => void) => {
  const files = ref<FileList | null>(null)
  // const { on: onChange, trigger } = createEventHook()
  const input: HTMLInputElement = document.createElement('input')
  input.type = 'file'

  input.onchange = (event: Event) => {
    const result = event.target as HTMLInputElement
    files.value = result.files
    onChange(files.value)
  }

  const open = () => {
    if (!input) {
      return
    }
    input.multiple = true
    // input.accept = _options.accept!
    // webkitdirectory key is not stabled, maybe replaced in the future.
    input.webkitdirectory = false
    files.value = null
    input.click()
  }

  return {
    open,
  }
}

export enum FileTypeEnum {
  DIR = 'directory',
  FILE = 'file',
}

export function isDir(option: any) {
  return option.fileType === FileTypeEnum.DIR
}

import { request } from '@/utils'
import type { OmTodoQuery, OmTodoTask, OmTodoTaskAborted } from '@/views/om/todo/types'

export default {
  getEmptyQuery: (): OmTodoQuery => {
    return {
      status: '1',
      createBy: null,
      executorId: null,
      emergency: null,
      title: '',
    }
  },
  getEmptyTask: (): OmTodoTask => {
    return {
      allowEdit: true,
      backlog: '',
      emergency: 1,
      executorIds: [],
      orgId: '',
      feedbackDTOS: [],
      customerDTO: {
        name: '',
        phone: '',
      },
      remark: '',
      taskDate: null,
      todoList: '',
      taskId: '',
    }
  },
  getEmptyTaskAborted: (): OmTodoTaskAborted => {
    return {
      summary: '',
    }
  },

  /** 查询待办 */
  getTodoList: (params: PageParams) =>
    request.post(`/ops/om/agenda/pageList?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`, params.body || {}),
  /** 获取任务详情 */
  getTaskDetail: (id: string) => request.get(`/ops/om/agenda/get?id=${id}`),
  /** 保存任务 */
  saveTask: (data: Recordable) => request.post('/ops/om/agenda/addOrEdit', data),
  /** 终止任务 */
  abortTask: (data: Recordable) => request.post('/ops/om/agenda/terminal', data),
  /** 获取客户单位下拉 */
  queryCustomerOrg: () => request.get('/ops/om/agenda/queryCustomerOrg'),
  /** 获取执行人下拉 */
  queryExecutors: (orgId: string) => request.get(`/ops/om/agenda/queryExecutors?orgId=${orgId}`),
  /** 获取任务类型下拉 */
  queryTaskByCustomerOrg: (orgId: string) => request.get(`/ops/om/agenda/queryTaskByCustomerOrg?customerOrgId=${orgId}`),
  /** 获取所有执行人 */
  queryAllExecutors: () => request.get(`/ops/om/agenda/queryAllExecutors`),
  /** 获取创建人下拉 */
  queryCreate: () => request.get(`/ops/om/agenda/queryCreate`),
  /** 修改客户可见 */
  changeVisible: (params: Recordable) =>
    request.post('/ops/om/agenda/visible', null, {
      params,
    }),
}

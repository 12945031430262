import api from './api'
import type { ResponseResult } from '~/types/axios'

async function Middleware(req: (body: any) => Promise<ResponseResult<any>>, params: any, original = false) {
  const res = await req(params)
  if (original) {
    return res
  } else {
    return res.data
  }
}

export const service = {
  // getMobileTaskReportExport: (params: any) => Middleware(api.getMobileTaskReportExport, params, true),
  getDirectionByCode: (params: any) => Middleware(api.getDirectionByCode, params),
  getSelectUser: (params: any) => Middleware(api.getSelectUser, params),

  getPageList: (params: any) => Middleware(api.getPageList, params),
  getCollectInfo: (params: any) => Middleware(api.getCollectInfo, params),
  getSchemeBrandChart: (projectId: string, schemeId: string) => Middleware(api.getSchemeBrandChart, { projectId, schemeId }),
  // getResidentTaskPage: (params: any) => Middleware(api.getResidentTaskPage, params),
  // getResidentTaskReportStatistics: (params: any) => Middleware(api.getResidentTaskReportStatistics, params),
  getExport: (params: any) => Middleware(api.getExport, params, true),
}

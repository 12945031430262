import { request } from '@/utils'

export default {
  getEmptyRecommendBrand: (categoryId?: string, name?: string) => {
    return {
      categoryId,
      brand: null,
      name,
    }
  },
  // (分页)分类查询品类
  getPageListCategory: (body: PageParams) => {
    if (body.lastType)
      return request.post(
        `/purchase/purchase/supply/service/typeV2/pageListCategory?pageSize=${body.pageSize}&pageNumber=${body.pageNumber}`,
        body || {}
      )
    else
      return request.post(
        `/purchase/purchase/supply/service/typeV2/pageListTypeByPid?pageSize=${body.pageSize}&pageNumber=${body.pageNumber}`,
        body || {}
      )
  },

  // 分类的级联查询
  getTypeCascadeQuery: (params?: { keyword?: string }) =>
    request.get('/purchase/purchase/supply/service/typeV2/typeCascadeQuery', { params }),

  getCategory: () => request.get('/purchase/purchase/supply/service/type/categoryCascadeQuery'),
  getBrand: () => request.get('/purchase/purchase/supply/service/type/brandCascadeQuery'),
  addClassification: (data: Recordable) => request.post('/purchase/purchase/supply/service/typeV2/add', data),
  addCategory: (data: Recordable) => request.post('/purchase/purchase/supply/service/typeV2/addCategory', data),
  delForCategory: (categoryId: string) => request.get(`/purchase/purchase/supply/service/typeV2/delForCategory?categoryId=${categoryId}`),
  downloadTemplate: () => request.get('/purchase/purchase/supply/service/typeV2/downloadTemplate'),
  editClassification: (data: Recordable) => request.post('/purchase/purchase/supply/service/typeV2/edit', data),
  listTypeByLevel: () => request.get('/purchase/purchase/supply/service/typeV2/listTypeByLevel'),
  listTypeByPid: () => request.get('/purchase/purchase/supply/service/typeV2/listTypeByPid'),
  switchEnable: (data: Recordable) => request.post('/purchase/purchase/supply/service/typeV2/switchEnable', data),
  typeCascadeQuery: (keyword: string) => request.get(`/purchase/purchase/supply/service/typeV2/typeCascadeQuery/${keyword}`),
  getRecommendBrand: (categoryId: string) => request.get(`/purchase/category/recommend/get?categoryId=${categoryId}`),
  saveRecommendBrand: (data: Recordable) => request.post('/purchase/category/recommend/save', data),
  brandCascade: () => request.get('/purchase/purchase/categoryV2/brandCascade'),
  getAllBrand: () => request.get('/purchase/purchase/supply/service/type/qeuryAllbrand'),
  getDirectionByCode: (code: string) => request.get(`/system/diction/findByCode/${code}`),
  // 只查品牌下拉框
  brandSelectDown: () => request.get('/purchase/purchase/categoryV2/brandSelectDown'),
}

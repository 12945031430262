import { request } from '@/utils'

export default {
  // 考勤统计的分页查询
  statisticsPageListByTime: (data: Recordable) => {
    const params = {
      ...data.body,
      pageNumber: data.pageNumber,
      pageSize: data.pageSize,
    }
    return request.post('/ops/attendance/statistics/statisticsPageListByTime', params)
  },
  // 考勤统计的分页查询
  statisticsPageListDetail: (data: Recordable) => {
    const params = {
      ...data.body,
      pageNumber: data.pageNumber,
      pageSize: data.pageSize,
    }
    return request.post('/ops/attendance/statistics/pageListDetail', params)
  },
  // 考勤统计的明细信息
  statisticsSummaryByTime: (data: Recordable) => request.post('/ops/attendance/statistics/statisticsSummaryByTime', data),
  // 获取部门下所有的人员信息
  getAllUserByDeptId: (data: Recordable) => request.post('/ops/attendance/user/getAllUserByDeptId', data),
  // 获取当前人可以查询的所有部门信息
  getAllDeptInfoByUser: (params: Recordable) => request.get('/ops/attendance/user/getAllDeptInfoByUser', { params }),

  // 导出-考勤统计的分页查询
  exportStatisticsPageListByTime: (data?: any) =>
    request.post(`/ops/attendance/statistics/exportStatisticsPageListByTime`, data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),
  // 导出-考勤明细分页查询
  exportPageListDetail: (data?: any) =>
    request.post(`/ops/attendance/statistics/exportPageListDetail`, data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),
  // 导出-邮箱考勤报表
  exportEmailReport: (data?: any) =>
    request.post('/ops/attendance/export/exportHrEmailMonthReport', data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),
  // 导出-验证考勤报表
  exportValidReport: (data?: any) =>
    request.post('/ops/attendance/export/exportHrMonthReport', data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),
  getSignDetail: (id: string) => request.get(`/ops/attendance/statistics/getDetail/${id}`),
  dealClockResult: (data: Recordable) => request.post('/ops/attendance/statistics/dealClockResult', data),
  // 获取加班规则说明参数
  getRuleRemark: (userId: string) => request.get(`/ops/attendance/manager/rule/getRuleRemark?userId=${userId}`),

  /**
   * 异常处理记录
   * @param params
   */
  // 分页列表
  getSignExHistory: (params: PageParams) =>
    request.post(
      `/ops/attendance/statistics/dealClockResultPageList?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`,
      params.body || {}
    ),
  annualLeavePageList: (data: Recordable) => {
    const params = {
      ...data.body,
      pageNumber: data.pageNumber,
      pageSize: data.pageSize,
    }
    return request.post(`/ops/workLeave/annualLeavePageList`, params || {})
  },
  annualLeavePageListExport: (data: Recordable) => {
    const params = {
      ...data.body,
      pageNumber: data.pageNumber,
      pageSize: data.pageSize,
    }
    return request.post(`/ops/workLeave/annualLeavePageListExport`, params || {}, { responseType: 'blob', timeout: 30 * 60 * 1000 })
  },
  leaveRecordHistoryPageList: (data: Recordable) => {
    const params = {
      ...data.body,
      pageNumber: data.pageNumber,
      pageSize: data.pageSize,
    }
    return request.post(`/ops/workLeave/leaveRecordHistoryPageList`, params || {})
  },
}

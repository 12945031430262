import mitt from 'mitt'

interface Events {
  cloudTree: boolean
  cloudTable: boolean
  cloudFavorite: boolean
  cloudInfo: boolean
  cloudPreview: boolean
  [key: string | symbol]: any
}

export const emitter = mitt<Events>()

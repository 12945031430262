import { request } from '@/utils'

export default {
  getEmptyInvocingQuery: () => {
    return {
      keyword: null,
    }
  },
  getInvoiceRecords: (id: string) => request.get(`/project/sys/project/infoCollectionMoney/getApplyInvoicingRecord/${id}`),

  saveApplyInvoicing: (data: any) => request.post('/project/sys/project/infoCollectionMoney/saveApplyInvoicing', data),
  getApplyInvoicingRecord: (id: string) => request.get(`/project/sys/project/infoCollectionMoney/getApplyInvoicingRecord/${id}`),

  getInvoiceByPage: (params: PageParams) =>
    request.post(`/system/system/open/getDataInvoice?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`, params.body || {}),

  getcustomerInvoiceRecords: (id: string) => request.get(`/project/customer/customerOrgInvoice/get/${id}`),

  savecustomerInvoicing: (data: any) => request.post('/project/customer/customerOrgInvoice/save', data),
  getPickupMethod: () => request.get('/system/diction/findByCode/invoice_pickup_method'),
  getChinaProvinceCityRegion: () => request.get('/system/system/open/getChinaProvinceCityRegion'),
  getInvoicingInfo: (projectId: string) => request.get(`/project/sys/project/infoCollectionMoney/getInvoicingInfo?projectId=${projectId}`),
  saveInvoicingInfo: (data: any) =>
    request.post(`/project/sys/project/infoCollectionMoney/saveInvoicingInfo?projectId=${data.projectId}`, data),
  updateApplyInvoicing: (data: any) => request.post(`/project/sys/project/infoCollectionMoney/updateApplyInvoicing?id=${data.id}`, data),

  getProjectInfoContractSettlement: (projectId: string) =>
    request.get(`/project/sys/project/infoCollectionMoney/getProjectInfoContractSettlement/${projectId}`),
  // 获取提醒周期
  getReminderPeriod: () => request.get('/system/diction/findByCode/proj_contract_reminder_period'),
  // 获取结算周期
  getSettlementPeriod: () => request.get('/system/diction/findByCode/proj_settlement_period_type_code'),
  // 获取项目阶段
  getProjectPhase: () => request.get('/system/diction/findByCode/proj_contract_project_phase'),
  getApplyInvoicingRecordByContractSettlementId: (projectId: string, contractSettlementId: string) =>
    request.get(
      `/project/sys/project/infoCollectionMoney/getApplyInvoicingRecordByContractSettlementId/${projectId}?contractSettlementId=${contractSettlementId}`
    ),
  getContractSettlementProjectInfo: (projectId: string) =>
    request.get(`/project/sys/project/infoContract/getContractSettlementProjectInfo/${projectId}`),
  getContractSettlement: (projectId: string, contractSettlementId: string) =>
    request.get(`/project/sys/project/infoContract/getContractSettlement/${projectId}?contractSettlementId=${contractSettlementId}`),
  addContractSettlement: (data: any) => request.post('/project/sys/project/infoContract/addContractSettlement', data),
  updateContractSettlement: (data: any) => request.post('/project/sys/project/infoContract/updateContractSettlement', data),

  // 申请修改预计回款日期
  applyModifyPlanReturnMoneyDate: (data: any) =>
    request.post(`/project/sys/project/infoCollectionMoney/applyModifyPlanReturnMoneyDate?id=${data.id}`, data),
  // 申请修改预计回款日期权限查询
  queryApplyModifyPlanReturnMoneyRule: (id: string) =>
    request.post(`/project/sys/project/infoCollectionMoney/queryApplyModifyPlanReturnMoneyRule?id=${id}`),
  // 查询申请预计回款日期记录
  queryApplyModifyPlanReturnMoneyRecord: (params: any) =>
    request.post(
      `/project/sys/project/infoCollectionMoney/queryApplyModifyPlanReturnMoneyRecord?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`,
      params || {}
    ),
}

import { request } from '@/utils'

export default {
  getEmptyResidentTask: () => {
    return {
      id: null,
      taskName: null,
      source: null,
      formOfService: null,
      situationDesc: null,
      projectId: null,
      customerUnit: null,
      ownerCompany: null,
      serverTimeList: [
        {
          range: null,
          serviceStartTime: null,
          serviceEndTime: null,
        },
      ],
      imageIdList: [],
      customerContactId: [],
      mobileTaskAddressDTO: {
        country: null,
        province: null,
        city: null,
        region: null,
        address: null,
        completeAddress: null,
        longitude: null,
        latitude: null,
      },
      deptId: null,
      status: 0,
      followUserId: null,
      assistantUserId: [],
      meetingSurveyDatetime: null,
    }
  },
  getResidentTaskByPage: (params: PageParams) => {
    return request.post(`/ops/ops/residentTask/pageList?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`, params.body || {})
  },
  getResidentTaskById: (id: string) => request.get(`/project/customer/profile/getDetail/${id}`),

  getUserBySelectCode: (selectCode: string) => request.get(`/ops/ops/taskCustomer/getUserBySelectCode?selectCode=${selectCode}`),
  // saveResidentTask: (data: Recordable) => request.post('/om/resident/save', data),
  // updateResidentTask: (data: Recordable) => request.put('/om/resident/update', data),

  getSignList: (taskId: string, date: string) => request.get(`/om/resident/getSignList?id=${taskId}&date=${date}`),

  getInvoicingByTaskId: (id: string) => request.get(`/ops/ops/taskInvoicing/getInvoice?taskId=${id}`),
  addInvoicing: (data: any) => request.post('/ops/ops/taskInvoicing/openInvoice', data),
  editInvoicing: (data: any) => request.post('/ops/ops/taskInvoicing/editInvoice', data),

  getTaskSource: () => request.get('/system/diction/findByCode/ops_resident_task_source'),
  getTaskType: () => request.get('/system/diction/findByCode/ops_resident_task_service_style'),
  getTaskCompanyName: () => request.get('/system/diction/findByCode/ops_mobile_task_company_name'),
  getTaskStatus: () => request.get('/system/diction/findByCode/ops_resident_task_status'),
  getTaskProgression: () => request.get('/system/diction/findByCode/ops_task_progression'),
  getInvocingType: () => request.get('/system/diction/findByCode/ops_task_invocing_type'),
  getTaskSettleState: () => request.get('/system/diction/findByCode/ops_task_settle_state_code'),
  getDevicesBacktime: () => request.get('/system/diction/findByCode/ops_mobile_task_devices_backtime'),
  getUser: () => request.get('/system/user/findAll'),

  getCompanyTitle: () => request.post('/system/sys/department/list'),
  getCompanyNameList: () => request.get('/project/project/open/getCustomerCompany'),

  getOpsUser: () => request.get('/ops/ops/taskCustomer/getOpsUserTaskCount'),
  getCustomerAll: () => request.get('/project/customer/profile/findAll'),

  getCustomerList: (id: string) => request.get(`/ops/ops/taskCustomer/getCustomerDropList?unitId=${id}`),
  addCustomer: (data: any) => request.post('/ops/ops/taskCustomer/saveCustomer', data),
  addResidentTask: (data: any) => request.post('/ops/ops/residentTask/submit', data),
  addResidentTaskDraft: (data: any) => request.post('/ops/ops/residentTask/saveDraft', data),
  editResidentTask: (data: any) => request.post('/ops/ops/residentTask/editTask', data),
  updateResidentTask: (data: any) => request.post('/ops/ops/residentTask/updateTask', data),
  getTaskByTaskId: (id: string) => request.get(`/ops/ops/residentTask/getDetail/${id}`),

  getServerReport: (id: string) => request.get(`/ops/ops/feedback/serverReport/${id}`),
  pushReport: (data: any) => request.post('/ops/ops/feedback/pushReport', data),
  getsHistoryServerReport: () => request.get('/ops/ops/feedback/queryHistoryServerReport'),
  taskSettle: (data: any) => request.post(`/ops/ops/confirmLetter/taskSettle?taskId=${data.taskId}&stateCode=${data.stateCode}`),
  getByResidentIdAndDate: (id: string, date: string, type: string) =>
    request.get(`/ops/ops/feedback/getByResidentId?residentId=${id}&residentDate=${date}&type=${type}`),
  getFeedbackOfTimePhase: (data: any) => request.post('/ops/ops/feedback/getFeedbackOfTimePhase', data),
  // zeng add
  getFeedBackNum: (id: string, date: string) => request.get(`ops/ops/feedback/getFeedBackNum?residentDate=${date}&residentId=${id}`),
  exportLog: (data: any) => request.post('/ops/ops/mobiletask/exportFeedBackData', data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),
  exportSign: (data: any) => request.post('/ops/ops/taskSign/export', data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),

  getProjectInformationByPage: (params: PageParams) => {
    return request.post(`/ops/ops/proj/information/pageList?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`, params.body || {})
  },
  getFileDirCountDrilling: (id: string) => request.get(`/ops/ops/proj/information/fileDirCountDrilling?projectId=${id}`),
  getCustomerSelect: (id?: string) => request.get(`/ops/ops/proj/information/getCustomerSelect?customerUnitId=${id}`),
  getCustomerUnitSelect: () => request.get('/ops/ops/proj/information/getCustomerUnitSelect'),
  // download: () =>
  //   request.post('/purchase/purchase/alternate/downloadCategoryFileTemplate', {}, { responseType: 'blob', timeout: 30 * 60 * 1000 }),
  downloadError: (data: any) =>
    request.post('/purchase/purchase/alternate/downloadCategoryErrFile', data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),

  // 新
  getAllAsset: (params: PageParams) => {
    return request.post(`/ops/ops/asset/getAllAsset?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`, params.body || {})
  },
  downAllAsset: (data: Recordable) => {
    return request.post(`/ops/ops/asset/down`, data, { responseType: 'blob', timeout: 30 * 60 * 1000 })
  },
  addAsset: (data: any) => request.post('/ops/ops/asset/addAsset', data),
  updateAsset: (data: any) => request.post('/ops/ops/asset/updateAsset', data),
  getAsset: (id?: string) => request.get(`/ops/ops/asset/getAsset?id=${id}`),
  getAssetBrandList: () => request.get('/ops/ops/asset/getAssetBrandList'),
  getAssetModelList: (id?: string) => request.get(`/ops/ops/asset/getAssetModelList?brandId=${id}`),
  getAssetPlaceList: (id?: string | null) => request.get(`/ops/ops/asset/getAssetPlaceList?projectId=${id}`),
  getAssetTypeList: () => request.get('/ops/ops/asset/getAssetTypeList'),
  addAssetBrand: (data: any) => request.post('/ops/ops/asset/addAssetBrand', data),
  addAssetModel: (data: any) => request.post('/ops/ops/asset/addAssetModel', data),
  addAssetType: (data: any) => request.post('/ops/ops/asset/addAssetType', data),
  addAssetPlace: (data: any) => request.post('/ops/ops/asset/addAssetPlace', data),
  getProjectAll: () => request.get('/project/project/open/getProjectFormSelectList'),
  addAssetRepair: (id: string, data: any) => request.post(`/ops/ops/asset/addAssetRepair?assetId=${id}`, data),
  getAssetRepairList: (id: string, data: any) => request.post(`/ops/ops/asset/getAssetRepairList?assetId=${id}`, data),
  updateAssetRepair: (id?: string) => request.post(`/ops/ops/asset/updateAssetRepair?assetId=${id}`),
  downAssetRepairList: (data: any, id: string) =>
    request.post(`/ops/ops/asset/downAssetRepairList?assetId=${id}`, data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),

  download: () => request.get('/ops/ops/asset/downImportAssetTplFile', { responseType: 'blob', timeout: 30 * 60 * 1000 }),
  getAllAssetByProj: (params: PageParams) => {
    return request.post(`/ops/ops/asset/getAllAssetByProj?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`, params.body || {})
  },
  getOrgTypeList: () => request.get('/ops/ops/asset/getOrgTypeList'),
  getCustomerTypeList: () => request.get('/ops/ops/asset/getCustomerTypeList'),
  delAsset: (id?: string) => request.post(`/ops/ops/asset/deleteAsset?assetId=${id}`),
  getAssetData: (id?: string | null) => request.post(`/ops/ops/asset/getAssetData?projectId=${id}`),
}

import { request } from '@/utils'

export default {
  getProjectList: () => request.post(''),
  // 获取跟进售前
  getSelectUser: (data: any) => request.post('/system/system/open/getSelectUser', data),
  // 获取进度明细
  progressItem: (data: any) =>
    request.post(`project/sys/project/infoDesign/progressItem?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`, data),
  // 保存项目管理信息
  saveManagerInfo: (data: any, projectId: string) =>
    request.post(`/project/sys/project/infoDesign/saveManagerInfo?projectId=${projectId}`, data),
  // 获取项目管理信息
  getManagerInfo: (projectId: string) => request.get(`/project/sys/project/infoDesign/getManagerInfo?projectId=${projectId}`),
  // 查询是否丢单
  lostOrder: (projectId: string) => request.get(`/project/sys/project/infoDesign/lostOrder?projectId=${projectId}`),
}

import { request } from '@/utils'

export default {
  getPickGoodsList: (params: PageParams) =>
    request.post(`/purchase/category/project/pageList?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}`, params.body || {}),
  submitToPurchase: (ids: any) => request.post('/purchase/category/project/submitPurchase', ids),
  deleteGood: (id: string) => request.delete(`/purchase/category/project/delete?applyIds=${id}`),
  queryDetail: (id: string) => request.get(`/warehouse/receiveGoods/getArrangeDetailToProjectManager/${id}`),
  confirmReceive: (data: any) => request.post('/purchase/category/project/saveArrangeDetail', data),
  queryLogistics: (id: string, type: number) =>
    request.get(`/purchase/category/project/queryLogistics?applyId=${id}&logisticsType=${type}`),
  revert: (ids: Array<string>) => request.post('/purchase/category/project/returnback', ids),
  refresh: (data: any) => request.post('/purchase/category/project/refreshMatch', data),
  getCategoryCascade: () => request.get('/purchase/purchase/supply/service/type/categoryCascadeQuery'),
  getBrandCascade: () => request.get('/purchase/purchase/supply/service/type/brandCascadeQuery'),
  getDirectionByCode: (code: string) => request.get(`/system/diction/findByCode/${code}`),
  download: (projectNo: string, projectName: string) =>
    request.post('/purchase/category/project/downloadTemplate', { projectNo, projectName }, { responseType: 'blob' }),
  getApplyDetail: (id: string) => request.get(`/purchase/category/project/getDetail?id=${id}`),
  editApply: (data = {}) => request.put('/purchase/category/project/edit', data),
  getAllBrand: () => request.get('/purchase/purchase/supply/service/type/qeuryAllbrand'),
  completeInformation: (data = {}) => request.put('/purchase/category/project/completeInformation', data),
  changeContractList: (data = {}) => request.put('/purchase/category/project/changeContractList', data),
  getBrandCascadeQueryByPid: (pId: any) => request.get(`/purchase/purchase/supply/service/type/brandCascadeQueryByPid?pId=${pId}`),
  getCategorySelect: (data: any) => request.post('/purchase/purchase/properties/categoryDropDown', data),
  getCategoryUnit: () => request.get('/system/diction/findByCode/purchase_category_unit'),
  getCategoryColor: () => request.get('/system/diction/findByCode/purchase_category_color'),
  getMaterialCode: () => request.get('/system/diction/findByCode/inspection_material_code'),

  approveAuxiliary: (data: any) => request.post('/purchase/purchase/category/applyV2/approveAuxiliary', data),
  getProjectHasContract: (projectId: any) => request.get(`/purchase/purchase/category/applyV2/getProjectHasContract/${projectId}`),
  pageListAll: (params: PageParams) =>
    request.post(
      `/purchase/purchase/category/applyV2/pageListAll?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}`,
      params.body || {}
    ),
  pageListAuxiliary: (params: PageParams) =>
    request.post(
      `/purchase/purchase/category/applyV2/pageListAuxiliary?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}`,
      params.body || {}
    ),
  pageListLaunched: (params: PageParams) =>
    request.post(
      `/purchase/purchase/category/applyV2/pageListLaunched?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}`,
      params.body || {}
    ),
  pageListPurchased: (params: PageParams) =>
    request.post(
      `/purchase/purchase/category/applyV2/pageListPurchased?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}`,
      params.body || {}
    ),
  pushPurchase: (data: any) => request.post('/purchase/purchase/category/applyV2/pushPurchase', data),
  saveAuxiliary: (data: any) => request.post('/purchase/purchase/category/applyV2/saveAuxiliary', data),

  getCategoryTagAndType3V2: (data: any) => request.post('/project/project/design/category/getCategoryTagAndType3V2', data),
  getCategoryBrand: (data: any) => request.post('/project/project/design/category/getCategoryBrand', data),
  getCategoryModel: (data: any) => request.post('/project/project/design/category/getCategoryModel', data),
  getCategoryPage: (data: any) => request.post('/project/project/design/category/getCategoryPage', data),
  getCategoryImage: (params: any) => request.get('/project/project/design/category/getCategoryImage', { params }),
  getCategoryColors: (categoryId: string) => request.get(`/purchase/purchase/categoryV2/getCategoryColors?categoryId=${categoryId}`),
  getCategoryPageForCommon: (data: any) => request.post('/project/project/open/getCategoryPageForCommon', data),
  cancelAuxiliary: (data: any) => request.post('/purchase/purchase/category/applyV2/cancelAuxiliary', data),
  getDetailLaunched: (id: any) => request.get(`/purchase/purchase/category/applyV2/getDetailLaunched?id=${id}`),
  editLaunched: (data: any) => request.post('/purchase/purchase/category/applyV2/editLaunched', data),
  getDetailCategory: (id: string) => request.get(`/purchase/purchase/categoryV2/getDetailCategory?id=${id}`),
  getCategoryTagAndType3WithLevel1V2: (data: any) =>
    request.post('/purchase/purchase/supply/service/typeV2/getCategoryTagAndType3WithLevel1V2', data),
  getCategoryModelWithLevel1: (data: any) => request.post('/purchase/purchase/supply/service/typeV2/getCategoryModelWithLevel1', data),
  getCategoryBrandWithLevel1: (data: any) => request.post('/purchase/purchase/supply/service/typeV2/getCategoryBrandWithLevel1', data),
  getOptAuthority: (projectId: string) => request.get(`/project/sys/project/infoContract/getOptAuthority?projectId=${projectId}`),
  rejectView: (applyId: string) => request.post(`/purchase/category/apply/rejectView?applyId=${applyId}`),
  getInspection: (purchaseId: string) => request.get(`/purchase/category/apply/getInspection?id=${purchaseId}`),

  maintenancePurchaseSave: (data: any) => request.post('/project/maintenance/maintenancePurchaseSave', data),
}

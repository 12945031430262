import { request } from '@/utils'

export default {
  getEmptyQuery: () => {
    return {
      brandSuccessionModel: null,
      keyword: null,
    }
  },
  getEmptyDetailQuery: (categoryId: string) => {
    return {
      categoryId,
      goodsStatus: null,
      keyword: null,
      storehouseId: null,
      type: [],
    }
  },
  getEmptyInbound: () => {
    return {
      categoryId: null,
      count: 1,
      countUnit: null,
      files: [],
      fromResourceCode: 'xmth',
      goodsCode: null,
      goodsStatus: null,
      projectId: null,
      storehouseId: null,
    }
  },
  getEmptyOutbound: (categoryId: string) => {
    return {
      applyUserId: null,
      categoryId,
      companyDept: null,
      goodsIds: [],
      goodsCodes: [],
      parterId: null,
      projectId: null,
      reasonCode: null,
      receiveUserId: null,
      summary: null,
    }
  },
  // 库存分页
  getStockByPage: (params: PageParams) =>
    request.post(`/warehouse/stockManage/list?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`, params.body || {}),
  // 品牌系列型号
  getBrandSeriesModelList: () => request.get('/purchase/purchase/supply/service/type/brandCascadeQuery'),
  // 导出库存管理列表
  exportExcel: (data: Recordable) => request.post('/warehouse/stockManage/exportExcel', data, { responseType: 'blob' }),
  // 库存详情分页
  getCategoryStockByPage: (params: PageParams) =>
    request.post(
      `/warehouse/stockManage/listGoodsByCategoryId?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`,
      params.body || {}
    ),
  // 库存详情分页
  getCategoryStockByIds: (categoryId: string, ids: Array<string>) =>
    request.post(`/warehouse/stockManage/getGoodsInfoRowByGoodsId?categoryId=${categoryId}`, ids),
  // 仓库列表
  getStockFormList: () => request.get('/warehouse/storehouse/getFormList'),
  // 新增入库
  addGoodsIn: (data: Recordable) => request.post('/warehouse/stockManage/addGoodsIn', data),
  // 新增出库
  addGoodsOut: (data: Recordable) => request.post('/warehouse/stockManage/addGoodsOut', data),
  // 根据货品编码获取货品信息
  getCategoryInfoByCode: (code: string) => request.get(`/warehouse/stockManage/getGoodsInfoByGoodsCode/${code}`),
  // 根据货品id获取货品信息
  getGoodsInfoById: (id: string) => request.get(`/warehouse/stockManage/getGoodsInfo/${id}`),
  // 修改货品信息
  updateGoodsInfo: (data: Recordable) => request.post('/warehouse/stockManage/saveGoodsInfo', data),
  // 获取所有供货商
  getAllPartner: () => request.get('/warehouse/stockManage/getAllPartner'),
  // 根据货品获取货品颜色
  getCategoryColors: (categoryId: string) => request.get(`/purchase/purchase/categoryV2/getCategoryColors?categoryId=${categoryId}`),
}

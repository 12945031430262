import { request } from '@/utils'

export default {
  // 获取人员类下拉框数据
  getProjectPerson: (data = {}) => request.post('/project/proj/userList', data),
  // 当前用户是否为项目的角色
  getCurrentUserIsPm: (projectId: string, code: string) =>
    request.post(`/project/project/open/currentUserIsProjectRole?projectId=${projectId}&code=${code}`),
  // 获取所有用户
  getUser: () => request.get('/system/user/findAll'),
  // 获取所有用户(客户单位)
  getCompanyNameList: () => request.get('/project/project/open/getCustomerCompany'),
  // 新建字典
  addDiction: (data = {}) => request.post('/system/diction/saveAuto', data),
  // 新建线索/项目
  addProClue: (data = {}) => request.post('/project/prjClue/add', data),
  // 获取项目线索
  getProjectClue: (id: string) => request.get(`/project/prjClue/find/${id}`),
  // 获取项目类型
  getProjectType: () => request.get('/system/diction/findByCode/proj_clue_type'),
  // 获取客户角色
  getCustomerType: () => request.get('/system/diction/findByCode/proj_customer_identity'),
  // 获取客户信息
  getCustomerAll: (id: string) => request.get(`/project/project/open/getrCustomerContactUser?customerOrgId=${id}`),
  // 获取项目创建信息
  getProjectCreated: (id: string) => request.get(`/project/sys/project/infoApproval/get/${id}`),
  // 获取立项跟进公司抬头
  getCompanyTitle: () => request.get('/system/diction/findByCode/proj_approval_company'),
  // 新建项目创建信息
  addProjectCreated: (data = {}) => request.post('/project/sys/project/infoApproval/save', data),
  // 获取项目设计信息
  getProjectDesign: (id: string) => request.get(`/project/sys/project/infoDesign/get/${id}`),
  // 新增项目设计信息
  addProjectDesign: (data = {}) => request.post('/project/sys/project/infoDesign/save', data),
  // 新增项目设计信息
  addNewest: (data = {}) => request.post('/project/sys/project/infoDesign/addNewest', data),
  // 新增招投标
  addProjectBidding: (data = {}) => request.post('/project/proj/bidding/save', data),
  // 修改招投标
  editProjectBidding: (data = {}) => request.put('/project/proj/bidding', data),
  // 获取招投标
  getProjectBidding: (id: string) => request.get(`/project/proj/bidding/find/${id}`),
  // 获取招投标承接项目身份
  getProjectBiddingType: () => request.get('/system/diction/findByCode/proj_bidding_identity_role'),
  // 新增合同
  addProjectContract: (data = {}) => request.post('/project/sys/project/infoContract/save', data),
  // 获取合同
  getProjectContract: (id: string) => request.get(`/project/sys/project/infoContract/get/${id}`),
  // 获取合同—付款方式
  getPaymentMethod: () => request.get('/system/diction/findByCode/proj_contract_payment_method'),
  // 保存验收项目信息
  saveContractAcceptance: (data: any) => request.post('/project/sys/project/infoContract/saveContractAcceptance', data),
  // 获取合同—质保金&时长单位
  getWarrantyDepositUnit: () => request.get('/system/diction/findByCode/proj_contract_warranty_deposit_unit'),
  // 获取结算批次
  getSettlementGroup: () => request.get('/system/diction/findByCode/proj_contract_settlement_group'),
  // 获取提醒周期
  getReminderPeriod: () => request.get('/system/diction/findByCode/proj_contract_reminder_period'),
  // 获取结算周期
  getSettlementPeriod: () => request.get('/system/diction/findByCode/proj_settlement_period_type_code'),
  // 获取快递公司选项
  getLogisticsOptions: () => request.get('/system/diction/findByCode/project_logistics_company_code'),
  // 获取开票方式
  getInvoicingMethod: () => request.get('/system/diction/findByCode/proj_contract_invoicing_method'),
  // 获取开票税点
  getInvoicingTaxPoint: () => request.get('/system/diction/findByCode/proj_contract_invoicing_tax_point'),
  // 获取项目阶段
  getProjectPhase: () => request.get('/system/diction/findByCode/proj_contract_project_phase'),
  // 新增内验信息
  addProjectInternalTest: (data = {}) => request.post('/project/sys/project/infoInternalInspection/save', data),
  // 新增收款信息
  addProjectProceeds: (data = {}) => request.post('/project/sys/project/infoCollectionMoney/save', data),
  // 获取收款信息
  getProjectProceeds: (id: string) => request.get(`/project/sys/project/infoCollectionMoney/get/${id}`),
  // 新增运维信息
  addProjectMaintenance: (data = {}) => request.post('/project/maintenance/saveOrUpdate', data),
  // 根据项目获取运维信息
  getMaintenanceByProject: (id: string) => request.get(`/ops/ops/mobiletask/queryForProject?projectId=${id}`),
  // 获取运维信息
  getProjectMaintenance: (id: string) => request.get(`/project/maintenance/${id}`),

  getChinaProvinceCityRegion: () => request.get('/system/system/open/getChinaProvinceCityRegion'),
  // 导出收款信息
  exportProjectProceeds: (id: string) =>
    request.post(`/project/sys/project/infoCollectionMoney/export?projectId=${id}`, {}, { responseType: 'blob' }),

  getTagOpenToSelect: (id: string) => request.get(`project/sys/project/lifecycleTagGroup/getTagOpenToSelect?groupCode=${id}`),

  getLostReason: () => request.get('/system/diction/findByCode/proj_info_approval_lost_reason'),
  getClassificationCode: () => request.get('/system/diction/findByCode/project_classification_code'),
  getProjectFromType: () => request.get('/system/diction/findByCode/proj_info_approval_project_from_type_code'),
  delApplyInvoicing: (id: string, applyId: string) =>
    request.get(`/project/sys/project/infoCollectionMoney/delApplyInvoicing/${id}?applyId=${applyId}`),

  getTaskByTaskId: (id: string) => request.get(`/ops/ops/mobiletask/getDetail/${id}`),
  getResidentTaskByTaskId: (id: string) => request.get(`/ops/ops/residentTask/getDetail/${id}`),

  getCommunicationType: () => request.get('/system/diction/findByCode/proj_design_communication_type'),
  /**
   * 售前标准化
   **/
  getBiddingNode: (projectId: string) => request.get(`/project/node/${projectId}`),
  getBidAuditor: (projectId: string) => request.get(`/project/book/audit/queryBidAuditor/${projectId}`),
  /**
   * 获取指定文件code所上传的数量
   **/
  getFileCountByCode: (projectId: string, code: string) => request.get(`/project/proj/file/getFileCountOfDirCode/${projectId}/${code}`),
  getDynamicFormList: () => request.get('/system/sys/dynamic/forms/findAll'),
  saveDynamicFormRef: (data: Recordable) => request.post('/project/proj/form/tpl/save', data),
  getDirInfoByCode: (code: string) => request.get(`/project/proj/file/getDirInfByCode/${code}`),
  getLinkFolderList: (projectId: string, code: string) => request.get(`/project/proj/file/getFileDirDetail/${projectId}/${code}`),
  getLinkFolderInfo: (code: string) => request.get(`/project/proj/file/getFileDirBaseInfo/${code}`),

  /**
   * 项目实施
   **/
  // 保存实施信息
  saveProjectImplement: (data: Recordable) => request.post('/project/implemente/save', data),
  // 获取实施信息
  getProjectImplement: (id: string) => request.get(`/project/implemente/${id}`),
  // 获取所有的实施节点信息
  getProjectImplementAllProcess: (projectId: string) =>
    request.get(`/project/implemente/node/getNodeCompletedInfoByProjectId/${projectId}`),
  getEmptyProjectImplement: () => {
    return {
      id: null,
      projectId: null,
      // 建设内容
      buildContentGroupId: [],
      // 预计进场日期
      preEnterDate: null,
      // 预计到货日期
      preArrivalDate: null,
      // 预计调试日期
      preDebugDate: null,
      // 预计验收日期
      preCheckDate: null,
      // 预计初验日期
      preInitCheckDate: null,
      // 预计终验日期
      preEndCheckDate: null,
      // 预计交付日期
      planDeliveryDate: null,
      // 备注说明
      implementationSummary: null,
      // 项目经理
      projectManagerId: null,
      // 项目过程
      projectNotes: [],
      // 生命周期
      phaseCode: null,
      // 实施状态
      implemenetStatus: null,
    }
  },
  /**
   * 公司内验
   **/
  getProjectQualityApproveList: (projectId: string) =>
    request.get(`/project/sys/project/infoInternalInspection/getApproveQualityInspectionList/${projectId}`),
  getProjectInternalApproveList: (projectId: string) =>
    request.get(`/project/sys/project/infoInternalInspection/getApproveInternalInspectionList/${projectId}`),
  saveProjectQualityApprove: (data: Recordable) =>
    request.post('/project/sys/project/infoInternalInspection/approveQualityInspection', data),
  saveProjectInternalApprove: (data: Recordable) =>
    request.post('/project/sys/project/infoInternalInspection/approveInternalInspection', data),

  getProjectQualityApproveHistory: (projectId: string, code: string) =>
    request.get(
      `/project/sys/project/infoInternalInspection/searchHistoryApproveQualityInspection?projectId=${projectId}&bussCode=${code}`
    ),
  getProjectInternalApproveHistory: (projectId: string) =>
    request.get(`/project/sys/project/infoInternalInspection/searchHistoryApproveInternalInspection?projectId=${projectId}`),

  /**
   * 验收交付
   **/
  addProjectDelivery: (data = {}) => request.post('/project/sys/project/infoDeliver/save', data),
  getProjectDelivery: (id: string) => request.get(`/project/sys/project/infoDeliver/get/${id}`),
  getEmptyProjectDelivery: () => {
    return {
      buildContentContainComputerRoomConstruction: null,
      afterSalesJoinDate: null,
      deliveryCompletionDate: null,
      deliveryCompletionSummary: null,
      finalAcceptanceCompletionDate: null,
      finalCheckSummary: null,
      initialFinishDate: null,
      operationMaintenanceSummary: null,
      warrantyContentSummary: null,
      warrantyEndDate: null,
      warrantyStartDate: null,
      warrantyRange: null,
    }
  },
  getProjectLastApproveQuality: (projectId: string, bussCode: string) =>
    request.get(`/project/sys/project/infoInternalInspection/getLastApproveQualityInspection/${projectId}/${bussCode}`),
  getProjectLastApproveInternal: (projectId: string) =>
    request.get(`/project/sys/project/infoInternalInspection/getLastApproveInternalInspection/${projectId}`),

  getCustomerOrgType: () => request.get('/system/diction/findByCode/customer_org_type_code'),
  getCustomerOrgNature: () => request.get('/system/diction/findByCode/customer_org_nature_code'),
  getCustomerOrgClassificate: () => request.get('/system/diction/findByCode/customer_org_classificate_code'),
  getProjSchedule: () => request.get('/system/diction/findByCode/project_budget_layout'),
  // 阶段详情查询
  getPhaseById: (action: string, id: string) => request.get(`/project/sys/project/infoDesign/${action}/${id}`),
  // 阶段详情保存
  savePhase: (action: string, data = {}) => request.post(`/project/sys/project/infoDesign/${action}`, data),
  addJudge: (data: any) => request.post('/project/customer/profile/saveWithSysOut', data),
  // 获取对接客户列表
  getCustomerList: (projectId: string, bussModule: string) =>
    request.get(`/project/customer/profile/findAllWithSysOut/${projectId}?bussModule=${bussModule}`),
  getVersionList: (id: string, type: string, data: any) =>
    request.post(`/project/sys/project/infoDesign/getPhase3FileVersionList/${id}/${type}`, data),
  getAuth: (projectId: string) => request.get(`/project/project/design/require/getAuth/${projectId}`),
  getProjectArchiveInfo: (projectId: string) => request.get(`/project/sys/project/infoCollectionMoney/getProjectArchiveInfo/${projectId}`),
  saveProjectArchive: (data: any) => request.post('/project/sys/project/infoCollectionMoney/saveProjectArchive', data),
  getProjectTagRecord: (projectId: string, tagId: string, pageNumber: number, pageSize: number) =>
    request.get(
      `/project/project/open/getProjectTagRecord?projectId=${projectId}&tagObjectId=${tagId}&pageNumber=${pageNumber}&pageSize=${pageSize}`
    ),
  getDirectionByCode: (code: string) => request.get(`/system/diction/findByCode/${code}`),
  // 合同模块迭代新接口
  getContract: (projectId: string) => request.get(`/project/sys/project/infoContract/getContract/${projectId}`),
  getBaseInfo: (projectId: string) => request.get(`/project/sys/project/infoContract/getBaseInfo/${projectId}`),
  getPurchase: (projectId: string) => request.get(`/project/sys/project/infoContract/getPurchase/${projectId}`),
  getPurchaseList: (projectId: string) => request.get(`/project/sys/project/infoContract/getPurchaseList/${projectId}`),
  saveContract: (data: any) => request.post('/project/sys/project/infoContract/saveContract', data),
  saveContractAndApply: (data: any) => request.post('/project/sys/project/infoContract/saveContractAndApply', data),
  savePurchase: (data: any) => request.post('/project/sys/project/infoContract/savePurchase', data),
  getFinalizeSchemeId: (projectId: string) => request.get(`/project/project/design/open/getFinalizeSchemeId?projectId=${projectId}`),
  getCanSelectQuoteScheme: (projectId: string) =>
    request.get(`/project/project/design/open/getCanSelectQuoteScheme?projectId=${projectId}`),
  getProjectInfoByProjectId: (projectId: string) => request.get(`/project/project/open/getProjectInfoByProjectId?projectId=${projectId}`),

  // 运维提货逻辑
  canAddPurchaseCategory: (projectId: string) => request.get(`/project/maintenance/canAddPurchaseCategory/${projectId}`),
  maintenancePurchaseConfirm: (projectId: string) => request.get(`/project/maintenance/maintenancePurchaseConfirm/${projectId}`),
  maintenancePurchasePageList: (pageNumber: number, pageSize: number, data: any) =>
    request.post(`/project/maintenance/maintenancePurchasePageList?pageNumber=${pageNumber}&pageSize=${pageSize}`, data),
  maintenancePurchaseEdit: (data: any) => request.post('/project/maintenance/maintenancePurchaseEdit', data),
  getProjectLimitByUser: (projectId: string, itemId: string) =>
    request.post(`/project/sys/project/infoContract/getProjectLimitByUser?projectId=${projectId}&itemId=${itemId}`),
  updateProfitRemark: (data: any) => request.post('/project/sys/project/infoContract/updateProfitRemark', data),
  getFinalAcceptanceCompletionDate: (data: any) => request.post('/project/sys/project/infoDeliver/getFinalAcceptanceCompletionDate', data),
  // 获取项目归属地
  getUserBelongToCode: () => request.get('/project/sys/project/infoApproval/getUserBelongToCode'),
  // 申请跟进销售权限查询
  queryApplyModifyFlowSaleRule: (projectId: string) =>
    request.post(`/project/sys/project/infoApproval/queryApplyModifyFlowSaleRule?id=${projectId}`),

  // 查询结算金额修改记录
  queryChangeSettlementAmountHistory: (data: any) => request.post('/project/sys/project/settlementChange/pageList', data.body),
  // 获取结算金额变更附件地址
  getFileBrowseUrl: (id: string, type?: string) =>
    request.get(`/project/sys/project/settlementChange/getFileBrowseUrl?id=${id}&type=${type}`),
  getProjectAll: () => request.get('/project/main/getProjectListByAssociated'),
  queryProjectTagListByNum: (projectId: string, num: string) =>
    request.post(`/project/sys/project/infoApproval/queryProjectTagListByNum?id=${projectId}&num=${num}`),
}

import type { InjectionKey } from 'vue'

export interface AuthInjection {
  /** 是否可编辑报价清单&报价方案 */
  canEditQuote: boolean
  /** 是否可查看报价清单价格 */
  canBrowseQuotePrice: boolean
  /** 是否可以设置下载报价清单按钮设置 */
  canSetQuoteDownFileConfig: boolean
  /** 是否可下载报价清单 */
  canDownQuote: boolean
  /** 售前是否定稿 */
  canSetPreSaleConfig: boolean
}
export const AuthInjectionKey: InjectionKey<AuthInjection> = Symbol('Auth')
export const DYNAMIC_TABLE_COL_KEY = 'pre-sale—dynamic-table'

export enum AlignEnum {
  center = 'center',
  left = 'left',
  right = 'right',
}

export interface Columns {
  width: number
  title?: string
  type?: string
  allCheckBox?: boolean
  align?: AlignEnum
  headerAlign?: AlignEnum
  props?: string
}

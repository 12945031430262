import { request } from '@/utils'
import type { RequestConfig } from '~/types/axios'

export default {
  getEmptyTodoQuery: () => {
    return {
      approveStartTime: null,
      approveEndTime: null,
      taskStartTime: null,
      taskEndTime: null,
      approveStartUserId: [],
      keyWord: null,
      code: null,
    }
  },
  getEmptyCcQuery: () => {
    return {
      approveStartTime: null,
      approveEndTime: null,
      approveStartUserId: [],
      keyWord: null,
      code: null,
      status: null,
    }
  },
  getEmptyFinishedQuery: () => {
    return {
      approveStartTime: null,
      approveEndTime: null,
      approveStartUserId: [],
      keyWord: null,
      code: null,
    }
  },
  getEmptyMySubmitQuery: () => {
    return {
      approveStartTime: null,
      approveEndTime: null,
      taskStartTime: null,
      taskEndTime: null,
      keyWord: null,
      code: null,
      status: null,
    }
  },
  getEmptyFinishedInstQuery: () => {
    return {
      approveStartTime: null,
      approveEndTime: null,
      approveStartUserId: [],
      keyWord: null,
      code: null,
      status: null,
    }
  },
  getEmptyAllQuery: () => {
    return {
      approveStartTime: null,
      approveEndTime: null,
      approveStartUserId: [],
      keyWord: null,
      code: null,
      status: null,
    }
  },
  getEmptyQuery: () => {
    return {
      type: '1',
      keyWord: null,
      code: null,
      status: null,
    }
  },
  getEmptyShareQuery: () => {
    return {
      approveStartTime: null,
      approveEndTime: null,
      approveStartUserId: [],
      shareUser: [],
      keyWord: null,
      code: null,
      status: null,
    }
  },

  /** 待处理 */
  getTodoList: (params: PageParams) =>
    request.post(
      `/workflow/wflow/erp/process/task/todoList?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`,
      params.body || {}
    ),
  /** 抄送我 */
  getCcList: (params: PageParams) =>
    request.post(`/workflow/wflow/erp/process/task/ccMe?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`, params.body || {}),
  /** 已完成 */
  getFinishedList: (params: PageParams) =>
    request.post(`/workflow/wflow/erp/process/task/idoList?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`, params.body || {}),
  /** 已完成的流程实例 */
  getFinishedInstList: (params: PageParams) =>
    request.post(
      `/workflow/wflow/erp/process/task/idoInstanceList?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`,
      params.body || {}
    ),
  /** 我发起 */
  getMySubmitList: (params: PageParams) =>
    request.post(
      `/workflow/wflow/erp/process/task/mySubmitted?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`,
      params.body || {}
    ),
  /** 所有 */
  getAllList: (params: PageParams) =>
    request.post(`/workflow/wflow/erp/process/task/all?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`, params.body || {}),
  /** 获取任务详情 */
  getTaskDetail: (instId: string, nodeId?: string | null) =>
    request.get(`/workflow/wflow/process/progress/${instId}/${nodeId}`, { noResData: true } as RequestConfig),
  /** 获取审批类型 */
  getAllApproveType: () => request.get('/workflow/wflow/erp/process/task/getAllApproveType'),
  /** 获取审批发起人 */
  getApproveUsers: () => request.post('/system/system/open/getSelectUser', { code: 'all_people' }),
  /** 获取所有用户包含离职的人 */
  getSelectUserWithLeave: () => request.post('/system/system/open/getSelectUserWithLeave'),
  /** 分享给我 */
  getShareList: (params: PageParams) =>
    request.post(`/workflow/wflow/erp/process/task/shareMe?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`, params.body || {}),
  getSelectUser: (data: any) => request.post('/system/system/open/getSelectUser', data),
  shareWorkFlow: (data: any) => request.post(`/workflow/wflow/erp/process/task/shareWorkFlow`, data || {}),
}

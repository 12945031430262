import type { FormItemRule } from 'naive-ui/es/form/src/interface'
import { isBoolean } from 'lodash-es'
import { isDir, isFunction } from '@/utils'

export default function useCloud() {
  const videoTypes = [
    'avi',
    'wmv',
    'mpg',
    'mpeg',
    'mov',
    'rm',
    'ram',
    'swf',
    'flv',
    'mp4',
    'mp3',
    'wma',
    'avi',
    'rm',
    'rmvb',
    'flv',
    'mpg',
    'mkv',
  ]

  const imgTypes = ['svgz', 'pjp', 'png', 'ico', 'avif', 'tiff', 'tif', 'jfif', 'svg', 'xbm', 'pjpeg', 'webp', 'jpg', 'jpeg', 'bmp', 'gif']

  function buildSpaceTreeItem(item: any) {
    delete item.children
    return {
      ...item,
      isLeaf: !isDir(item),
    }
  }

  const msgMap = new Map([
    ['user', '请选择用户'],
    ['dept', '请选择部门'],
    ['role', '请选择角色'],
  ])

  const scopeRule: FormItemRule = {
    trigger: ['change', 'blur'],
    validator(rule: unknown, value: { scopeType: string; typeOfId: string[]; codes: string[] }) {
      if (!value) {
        return new Error('请选择可见范围')
      }
      window.console.log(value, '6666666')
      if (msgMap.has(value.scopeType) && value.typeOfId.length === 0) {
        return new Error(msgMap.get(value.scopeType))
      }
      if (value.codes.length === 0) {
        return new Error('请选择权限')
      }
      return true
    },
  }

  function buildSpaceTree(tree: Array<any>) {
    return (tree || []).map((item) => buildSpaceTreeItem(item))
  }

  function getPermValue(val: any, params: any) {
    if (isBoolean(val)) {
      return val
    } else {
      if (isFunction(val)) {
        return val(params)
      }
      return false
    }
  }

  function getTagColor(color: string | null) {
    return {
      color,
      textColor: '#ffffff',
    }
  }

  const showTag = ref(true)

  return {
    showTag,
    buildSpaceTreeItem,
    buildSpaceTree,
    scopeRule,
    getPermValue,
    videoTypes,
    imgTypes,
    getTagColor,
  }
}

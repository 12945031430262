import api from './api'
import type { ResponseResult } from '~/types/axios'

async function Middleware(req: (body: any) => Promise<ResponseResult<any>>, params: any, original = false) {
  console.log('Middleware', params)
  const res = await req(params)

  if (original) {
    return res
  } else {
    return res.data
  }
}

export const service = {
  page: (params: PageParams) => Middleware(api.getPageListCategory, params || {}),
  add: (params?: any) => Middleware(api.addCategory, params || {}),
  delete: (params?: any) => Middleware(api.delForCategory, params.id || {}),
  info: (params?: any) => Middleware(api.getDetailCategory, params.id || {}),
  update: (params?: any) => Middleware(api.editCategory, params || {}),
  list: (params?: any) => Middleware(api.brandCascade, params || {}),
  getDirectionByCode: (params?: any) => Middleware(api.getDirectionByCode, params || {}),
  getBrand: (params?: any) => Middleware(api.getBrand, params || {}),
  getAllBrand: (params?: any) => Middleware(api.getAllBrand, params || {}),
  cascaderQueryByPid: (params?: any) => Middleware(api.cascaderQueryByPid, params || {}),
  queryTypeInfoBySuccession: (params?: any) => Middleware(api.queryTypeInfoBySuccession, params || {}),
  getCategoryCascade: () => Middleware(api.getCategoryCascade, {}),
  getSelectUser: (params: any) => Middleware(api.getSelectUser, params),
  getSelectLabel: (bussCode: string) => Middleware(api.getSelectLabel, bussCode),
}

import { request } from '@/utils'

export default {
  getDirectionByCode: (code: string) => request.get(`/system/diction/findByCode/${code}`),
  getSelectUser: (data: any) => request.post('/system/system/open/getSelectUser', data),
  // 查询列表
  getPageList: (data: any) => request.post('/data/presale/report/project/page', data),
  // 查询综合信息
  getCollectInfo: (data: any) => request.post('/data/presale/report/project/getCollectInfo', data),
  getSchemeBrandChart: (params: any) =>
    request.get(`/data/presale/report/project/getSchemeBrandChart`, {
      params: {
        projectId: params.projectId,
        schemeId: params.schemeId,
      },
    }),
  getExport: (data: any) => request.post('/data/presale/report/project/down', data, { responseType: 'blob', timeout: 30000 }),
}

import { request } from '@/utils'

export default {
  getEmptyUser: () => {
    return {
      account: null,
      accumulationFund: null,
      address: null,
      age: null,
      bank: null,
      bankCardNo: null,
      birthday: null,
      companyAddress: null,
      companyMail: null,
      deptId: null,
      deptName: null,
      email: null,
      empType: null,
      entryDate: null,
      id: null,
      identityCard: null,
      job: null,
      joinWorkTime: null,
      maritalStatus: null,
      mobile: null,
      nation: null,
      nativePlace: null,
      nickName: null,
      officeAddress: null,
      politics: null,
      position: null,
      positionLevel: null,
      probationPeriod: null,
      recruitType: null,
      regularWorkerDate: null,
      sex: null,
      site: null,
      socialNo: null,
      specialPlane: null,
      status: null,
      superWorkName: null,
      superWorkUserId: null,
      userName: null,
      workNo: null,
      workingYears: null,
    }
  },
  saveUser: (data: any) => request.post('/system/user/add', data),
  getAllRole: () => request.get('/system/system/open/getRole'),

  getUserList: (params: PageParams) =>
    request.post(`/hr/roster/employee/pageList?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}`, params.body),
  getStatistics: () => request.post('/hr/roster/employee/statistics'),
  getAllJob: () => request.get('/system/system/open/getJob'),
  getBasicInfo: (id: string) => request.get(`/hr/roster/employee/getBasicInfo?id=${id}`),
  getUserImage: (id: string) => request.get(`/hr/roster/employee/userImage?userId=${id}`),
  levelInfo: (userId: string) => request.post(`/hr/roster/employee/levelInfo?userId=${userId}`),
  queryContract: (id: string) => request.get(`/hr/roster/employee/queryContract?employeeId=${id}`),
  queryMaterialAttachment: (id: string) => request.get(`/hr/roster/employee/queryMaterialAttachment?employeeId=${id}`),
  editPersonalInfo: (data: any) => request.post('/hr/roster/employee/editPersonalInfo', data),
  editPositionInfo: (data: any, userId: string) => request.post(`/hr/roster/employee/editPositionInfo?userId=${userId}`, data),
  editWorkExperience: (data: any, userId: string) => request.post(`/hr/roster/employee/editWorkExperience?userId=${userId}`, data),
  editLinkMan: (data: any, userId: string) => request.post(`/hr/roster/employee/editLinkMan?userId=${userId}`, data),
  editFamily: (data: any, userId: string) => request.post(`/hr/roster/employee/editFamily?userId=${userId}`, data),
  editEduExperience: (data: any, userId: string) => request.post(`/hr/roster/employee/editEduExperience?userId=${userId}`, data),
  editBankCardInfo: (data: any, userId: string) => request.post(`/hr/roster/employee/editBankCardInfo?userId=${userId}`, data),
  editMaterial: (data: any, userId: string) => request.post(`/hr/roster/employee/editMaterial?userId=${userId}`, data),
  editContract: (data: any, userId: string) => request.post(`/hr/roster/employee/editContract?userId=${userId}`, data),
  getSelectUser: (data: any) => request.post('/system/system/open/getSelectUser', data),
  calculateWorkAge: (data: any) => request.post('/hr/roster/employee/calculateWorkAge', data),
  doLevel: (data: any) => request.post('/hr/roster/employee/doLevel', data),
  confirmLevel: (data: any) => request.post('/hr/roster/employee/confirmLevel', data),
  transfer: (data: any) => request.post('/hr/roster/employee/transfer', data),
  userJobInfo: (id: string | null) => request.get(`/hr/roster/employee/userJobInfo?userId=${id}`),
}

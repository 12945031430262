import { request } from '@/utils'

export default {
  // 获取部门下所有的子部门  (销售部：1645610207172145154)
  getSubDepartment: (params: any) => request.get('/system/sys/department/getSaleDepartment', { params }),
  // 获取部门下所有的用户
  getUserOfDepartment: (params: any) => request.get('/system/sys/department/getUserOfDepartment', { params }),
  // 销售部计划任务指标-获取任务指标详情
  taskPlanGetDetail: (params: any) => request.get('/system/taskPlan/taskPlanIndicators/getDetail', { params }),
  // 销售部计划任务指标-新增指标
  taskPlanAddIndicators: (data: any) => request.post('/system/taskPlan/taskPlanIndicators/addIndicators', data),
  // 销售部计划任务指标-修改指标
  taskPlanEditIndicators: (data: any) => request.post('/system/taskPlan/taskPlanIndicators/editIndicators', data),
  // 销售部计划任务指标-删除指标
  taskPlanDeleteIndicators: (id: any) => request.post(`/system/taskPlan/taskPlanIndicators/deleteIndicators/${id}`),
  // 销售部计划任务指标-部门指标分页查询
  taskPlanPageDepartment: (data: any) => request.post('/system/taskPlan/taskPlanIndicators/pageDepartment', data),
  // 销售部计划任务指标-人员指标分页查询
  taskPlanPageUser: (data: any) => request.post('/system/taskPlan/taskPlanIndicators/pageUser', data),
}

import { defineStore } from 'pinia'
import { buildDynamicRoutes, filterAsyncRoutes } from './helpers'
import { asyncRoutes, basicRoutes } from '@/router/routes'
import type { RoutesType } from '~/types/router'
import { useUserStore } from '@/store'

export const usePermissionStore = defineStore('permission', {
  state() {
    return {
      accessRoutes: <RoutesType>[],
      staticRoutes: <RoutesType>[],
      dynamicMenus: <any>[],
    }
  },
  getters: {
    routes(): RoutesType {
      return basicRoutes.concat(this.accessRoutes)
    },
    metaMenus(): { staticRoutes: RoutesType; dynamicMenus: any[] } {
      return {
        staticRoutes: basicRoutes.concat(this.staticRoutes),
        dynamicMenus: this.dynamicMenus,
      }
    },
    menus(): RoutesType {
      return this.routes.filter((route) => route.name && !route.isHidden)
    },
  },
  actions: {
    generateRoutes(menus: Array<Recordable> = [], authorities: Array<Recordable>): RoutesType {
      const staticRoutes = filterAsyncRoutes(asyncRoutes, authorities)
      this.staticRoutes = staticRoutes
      this.dynamicMenus = menus
      const modules = import.meta.glob('@/views/**/*.vue')
      const dynamicRoutes = buildDynamicRoutes(modules, menus)
      const accessRoutes = [...staticRoutes, ...dynamicRoutes]
      this.accessRoutes = accessRoutes
      return accessRoutes
    },
    resetPermission() {
      this.$reset()
    },
    checkPermission(permissionCode: string) {
      const { authorities } = useUserStore()
      return !!permissionCode && authorities.some((auth) => auth.authority === permissionCode)
    },
  },
})

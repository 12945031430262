import { defineStore } from 'pinia'
import { setSession } from '@/utils'
import {
  currentCustomerCompany,
  currentProjectCode,
  currentProjectId,
  currentProjectMainData,
  currentProjectName,
  currentProjectNodeCode,
} from '@/store/modules/project/helpers'

export const useProjectStore = defineStore('project', {
  state() {
    return {
      code: '',
      projectId: <string>currentProjectId || '',
      projectName: <string>currentProjectName || '',
      projectCode: <string>currentProjectCode || '',
      projectNodeNumber: <number>0,
      projectNodeCode: <string | undefined>currentProjectNodeCode,
      formDisabled: <boolean>true,
      canEdit: <boolean>true,
      canInDetail: <boolean>true,
      isShowQuoteScheme: <boolean>false,
      reloadStep: <boolean>false,
      currentStep: <Recordable | null>null,
      currentStepName: <string>'',
      orderNo: '',
      mainData: <any>currentProjectMainData || {},
      projectRef: <any>null,
      designAuthData: <any>{},
      designFileUploaded: <boolean>false,
      fileChange: <number>0,
      createUserName: <string>'',
      customerCompany: <string>currentCustomerCompany || '',
      currentPickGoodTab: <string | null>null,
    }
  },
  actions: {
    setComponentParams(data: any) {
      this.currentStep = data
      this.setCurrentStepName(data?.name)
      this.code = data.code
      this.orderNo = data.orderNo
      this.formDisabled = true
      this.canEdit = true
      this.canInDetail = data.canInDetail
      this.isShowQuoteScheme = data.isShowQuoteScheme ?? false
    },
    setCurrentStepName(name: string) {
      this.currentStepName = name
    },
    setProjectRef(projectRef: any) {
      this.projectRef = projectRef
    },
    setProjectId(id: string) {
      this.projectId = id
      setSession('projectId', id)
    },
    setProjectCode(projectCode: string) {
      this.projectCode = projectCode
      setSession('projectCode', projectCode)
    },
    setProjectName(name: string) {
      this.projectName = name
      setSession('projectName', name)
    },
    setProjectNodeNumber(number: number) {
      this.projectNodeNumber = number
      setSession('projectNodeNumber', number)
    },
    setProjectNodeCode(code?: string) {
      this.projectNodeCode = code
      setSession('projectNodeCode', code)
    },
    setReloadStep(reloadStep: boolean) {
      this.reloadStep = reloadStep
    },
    setMainData(data: any) {
      this.mainData = data
      setSession('mainData', data)
    },
    setFormDisabled(formDisabled: boolean) {
      this.formDisabled = formDisabled
    },
    setCanEdit(canEdit: boolean) {
      this.canEdit = canEdit
    },
    setDesignAuthData(data: any) {
      this.designAuthData = data ?? {}
    },
    setDesignFileUploaed(val?: boolean) {
      this.designFileUploaded = val ?? true
    },
    setCreateUserName(name: string) {
      this.createUserName = name ?? ''
    },
    setCustomerCompany(customerCompany: string) {
      this.customerCompany = customerCompany ?? ''
      setSession('customerCompany', customerCompany)
    },
    triggerFileChange() {
      this.fileChange++
    },
    setCurrentPickGoodTab(currentPickGoodTab: string | null) {
      this.currentPickGoodTab = currentPickGoodTab
    },
    jumpProjectDetail(row: any, index: number, router: any, query = {}, stepCode?: string) {
      this.setMainData(row)
      this.setProjectId(row.id)
      this.setProjectName(row.name)
      this.setProjectCode(row.code)
      this.setCreateUserName(row.createUserName)
      this.setProjectNodeNumber(index)
      this.setProjectNodeCode(stepCode)
      this.setCustomerCompany(row.customerCompany)
      router.push({ path: '/project/detail', query })
    },
  },
})

import type { RouteType } from '~/types/router'
const Layout = () => import('@/layout/index.vue')

export default {
  name: 'Dashboard',
  path: '/',
  component: Layout,
  redirect: '/workbench',
  isShowParent: false,
  meta: {
    order: 0,
  },
  children: [
    {
      name: 'Workbench',
      path: 'workbench',
      component: () => import('./index.vue'),
      isShowParent: false,
      meta: {
        title: '数据看板',
        icon: 'fa-solid:home',
      },
    },
    {
      name: 'Reset',
      path: 'reset',
      isHidden: true,
      component: () => import('@/views/common/reset-password/index.vue'),
      meta: {
        title: '修改密码',
        requireAuth: false,
      },
    },
    {
      name: 'Notify',
      path: 'notify',
      isHidden: true,
      component: () => import('@/views/common/notify/index.vue'),
      meta: {
        title: '消息中心',
        requireAuth: false,
      },
    },
    {
      name: 'AnnouncementCenter',
      path: 'announcement',
      isHidden: true,
      component: () => import('@/views/common/announcement/index.vue'),
      meta: {
        title: '公告',
        requireAuth: false,
      },
    },
    {
      name: 'LogDetails',
      path: 'oa/log-detail',
      isHidden: true,
      component: () => import('@/views/oa/logs/logDetail/index.vue'),
      meta: {
        title: '日志明细',
        requireAuth: false,
      },
    },
  ],
} as RouteType

import { request } from '@/utils'

export default {
  getUnreadMsg: (pageSize: number) =>
    request.post(`/system/sys/dashboard/getUserAllMessage?readed=0&pageSize=${pageSize}`, { pageNumber: 1 }),
  getUserAllMessage: (data: any) =>
    request.post(`/system/sys/dashboard/getUserAllMessage?pageSize=${data.pageSize}&pageNumber=${data.pageNumber}`),
  saveReadBatch: (ids: string[]) => request.post('/system/sys/dashboard/message/saveRead/batch', ids),
  saveRead: (id: string) => request.get(`/system/sys/dashboard/message/saveRead?id=${id}`),
  getProjectDetail: (id: string) => request.post(`/project/main/list?pageSize=1&pageNumber=1`, { id }),
  getPhase: (id: string | null) => request.get(`/project/sys/project/projPhase/getPhase/${id}`),

  // 工作流
  getWorkflowCount: () => request.get('/workflow/wflow/erp/process/task/getProcessInstanceCount'),
}

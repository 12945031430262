import { request } from '@/utils'

export default {
  getEmptyCategoryBaseList: () => {
    return {
      betterFlag: null,
      biddingParameters: null,
      brand: null,
      brandId: null,
      certifyFileIds: [],
      dispose: null,
      disposeId: null,
      functionIntroduction: null,
      imageVideoFileIds: [],
      informationFileIds: [],
      inOutContractCode: null,
      mainFeatures: null,
      model: null,
      modelId: null,
      succession: null,
      successionId: null,
      summary: null,
      systemFileIds: [],
    }
  },
  getPropPageList: (params: PageParams) => {
    return request.post(
      `/purchase/purchase/properties/propPageList?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`,
      params.body || {}
    )
  },
  getPropApprovePageList: (params: PageParams) => {
    return request.post(
      `/purchase/purchase/properties/propApprovePageList?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`,
      params.body || {}
    )
  },
  getPropapproveHisPageList: (params: PageParams) => {
    return request.post(
      `/purchase/purchase/properties/propApproveHisPageList?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`,
      params.body || {}
    )
  },
  approveEdit: (data: any) => request.put('/purchase/purchase/properties/approveEdit', data),

  // getCategoryDropDownByCategoryId: (data: any) => request.post('/purchase/purchase/properties/categoryDropDownByCategoryId', data),
  editCategoryDetail: (data: any) => request.post('/purchase/purchase/properties/edit', data),
  getCategoryDetail: (data: any) => request.post('/purchase/purchase/properties/getDetail', data),
  getApproveCount: () => request.get('/purchase/purchase/properties/getApproveCount'),
  getEditDiff: (data: any) =>
    request.get(
      `/purchase/purchase/properties/getEditDiff?brandId=${data.brandId}&successionId=${data.successionId}&modelId=${data.modelId}&disposeId=${data.disposeId}`
    ),

  saveCategoryDetail: (data: any) => request.post('/purchase/purchase/properties/save', data),
  deleteCategoryDetail: (data: any) => request.post('/purchase/purchase/properties/delete', data),

  getBrandCascadeQueryByPid: (pId: any) => request.get(`/purchase/purchase/supply/service/type/brandCascadeQueryByPid?pId=${pId}`),

  getAllBrand: () => request.get('/purchase/purchase/supply/service/type/qeuryAllbrand'),
  getDirectionByCode: (code: string) => request.get(`/system/diction/findByCode/${code}`),
}

class Node<T> {
  public value: T
  public next: Node<T> | null

  constructor(value: T) {
    this.value = value
    this.next = null
  }
}
export class LinkStack<T> {
  private top: Node<T> | null
  private count: number

  constructor() {
    this.top = null
    this.count = 0
  }

  public getAllNodes(): T[] {
    const nodes: T[] = []
    let current = this.top
    while (current !== null) {
      nodes.push(current.value)
      current = current.next
    }
    return nodes
  }

  // 压栈
  public push(item: T): void {
    const node = new Node(item)
    node.next = this.top
    this.top = node
    this.count++
  }

  // 弹栈
  public pop(): T | null {
    if (this.count > 0 && this.top) {
      const res = this.top.value
      this.top = this.top.next
      this.count--
      return res
    }
    return null
  }

  // 新方法：弹出指定节点
  popNode(data: T): void {
    if (this.isEmpty()) {
      return
    }
    if (this.top!.value === data) {
      this.top = this.top!.next
      return
    }
    let current = this.top
    while (current!.next !== null) {
      if (current!.next.value === data) {
        current!.next = current!.next.next
        return
      }
      current = current!.next
    }
  }

  // 弹出指定节点之后 不包括指定节点
  popAfter(data: T): void {
    if (this.isEmpty()) {
      return
    }
    if (this.top!.value === data) {
      return
    }
    let current = this.top
    while (current!.next !== null) {
      if (current!.next.value === data) {
        this.top = current!.next
        return
      }
      current = current!.next
    }
  }

  // 清空栈
  public clear(): void {
    this.top = null
    this.count = 0
  }

  // 取栈顶元素
  public peek(): T | null {
    if (this.count > 0 && this.top) {
      return this.top.value
    }
    return null
  }

  // 判断栈是否为空
  public isEmpty(): boolean {
    return this.count === 0
  }
}

import { request } from '@/utils'

export default {
  getDataBoard: (params: any) => request.post('/system/sys/dashboard/getDataBoard', params || {}),
  getTaskCompanyName: () => request.get('/system/diction/findByCode/ops_mobile_task_company_name'),
  findByCode: (code: string) => request.get(`/system/diction/findByCode/${code}`),
  getSelectUser: (data: any) => request.post('/system/system/open/getSelectUser', data),
  getProjectData: (data: any) => request.post('/system/sys/dashboard/getProjectData', data),
  getPerformanceIndicatorData: (data: any) => request.post('/system/sys/dashboard/getPerformanceIndicatorData', data),
  getUserMessageByDate: (date: string) => request.get(`/system/sys/dashboard/getUserMessageByDate?date=${date}`),
  saveCloseNotify: (id: string) => request.get(`/system/sys/dashboard/message/saveCloseNotify?id=${id}`),

  getAnnouncementPageData: (params: PageParams) => request.post(`/hr/proclamate/getUserProclamateList`, params.body || {}),
  getUserProclamateNoReadListByLimit: (pageSize: number) =>
    request.post(`/hr/proclamate/getUserProclamateNoReadListByLimit?pageSize=${pageSize}`),

  getCurrentUserWorkLeaveUsed: (leaveType: string) => request.get(`/ops/workLeave/getCurrentUserWorkLeaveUsed?leaveType=${leaveType}`),
  getCurrentUserThisWeekLogStatus: () => request.get(`/ops/log/statistics/getCurrentUserThisWeekLogStatus`),
  // 获取最近年份
  getLastestYear: () => request.get('/data/project/report/info/getLastestYear'),
}

import { createInjectionKey } from 'naive-ui/es/_utils'
import type { PropType } from 'vue'
import type { InputProps, TreeOption } from 'naive-ui'
import type { CollapseItem, LayoutApi, LayoutInjection } from '@/views/cloud/components/layout/types'
import type { DynamicTableAction } from '@/components/table/dynamic/types'

export const FolderIcon = 'icon-park-outline:folder-open'
export const FileIcon = 'icon-park-outline:notebook-one'

export const cloudInjectionKey = createInjectionKey<LayoutInjection>('erp-cloud')

export const layoutProps = {
  api: {
    type: Object as PropType<LayoutApi>,
    default: () => {},
  },
  collapseOptions: {
    type: Array as PropType<Array<CollapseItem>>,
    default: () => [],
  },
  filterable: Boolean,
  treeLoading: Boolean,
  inputProps: Object as PropType<InputProps>,
  showTree: Function as PropType<(data: CollapseItem) => boolean>,
  showBreadcrumb: {
    type: Boolean,
    default: true,
  },
  showSider: {
    type: Boolean,
    default: true,
  },
  tree: {
    type: Array as PropType<Array<any>>,
    default: () => [],
  },
  onTreeLoad: Function as PropType<(node: TreeOption) => Promise<unknown>>,
  treeActions: {
    type: Array as PropType<DynamicTableAction[]>,
    default: () => [],
  },
  mode: {
    type: String,
    default: 'storage',
  },
}

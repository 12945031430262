import type { AxiosError, AxiosResponse, AxiosResponseHeaders } from 'axios'
import { AxiosRejectError, resolveResError } from './helpers'
import { getToken } from '~/src/utils/auth/token'
import type { RequestConfig } from '~/types/axios'
import { ResultEnum } from '@/enums/httpEnum'

const tokenHeader = 'X-Requested-Token'
const timeHeader = 'Accept-Time'
const NETWORK_TAG = 'X-Network-Tag'

/** 请求拦截 */
export function reqResolve(config: RequestConfig) {
  if (config.params) {
    config.params = {
      ...config.params,
      __networkTag: import.meta.env.VITE_NETWORK_TAG,
    }
  } else {
    config.params = {
      __networkTag: import.meta.env.VITE_NETWORK_TAG,
    }
  }

  // 处理不需要token的请求
  if (config.noNeedToken) {
    const headers = {} as AxiosResponseHeaders
    headers[NETWORK_TAG] = import.meta.env.VITE_NETWORK_TAG
    config.headers = headers
    return config
  }

  // 请求时间
  const time = Date.now()
  // 构建header
  const token = getToken()
  if (!token) {
    return Promise.reject(new AxiosRejectError({ code: 401, message: '登录已过期，请重新登录！' }))
  }

  /**
   * * 加上 token
   * ! 认证方案: JWT Bearer
   */
  if (config.headers) {
    const headers = config.headers as any
    headers[tokenHeader] = headers[tokenHeader] || `${token}`
    headers[NETWORK_TAG] = import.meta.env.VITE_NETWORK_TAG
    headers[timeHeader] = time
    config.headers = headers
  } else {
    const headers = {} as AxiosResponseHeaders
    headers[tokenHeader] = `${token}`
    headers[timeHeader] = time
    headers[NETWORK_TAG] = import.meta.env.VITE_NETWORK_TAG
    config.headers = headers
  }

  return config
}

/** 请求错误拦截 */
export function reqReject(error: AxiosError) {
  return Promise.reject(error)
}

/** 响应拦截 */
export function resResolve(response: AxiosResponse) {
  // TODO: 处理不同的 response.headers
  const { data, status, config, statusText } = response
  if (data instanceof Blob) {
    return Promise.resolve(response)
  }
  const { noNeedTip, noResData } = config as RequestConfig
  if (noResData) {
    return Promise.resolve(response)
  } else if (data?.code !== ResultEnum.SUCCESS) {
    const code = data?.code ?? status

    /** 根据code处理对应的操作，并返回处理后的message */
    const message = resolveResError(code, data?.msg ?? statusText)
    !noNeedTip && window.$message?.error(message)
    return Promise.reject(new AxiosRejectError({ code, message, data: data || response }))
  }
  return Promise.resolve(data)
}

/** 响应错误拦截 */
export function resReject(error: AxiosError) {
  if (!error || !error.response) {
    const code = error?.code
    /** 根据code处理对应的操作，并返回处理后的message */
    const message = resolveResError(code, error.message)
    window.$message?.error(message)
    return Promise.reject(new AxiosRejectError({ code, message, data: error }))
  }
  const { data, status, config } = error.response
  let { code, message } = data as AxiosRejectError
  code = code ?? status
  message = message ?? error.message
  message = resolveResError(code, message)
  /** 需要错误提醒 */
  const { noNeedTip } = config as RequestConfig

  !noNeedTip && window.$message?.error(message)
  return Promise.reject(new AxiosRejectError({ code, message, data: error.response?.data || error.response }))
}

import type { RouteType } from '~/types/router'
const Layout = () => import('@/layout/index.vue')

export default {
  name: 'System2',
  path: '/system',
  component: Layout,
  redirect: '/',
  isHidden: true,
  meta: {
    title: '系统管理',
    order: 0,
  },
  children: [
    {
      name: 'DynamicFormRender',
      path: 'dynamicForm/render',
      component: () => import('@/views/system/dynamic-form/iframe-wrapper.vue'),
      isHidden: true,
      meta: {
        title: '动态表单详情',
      },
    },
  ],
} as RouteType

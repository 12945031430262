import type { App, Directive, DirectiveBinding } from 'vue'
import { useDebounceFn, useMutationObserver } from '@vueuse/core'

const title = import.meta.env.VITE_APP_TITLE
const watermarkClass = 'erp-watermark'

const watermarkDirective: Directive = {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    if (binding.value !== false) {
      const props = binding.value ?? {}
      addWaterMark(props.text, el, props.font, props.textColor)
      watchDom(el, props)
    }
  },
}

function watchDom(el: HTMLElement, props: any) {
  const handleDomChange = useDebounceFn((mutations: MutationRecord[]) => {
    let needAddWatermark = false
    mutations.forEach((mutation: MutationRecord) => {
      const { removedNodes, type, target } = mutation
      if (removedNodes && removedNodes.length) {
        removedNodes.forEach((node: any) => {
          needAddWatermark = node.classList?.contains(watermarkClass)
        })
      }
      if (type === 'attributes' && (target as any).classList?.contains(watermarkClass)) {
        el.removeChild(target)
      }
    })
    if (needAddWatermark) {
      addWaterMark(props.text, el, props.font, props.textColor)
    }
  }, 150)
  useMutationObserver(el, handleDomChange, {
    attributes: true,
    subtree: true,
    childList: true,
  })
}

function addWaterMark(text: string, parentNode: HTMLElement, font: string, textColor: string | CanvasGradient | CanvasPattern) {
  const canvas = document.createElement('canvas') as HTMLCanvasElement
  canvas.width = 300
  canvas.height = 170
  canvas.style.display = 'none'
  const context = canvas.getContext('2d') as CanvasRenderingContext2D
  context.rotate((Math.PI / 180) * -15)
  context.font = font || '16px Microsoft JhengHei'
  context.fillStyle = textColor || 'rgba(193, 193, 193, .3)'
  context.textAlign = 'center'
  context.textBaseline = 'middle'
  context.fillText(text || title, canvas.width / 2, canvas.height / 2)

  const container = document.createElement('div') as HTMLDivElement
  container.classList.add(watermarkClass)
  container.style.backgroundImage = `url(${canvas.toDataURL('image/png')})`
  container.style.position = 'absolute'
  container.style.top = '0'
  container.style.left = '0'
  container.style.width = '100%'
  container.style.height = '100%'
  container.style.pointerEvents = 'none'
  container.style.position = 'absolute'
  container.style.zIndex = '10'
  parentNode.appendChild(container)
}

export function setupWatermarkDirective(app: App) {
  app.directive('watermark', watermarkDirective)
}

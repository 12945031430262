<script lang="ts">
          import { defineComponent } from 'vue'
          export default defineComponent({
            name: "undefined"
          })
        </script><script setup lang="ts">
import AppProvider from '@/components/common/AppProvider.vue'
</script>

<template>
  <AppProvider>
    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
  </AppProvider>
</template>

import type { App } from 'vue'
import Crud, { locale } from '@zhsz/cool-design-crud'
import VXETable from 'vxe-table'
import commonApi from '@/api'
import { PageConfig } from '@/enums/commonEnum'

export function setupCrud(app: App) {
  app.use(Crud, {
    pageLayoutId: PageConfig.PageLayoutId,
    showWatermark: true,
    watermark: {
      height: 85,
      width: 300,
      offset: [60, 95],
      x: 120,
      y: 60,
      removable: false,
    },
    dict: {
      pagination: {
        page: 'pageNumber',
        size: 'pageSize',
      },
      sort: {
        prop: 'sortName',
        order: 'sortOrder',
      },
      label: locale.zhCn,
    },
    style: {
      size: 'medium',
    },
    render: {
      autoHeight: true,
    },
    remoteTableCustom: (data: any[]) => {
      if (data?.length) {
        Promise.all(
          data.map((item) =>
            commonApi.updateCustomSetting({
              bizCode: 'website',
              code: item.key,
              data: item.value,
            })
          )
        )
          .then()
          .catch((err) => {
            console.error(err)
          })
      }
    },
  })

  VXETable.renderer.add('TextLink', {
    // 默认显示模板
    renderDefault(renderOpts, params) {
      const { row, column } = params
      const { events } = renderOpts
      return h(
        'a',
        {
          class: 'text-link',
          onClick: () => events?.click(params),
        },
        row[column.field] as string
      )
    },
  })
}

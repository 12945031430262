import { request } from '@/utils'

export default {
  // getCategory: (data: any) => request.post('/purchase/purchase/partner/union/queryPartnerCategory', data),
  getCategoryOptions: () => request.get('/purchase/purchase/supply/service/type/categoryCascadeQuery'),
  addPartCategoryRelationship: (data: any) => request.post('/purchase/purchase/partner/union/addPartnerCategory', data),
  deletePartCategoryRelationship: (data: any) => request.post('/purchase/purchase/partner/union/deletePartnerCategory', data),
  updatePartCategoryRelationship: (data: any) => request.post('/purchase/purchase/partner/union/updatePartnerCategory', data),
  queryCategoryList: (data: any) => request.post('/purchase/purchase/partner/union/queryCategoryList', data),
  getPreferential: () => request.get('/system/diction/findByCode/purchase_category_preferential'),
  getPartnerAll: () => request.get('/purchase/purchase/partner/findAll'),
  getTransportationExpenses: () => request.get('/system/diction/findByCode/purchase_category_transportation_expenses'),
  getBrandCascadeQueryByPid: (pId: any) => request.get(`/purchase/purchase/supply/service/type/brandCascadeQueryByPid?pId=${pId}`),
  getAllBrand: () => request.get('/purchase/purchase/supply/service/type/qeuryAllbrand'),
  getSupplyPriceByPid: (categoryId: any) => request.get(`/purchase/purchase/partner/union/id/${categoryId}`),

  getCategory: (params: PageParams) =>
    request.post(
      `/purchase/purchase/partnerV2/pageListCategory?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`,
      params.body || {}
    ),
  getAlternateCategory: (params: PageParams) =>
    request.post(
      `/purchase/purchase/alternate/pageListAlternateCategory?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`,
      params.body || {}
    ),
  getBrand: () => request.get('/purchase/purchase/supply/service/type/brandCascadeQuery'),
}

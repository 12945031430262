import type { RouteType } from '~/types/router'
const Layout = () => import('@/layout/index.vue')

export default {
  name: 'project2',
  path: '/project',
  component: Layout,
  isHidden: true,
  meta: {
    order: 0,
  },
  children: [
    {
      name: 'brandReport',
      path: 'brandReport',
      isHidden: true,
      component: () => import('./list/brand-report/index.vue'),
      meta: {
        title: '品牌报备',
      },
    },
    {
      name: 'contactList1',
      path: 'contactList',
      isHidden: true,
      component: () => import('./list/contact-list/index.vue'),
      meta: {
        title: '合同清单',
      },
    },
  ],
} as RouteType

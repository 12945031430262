import { getCurrentInstance } from 'vue'
import type { Ref } from 'vue'
import { isObject, pickBy } from 'lodash-es'

export function useParent(name: string, r: Ref) {
  const d = getCurrentInstance()

  if (d) {
    let parent = d.proxy?.$.parent

    if (parent) {
      while (parent && parent.type?.name !== name) parent = parent?.parent

      if (parent) {
        if (parent.type.name === name) {
          r.value = parent.exposed
        }
      }
    }
  }

  return r
}

export function processItem(
  item: { [key: string]: any },
  fieldNames: {
    value?: string
    label?: string
    children?: string
    [key: string]: any
  } = {
    value: 'value',
    label: 'label',
    children: 'children',
  }
): any {
  const outputItem: any = {}
  if (isObject(item)) {
    for (const key in fieldNames) outputItem[key] = item[fieldNames[key]]

    if (item[fieldNames.children] && item[fieldNames.children].length > 0)
      outputItem.children = item[fieldNames.children].map((s: any) => processItem(s, fieldNames))

    return pickBy(outputItem, (value) => value !== undefined)
  } else {
    for (const key in fieldNames) outputItem[key] = item

    return pickBy(outputItem, (value) => value !== undefined)
  }
}

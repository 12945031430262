import { request } from '@/utils'

export default {
  // 品牌报备反馈
  brandFilingDeal: (data: any) => request.post(`/project/project/brand_filing/brandFilingDeal`, data),
  getSelectUser: (data: any) => request.post('/system/system/open/getSelectUser', data),
  // 品牌报备申请列表查询
  brandFilingApplyPageList: (data: any) => request.post(`/project/project/brand_filing/brandFilingApplyPageList`, data.body),
  // 品牌报备记录列表查询
  brandFilingRecordPageList: (data: any) => request.post(`/project/project/brand_filing/brandFilingRecordPageList`, data.body),
  // 报备申请审核
  brandFilingApprove: (data: any) => request.post(`/project/project/brand_filing/brandFilingApprove`, data),
  // 报备申请申请提交
  applySubmit: (data: any) => request.post(`/project/project/brand_filing/applySubmit`, data),
  // 可申请品牌报备列表查询
  brandPageList: (data: any) => request.post(`/project/project/brand_filing/brandPageList`, data),
  getCategoryCascade: () => request.get('/purchase/purchase/supply/service/type/categoryCascadeQuery'),
  getBrandCascade: () => request.get('/purchase/purchase/supply/service/type/brandCascadeQuery'),
  getBrandFilingSchemeList: (projectId: string) =>
    request.get(`/project/project/design/scheme/getBrandFilingSchemeList?projectId=${projectId}`),
  brandReportList: (data: any) => request.post(`project/start/brandReportList`, data.body),
  // 取消申请
  cancelSubmit: (data: any) => request.post(`project/project/brand_filing/cancelSubmit`, data),
  // 是否为项目的售前工程师
  isProjectPreSale: (data: any) => request.get(`project/project/brand_filing/isProjectPreSale?projectId=${data.projectId}`),
}

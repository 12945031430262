import { request } from '@/utils'

export default {
  getEmptyMobileTask: () => {
    return {
      id: null,
      name: null,
      provinceCityRegion: null,
      originatorId: [],
      taskName: null,
      source: null,
      type: null,
      importantDegree: null,
      urgencyDegree: null,
      appointmentTime: null,
      serviceTime: null,
      description: null,
      imageIdList: [],
      projectId: null,
      projectCustomerCompany: null,
      customerContactId: [],
      mobileTaskAddressDTO: {
        country: null,
        province: null,
        city: null,
        region: null,
        address: null,
        completeAddress: null,
        longitude: null,
        latitude: null,
      },
      deptId: null,
      projectExpired: null,
      productExpired: null,
      companyCode: null,
      followUserId: null,
      saleUserId: null,
      status: 0,
      assistantUserId: [],
    }
  },
  getMobileTaskByPage: (params: PageParams) => {
    return request.post(`/ops/ops/mobiletask/pageList?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`, params.body || {})
  },

  getUserBySelectCode: (selectCode: string) => request.get(`/ops/ops/taskCustomer/getUserBySelectCode?selectCode=${selectCode}`),

  getInvoicingByTaskId: (id: string) => request.get(`/ops/ops/taskInvoicing/getInvoice?taskId=${id}`),
  addInvoicing: (data: any) => request.post('/ops/ops/taskInvoicing/openInvoice', data),
  editInvoicing: (data: any) => request.post('/ops/ops/taskInvoicing/editInvoice', data),

  getTaskSource: () => request.get('/system/diction/findByCode/ops_mobile_task_source'),
  getTaskType: () => request.get('/system/diction/findByCode/ops_mobile_task_type'),
  getTaskProjectExpired: () => request.get('/system/diction/findByCode/ops_mobile_task_project_expired'),
  getTaskProductExpired: () => request.get('/system/diction/findByCode/ops_mobile_task_product_expired'),
  getTaskCompanyName: () => request.get('/system/diction/findByCode/ops_mobile_task_company_name'),
  getTaskStatus: () => request.get('/system/diction/findByCode/ops_mobile_task_status'),
  getTaskProgression: () => request.get('/system/diction/findByCode/ops_task_progression'),
  getInvocingType: () => request.get('/system/diction/findByCode/ops_task_invocing_type'),
  getTaskSettleState: () => request.get('/system/diction/findByCode/ops_task_settle_state_code'),
  getDevicesBacktime: () => request.get('/system/diction/findByCode/ops_mobile_task_devices_backtime'),
  getUser: () => request.get('/system/user/findAll'),
  getDeptList: () => request.post('/system/sys/department/list'),

  getCompanyTitle: () => request.get('/system/diction/findByCode/proj_approval_company'),

  addMobileTask: (data: any) => request.post('/ops/ops/mobiletask/submit', data),
  addMobileTaskDraft: (data: any) => request.post('/ops/ops/mobiletask/saveDraft', data),
  // assignMobileTask: (data: any) => request.post('/ops/ops/mobiletask/edit', data),
  editMobileTask: (data: any) => request.post('/ops/ops/mobiletask/editTask', data),
  getTaskByTaskId: (id: string) => request.get(`/ops/ops/mobiletask/getDetail/${id}`),
  getOpsMobileUserTaskCount: () => request.get('/ops/ops/taskCustomer/getOpsMobileUserTaskCount'),
  getCustomerAll: () => request.get('/project/customer/profile/findAll'),
  getCompanyNameList: () => request.get('/project/project/open/getCustomerCompany'),
  getTaskCustomerCompany: () => request.get('/ops/ops/taskCustomer/getCustomerCompany'),
  // 公共接口
  getProjectAll: () => request.get('/project/project/open/getProjectFormSelectList'),
  getCustomerList: (id: string) => request.get(`/ops/ops/taskCustomer/getCustomerDropList?unitId=${id}`),
  addCustomer: (data: any) => request.post('/ops/ops/taskCustomer/saveCustomer', data),
  addCustomerUnit: (data: any) => request.post('/ops/ops/taskCustomer/saveCustomerCompany', data),

  editTaskSettleRemark: (data: any) => request.post('/ops/ops/confirmLetter/editTaskSettleRemark', data),
  addTaskSettleRemark: (data: any) => request.post('/ops/ops/confirmLetter/taskSettleRemark', data),
  getTaskSettleRemark: (id: string) => request.get(`/ops/ops/confirmLetter/getSettleRemark?taskId=${id}`),
  taskSettle: (data: any) => request.post(`/ops/ops/confirmLetter/taskSettle?taskId=${data.taskId}&stateCode=${data.stateCode}`),

  getMaintenances: (data: any) => request.get(`/ops/ops/confirmLetter/getMaintenances?taskId=${data.taskId}&queryApply=${data.queryApply}`),
  maintenanceConfirm: (data: any) => request.post('/ops/ops/confirmLetter/maintenanceConfirm', data),

  getSignList: (data: any) => request.post('/ops/ops/taskSign/getSignList', data),
  getSignOfTimePhase: (data: any) => request.post('/ops/ops/taskSign/getSignOfTimePhase', data),

  getFeedBack: (data: any) => request.post('/ops/ops/mobiletask/feedback/getFeedBack', data),
  getFeedBackOfTimePhase: (data: any) => request.post('/ops/ops/mobiletask/feedback/getFeedBackOfTimePhase', data),

  getAuxiliaryMaterials: () => request.get('/system/diction/findByCode/ops_mobile_task_auxiliaryMaterials'),
  getFaultyEquipment: () => request.get('/system/diction/findByCode/ops_mobile_task_faultyEquipment'),
  getAllBrand: () => request.get('/purchase/purchase/supply/service/type/qeuryAllbrand'),
  getBrandCascadeQueryByPid: (pId: any) => request.get(`/purchase/purchase/supply/service/type/brandCascadeQueryByPid?pId=${pId}`),
  getCategoryUnit: () => request.get('/system/diction/findByCode/purchase_category_unit'),
  getLetterByTaskId: (id: string) => request.get(`/ops/ops/confirmLetter/getDetail/${id}`),
  pushLetter: (data: any) => request.post('/ops/ops/confirmLetter/pushLetter', data),
  pushLetterForCustomer: (data: any) => request.post('/ops/ops/confirmLetter/proxyConfirmLetter', data),
  rollBackLetter: (id: any) => request.post(`/ops/ops/confirmLetter/rollBackLetter?taskId=${id}`),
  exportLog: (data: any) => request.post('/ops/ops/mobiletask/exportFeedBackData', data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),
  exportSign: (data: any) => request.post('/ops/ops/taskSign/export', data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),
  getFeedBackReport: (params: PageParams) => request.post('/ops/ops/mobiletask/feedback/queryFeedbackList', params.body || {}),

  getLetterDetailV2: (taskId: string) => request.get(`/ops/ops/confirmLetter/v2/getLetterDetail?taskId=${taskId}`),
  getServiceType: () => request.get('/system/diction/findByCode/ops_mobile_task_type'),
  getLetterAdditionDetail: (letterId: string) => request.get(`/ops/ops/confirmLetter/v2/getLetterAdditionDetail?letterId=${letterId}`),
  getCustomerAppraise: (letterId: string) => request.get(`/ops/ops/confirmLetter/v2/getCustomerAppraise?letterId=${letterId}`),
  saveLetterAddition: (data: any) => request.post('/ops/ops/confirmLetter/v2/saveLetterAddition', data),
}

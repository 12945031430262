import api from './api'
import type { ResponseResult } from '~/types/axios'

async function Middleware(req: (body: any) => Promise<ResponseResult<any>>, params: any, original = false) {
  const res = await req(params)

  if (original) {
    return res
  } else {
    return res.data
  }
}

export const service = {
  page: (params: PageParams) => Middleware(api.getPageListCategory, params || {}),
  add: (params?: any) => Middleware(api.addCategory, params || {}),
  delete: (params?: any) => Middleware(api.delForCategory, params.id || {}),
  update: () => '',
  list: (params?: any) => Middleware(api.getTypeCascadeQuery, params || {}),
  getBrand: () => Middleware(api.getBrand, {}),
  brandSelectDown: () => Middleware(api.brandSelectDown, {}),
  getDirectionByCode: (params?: any) => Middleware(api.getDirectionByCode, params || {}),
}

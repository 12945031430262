import { request } from '@/utils'

export default {
  getEmptyUser: () => {
    return {
      name: null,
    }
  },
  saveUser: (data: any) => request.post('/system/user/add', data),
  getAllRole: () => request.get('/system/system/open/getRole'),

  getUserList: (params: PageParams) =>
    request.post(`/hr/entry/manager/pageList?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}`, params.body),
  getStatistics: () => request.post('/hr/entry/manager/statistics'),
  getEntryInfo: (userId: string | null) => request.get(`/hr/entry/manager/getEntryInfo?userId=${userId}`),
  processEntry: (data: any) => request.post('/hr/entry/manager/process', data),
  confirmEntry: (data: any) => request.post('/hr/entry/manager/confirm', data),
  adjustEntry: (data: any) => request.post('/hr/entry/manager/adjust', data),
  cancelEntry: (userId: any) => request.post(`/hr/entry/manager/cancel?userId=${userId}`),
  getAllUser: () => request.post('/hr/roster/employee/getAllUser'),
  getSelectUser: (data: any) => request.post('/system/system/open/getSelectUser', data),
  getAllJob: () => request.get('/system/system/open/getJob'),
}

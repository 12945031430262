import { request } from '@/utils'

export default {
  getPickGoodsList: (params: PageParams) =>
    request.post(
      `/purchase/category/project/pageListAllProject?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}`,
      params.body || {}
    ),
  submitToPurchase: (ids: Array<string | number>) => request.post('/purchase/category/project/submitPurchase', ids),
  deleteGood: (id: string) => request.delete(`/purchase/category/project/delete?applyIds=${id}`),
  queryDetail: (id: string) => request.get(`/warehouse/receiveGoods/getArrangeDetailToProjectManager/${id}`),
  confirmReceive: (data: any) => request.post('/purchase/category/project/saveArrangeDetail', data),
  queryLogistics: (id: string, type: number) =>
    request.get(`/purchase/category/project/queryLogistics?applyId=${id}&logisticsType=${type}`),
  revert: (ids: Array<string>) => request.post('/purchase/category/project/returnback', ids),
  refresh: (data: any) => request.post('/purchase/category/project/refreshMatch', data),
  getCategoryCascade: () => request.get('/purchase/purchase/supply/service/type/categoryCascadeQuery'),
  getBrandCascade: () => request.get('/purchase/purchase/supply/service/type/brandCascadeQuery'),
  getDirectionByCode: (code: string) => request.get(`/system/diction/findByCode/${code}`),
  download: (projectNo: string, projectName: string) =>
    request.post(
      `/purchase/category/project/downloadTemplate?projectNo=${projectNo}&projectName=${projectName}`,
      {},
      { responseType: 'blob' }
    ),
}

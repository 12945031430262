import { request } from '@/utils'

export default {
  getEmptyRecommendBrand: (categoryId?: string, name?: string) => {
    return {
      categoryId,
      brand: null,
      name,
    }
  },

  // 分类的级联查询
  getTypeCascadeQuery: (params?: { keyword?: string }) =>
    request.get('/purchase/purchase/supply/service/typeV2/typeCascadeQuery', { params }),

  getCategory: () => request.get('/purchase/purchase/supply/service/type/categoryCascadeQuery'),
  getBrand: () => request.get('/purchase/purchase/supply/service/type/brandCascadeQuery'),
  addClassification: (data: Recordable) => request.post('/purchase/purchase/supply/service/typeV2/add', data),
  delForCategory: (categoryId: string) => request.get(`/purchase/purchase/supply/service/typeV2/delForCategory?categoryId=${categoryId}`),
  downloadTemplate: () => request.get('/purchase/purchase/supply/service/typeV2/downloadTemplate'),
  editClassification: (data: Recordable) => request.post('/purchase/purchase/supply/service/typeV2/edit', data),
  listTypeByLevel: () => request.get('/purchase/purchase/supply/service/typeV2/listTypeByLevel'),
  listTypeByPid: () => request.get('/purchase/purchase/supply/service/typeV2/listTypeByPid'),
  switchEnable: (data: Recordable) => request.post('/purchase/purchase/supply/service/typeV2/switchEnable', data),
  typeCascadeQuery: (keyword: string) => request.get(`/purchase/purchase/supply/service/typeV2/typeCascadeQuery/${keyword}`),
  getRecommendBrand: (categoryId: string) => request.get(`/purchase/category/recommend/get?categoryId=${categoryId}`),
  saveRecommendBrand: (data: Recordable) => request.post('/purchase/category/recommend/save', data),
  deletePartnerPrice: (data: Recordable) => request.post('/purchase/purchase/categoryV2/deletePartnerPrice', data),
  getAllBrand: () => request.get('/purchase/purchase/supply/service/type/qeuryAllbrand'),

  // 新
  getDirectionByCode: (code: string) => request.get(`/system/diction/findByCode/${code}`),

  getEmptyBrandSuccession: (data?: any) => {
    return {
      code: null,
      enable: 1,
      name: data?.name,
      pname: data?.pname,
      pid: data?.id,
      typeCode: data?.typeCode,
      anotherName: [
        {
          atherName: null,
        },
      ],
    }
  },
  addCategory: (data = {}) => request.post('/purchase/purchase/categoryV2/addCategory', data),
  addSelfCategory: (data = {}) => request.post('/purchase/purchase/categoryV2/addSelfDevCategory', data),
  addCategoryProp: (data = {}) => request.post('/purchase/purchase/categoryV2/addCategoryProp', data),
  addPartnerPrice: (data = {}) => request.post('/purchase/purchase/categoryV2/addPartnerPrice', data),
  editPartnerPrice: (data = {}) => request.post('/purchase/purchase/categoryV2/editPartnerPrice', data),
  getDetailPartnerPrice: (partnerId: string, categoryId: string) =>
    request.get(`/purchase/purchase/categoryV2/getDetailPartnerPrice?partnerId=${partnerId}&categoryId=${categoryId}`),
  brandCascade: (params?: { keyword?: string }) => request.get('/purchase/purchase/categoryV2/brandCascade', { params }),
  categoryEnquiry: (params: any) => {
    return request.get(
      `/purchase/purchase/categoryV2/categoryEnquiry?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}&categoryId=${params.categoryId}`,
      params.body || {}
    )
  },
  editCategory: (data = {}) => request.post('/purchase/purchase/categoryV2/editCategory', data),
  editCategoryProp: (data = {}) => request.post('/purchase/purchase/categoryV2/editCategoryProp', data),
  getCategoryEnquiry: (params: any) =>
    request.get(
      `/purchase/purchase/categoryV2/categoryEnquiry?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}&categoryId=${params.categoryId}`,
      params.body || {}
    ),
  getDetailCategory: (id: string) => request.get(`/purchase/purchase/categoryV2/getDetailCategory?id=${id}`),
  getCategoryPropDetail: (id: string) => request.get(`/purchase/purchase/categoryV2/getCategoryPropDetail?id=${id}`),
  getPageListCategory: (body: PageParams) => {
    if (body.typeCode === 'xl')
      return request.post(
        `/purchase/purchase/categoryV2/pageListCategory?pageSize=${body.pageSize}&pageNumber=${body.pageNumber}`,
        body || {}
      )
    else
      return request.post(
        `/purchase/purchase/categoryV2/pageListSuccessionByBrand?pageSize=${body.pageSize}&pageNumber=${body.pageNumber}`,
        body || {}
      )
  },

  getInquiryList: (params: PageParams, id: any) =>
    request.post(`/purchase/purchase/category/enquiry?categoryId=${id}&pageNumber=${params.pageNumber}&pageSize=${params.pageSize}`),
  getPreferential: () => request.get('/system/diction/findByCode/purchase_category_preferential'),
  getPartnerAll: () => request.get('/purchase/purchase/partner/findAll'),
  getTransportationExpenses: () => request.get('/system/diction/findByCode/purchase_category_transportation_expenses'),

  getPartnerType: () => request.get('/system/diction/findByCode/purchase_partner_type'),
  getCategoryUnit: () => request.get('/system/diction/findByCode/purchase_category_unit'),
  getCategoryColor: () => request.get('/system/diction/findByCode/purchase_category_color'),
  getCategoryStatus: () => request.get('/system/diction/findByCode/purchase_category_status'),

  getCategoryList: (params?: any) => request.post('/purchase/purchase/partner/union/queryCategoryList', params),
  checkPropDuplicate: (data?: any) => request.post('/purchase/purchase/categoryV2/checkPropDuplicate', data),
  cascaderQueryByPid: (pId: any) => request.get(`/purchase/purchase/supply/service/type/brandCascadeQueryByPid?pId=${pId}`),
  getCategoryCascade: () => request.get('/purchase/purchase/supply/service/type/categoryCascadeQuery'),
  queryTypeInfoBySuccession: (successionId: any) =>
    request.get(`/purchase/purchase/categoryV2/queryTypeInfoBySuccession?successId=${successionId}`),
  getSelectUser: (data: any) => request.post('/system/system/open/getSelectUser', data),
  getSelectLabel: (bussCode: string) => request.get(`purchase/label/manager/listAllLabelSelect?bussCode=${bussCode}`),
  getCanEditField: (categoryId: string, bussCode: string, partnerId: string | null) =>
    request.get(
      `/purchase/purchase/permission/config/getAllPermissionField?categoryId=${categoryId}&bussCode=${bussCode}&partnerId=${partnerId}`
    ),
  isAllFieldReadOnly: (categoryId: string, bussCode: string, partnerId: string | null) =>
    request.get(
      `/purchase/purchase/permission/config/isAllFieldReadOnly?categoryId=${categoryId}&bussCode=${bussCode}&partnerId=${partnerId}`
    ),
}

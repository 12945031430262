import type { RouteType } from '~/types/router'
const Layout = () => import('@/layout/index.vue')

export default {
  name: 'cloudShare',
  path: '/share',
  component: Layout,
  isHidden: true,
  meta: {
    order: 0,
  },
  children: [
    {
      name: 'cloudShareStorage',
      path: 'storage',
      isHidden: true,
      component: () => import('/src/views/cloud/share/storage/index.vue'),
      meta: {
        title: '文档共享',
      },
    },
    {
      name: 'cloudShareLearn',
      path: 'learn',
      isHidden: true,
      component: () => import('/src/views/cloud/share/learn/index.vue'),
      meta: {
        title: '共享学习',
      },
    },
  ],
} as RouteType

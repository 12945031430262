import { request } from '@/utils'

export default {
  getEmptyUser: () => {
    return {
      name: null,
    }
  },
  saveUser: (data: any) => request.post('/system/user/add', data),
  getAllRole: () => request.get('/system/system/open/getRole'),

  getUserList: (params: PageParams) =>
    request.post(`/hr/regular/manager/pageList?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}`, params.body),
  getStatistics: () => request.post('/hr/regular/manager/statistics'),
  regularDetail: (userId: string | null) => request.get(`/hr/regular/manager/regularDetail?userId=${userId}`),
  // doLevel: (data: any) => request.post('/hr/roster/employee/doLevel', data),
  confirmRegular: (data: any) => request.post('/hr/regular/manager/confirmRegular', data),
  changeRegular: (data: any) => request.post('/hr/regular/manager/changeRegular', data),
  // cancelLeave: (userId: any) => request.post(`/hr/roster/employee/cancelLeave?userId=${userId}`, {}),
  getAllUser: () => request.post('/hr/roster/employee/getAllUser'),
}

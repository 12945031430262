import { ref } from 'vue'
import { useClipboard } from '@vueuse/core'
import { useMessage } from 'naive-ui'

export default function useCopy(onCopyClear: () => void) {
  const isCopy = ref(false)
  const { text, copy: copyFn } = useClipboard()
  const $message = useMessage()
  function openCopy() {
    isCopy.value = !isCopy.value
    copyFn('')
    onCopyClear()
  }
  function checkCopyContent() {
    const data = JSON.parse(text.value)
    if (!data) {
      $message?.warning('无可粘贴内容,请先复制数据')
      return false
    }
    return data
  }
  function _onCopy(listType: any, type: number) {
    console.log(listType)
    if (type === 0) {
      copyFn(
        JSON.stringify({
          categoryList: [
            {
              id: '',
              classify: '1695419324803739648',
              classifyName: 'LCD会议一体机',
              brand: '1695426603963805696',
              brandName: 'Newline',
              model: '1695426604278378496',
              modelName: 'NE65GA2',
              categoryId: '1695426789775667200',
              categoryName: '触摸一体机',
              num: 1,
              unit: null,
              unitName: null,
              remark: null,
              sync: true,
              deleteData: false,
              disabled: false,
              copy: false,
            },
          ],
          type2List: [
            {
              list: [
                {
                  sync: true,
                  num: 1,
                  deleteData: false,
                  copy: true,
                  categoryName: '网络硬盘录像机NVR',
                  categoryId: '1731932048375050240',
                  unitName: '台',
                  unit: 'tai',
                  label: '网络硬盘录像机NVR<海康威视-DS-7108N-F1(C)>',
                  value: '1731932048375050240',
                  type2Id: '1695419324770185216',
                  type2Name: null,
                  classify: '1695419324816322565',
                  classifyName: '存储设备',
                  brand: '1695426636041842688',
                  brandName: '海康威视',
                  series: '1731931684162662400',
                  seriesName: 'DS-7100N-F1系列 mini型',
                  model: '1731931684439486464',
                  modelName: 'DS-7108N-F1(C)',
                  marketPrice: 0.2,
                  costPrice: 189,
                  technologyParameters:
                    '• 可接驳符合ONVIF、RTSP 标准及众多主流厂商的网络摄像机\n• 支持H.265高效视频编码码流，支持H.265、H.264IP设备混合接入；\n• 支持4路1080P解码，推荐满路数接入200万及以下像素网络摄像机；\n• 最大支持400万像素高清网络视频的预览、存储与回放；\n• 支持HDMI与VGA同源高清输出，HDMI与VGA输出分辨率最高均可达1920×1080\n• 支持1个SATA接口，最大支持6T硬盘；\n• 支持智能搜索、回放及备份功能，有效提高录像检索与回放效率；\n• 支持萤石云服务，可实现手机远程预览回放\n• 可接驳符合ONVIF、RTSP 标准及众多主流厂商的网络摄像机\n• 支持H.265高效视频编码码流，支持H.265、H.264IP设备混合接入；\n• 支持4路1080P解码，推荐满路数接入200万及以下像素网络摄像机；\n• 最大支持400万像素高清网络视频的预览、存储与回放；\n• 支持HDMI与VGA同源高清输出，HDMI与VGA输出分辨率最高均可达1920×1080\n• 支持1个SATA接口，最大支持6T硬盘；\n• 内置4个/8个PoE网口，支持IPC直连供电和出图；\n• 支持智能搜索、回放及备份功能，有效提高录像检索与回放效率；\n• 支持萤石云服务，可实现手机远程预览回放；',
                  recommend: 0,
                  mateType: null,
                  vid: '1731932048375050240',
                },
                {
                  sync: true,
                  deleteData: false,
                  num: 5,
                  copy: true,
                  categoryName: '红外高清网络半球型摄像机',
                  categoryId: '1695426794062245888',
                  unitName: '台',
                  unit: 'tai',
                  label: '红外高清网络半球型摄像机<海康威视-海康DS-2CD7127EWD-IZ（2.8-12mm）>',
                  value: '1695426794062245888',
                  type2Id: '1695419324770185216',
                  type2Name: null,
                  classify: '1695419324803739655',
                  classifyName: '监控摄像头',
                  brand: '1695426636041842688',
                  brandName: '海康威视',
                  series: '1695426636075397120',
                  seriesName: '-',
                  model: '1695426636121534464',
                  modelName: '海康DS-2CD7127EWD-IZ（2.8-12mm）',
                  marketPrice: 0,
                  costPrice: 1060,
                  technologyParameters: null,
                  recommend: 0,
                  mateType: null,
                  vid: '1695426794062245888',
                },
              ],
              typeId: '1695419324770185216',
              copy: false,
            },
            {
              id: '',
              list: [
                {
                  id: '',
                  classify: '1695419324803739648',
                  classifyName: 'LCD会议一体机',
                  brand: '1695426603963805696',
                  brandName: 'Newline',
                  model: '1695426604278378496',
                  modelName: 'NE65GA2',
                  categoryId: '1695426789775667200',
                  categoryName: '触摸一体机',
                  num: 1,
                  unit: null,
                  unitName: null,
                  remark: null,
                  sync: true,
                  deleteData: false,
                  disabled: false,
                  copy: false,
                },
              ],
              typeId: '1695419324736630788',
              deleteData: false,
              copy: false,
            },
            {
              id: '',
              list: [
                {
                  id: '',
                  classify: '1695419324807933962',
                  classifyName: '数字调音台',
                  brand: '1695426628299157504',
                  brandName: 'SOUNDCRAFT',
                  model: '',
                  modelName: null,
                  categoryId: '',
                  categoryName: null,
                  num: 1,
                  unit: '',
                  unitName: null,
                  remark: null,
                  sync: true,
                  deleteData: false,
                  disabled: false,
                  copy: false,
                },
              ],
              typeId: '1695419324740825090',
              deleteData: false,
              copy: false,
            },
          ],
        })
      )
    }
    if (type === 1) {
      copyFn(
        JSON.stringify({
          categoryList: [
            {
              id: '1726559277939843072',
              classify: '1695419324807933962',
              classifyName: '数字调音台',
              classifyText: null,
              brand: '1695426628299157504',
              brandName: 'SOUNDCRAFT',
              brandText: null,
              model: '',
              modelName: null,
              modelText: null,
              categoryId: '',
              categoryName: null,
              categoryText: null,
              num: 1,
              unit: null,
              unitName: null,
              unitText: null,
              remark: null,
              marketPrice: null,
              marketTotalFee: null,
              costPrice: null,
              costTotalFee: null,
              definedType: false,
              technologyParameters: null,
              costPriceCanEdit: false,
              highlight: null,
              costPriceHighlight: false,
              marketPriceHighlight: false,
              newestMarketPrice: null,
              totalAmount: '0.00',
              totalCost: '0.00',
              fitRate: null,
              copy: true,
            },
          ],
          type2List: [
            {
              id: '1726559277776265216',
              typeId: '1695419324736630788',
              typeName: '视频显示系统',
              list: [
                {
                  id: '1726559277864345600',
                  classify: '1695419324803739648',
                  classifyName: 'LCD会议一体机',
                  classifyText: null,
                  brand: '1695426603963805696',
                  brandName: 'Newline',
                  brandText: null,
                  model: '1695426604278378496',
                  modelName: 'NE65GA2',
                  modelText: null,
                  categoryId: '1695426789775667200',
                  categoryName: '触摸一体机',
                  categoryText: null,
                  num: 1,
                  unit: null,
                  unitName: null,
                  unitText: null,
                  remark: null,
                  marketPrice: 0,
                  marketTotalFee: null,
                  costPrice: 7530,
                  costTotalFee: null,
                  definedType: false,
                  technologyParameters: null,
                  costPriceCanEdit: false,
                  highlight: null,
                  costPriceHighlight: false,
                  marketPriceHighlight: false,
                  newestMarketPrice: 0,
                  totalAmount: '0.00',
                  totalCost: '7530.00',
                  fitRate: null,
                },
              ],
              copy: false,
            },
            {
              id: '1726559277931454464',
              typeId: '1695419324740825090',
              typeName: '音频处理系统',
              list: [
                {
                  id: '1726559277939843072',
                  classify: '1695419324807933962',
                  classifyName: '数字调音台',
                  classifyText: null,
                  brand: '1695426628299157504',
                  brandName: 'SOUNDCRAFT',
                  brandText: null,
                  model: '',
                  modelName: null,
                  modelText: null,
                  categoryId: '',
                  categoryName: null,
                  categoryText: null,
                  num: 1,
                  unit: null,
                  unitName: null,
                  unitText: null,
                  remark: null,
                  marketPrice: null,
                  marketTotalFee: null,
                  costPrice: null,
                  costTotalFee: null,
                  definedType: false,
                  technologyParameters: null,
                  costPriceCanEdit: false,
                  highlight: null,
                  costPriceHighlight: false,
                  marketPriceHighlight: false,
                  newestMarketPrice: null,
                  totalAmount: '0.00',
                  totalCost: '0.00',
                  fitRate: null,
                },
              ],
            },
          ],
        })
      )
    }
    $message?.success('复制成功')
  }

  return [
    { isCopy, text },
    { openCopy, checkCopyContent, _onCopy },
  ] as const
}

import { request } from '@/utils'

export default {
  // 空查询
  getEmptyQuery: () => {
    return {
      isPurchase: null,
      demandSourceCodes: [],
      type: null,
      brandSeriesModelIds: [],
      keyWord: null,
      purchaseProgress: null,
      batchOperate: null,
    }
  },
  getEmptyPreOrderQuery: () => {
    return {
      settleCycle: null,
      partnerId: null,
      typeIds: [],
      keyWord: null,
      status: [],
      sortName: null,
      sortOrder: null,
    }
  },
  getEmptyPurchaseOrderQuery: () => {
    return {
      applyUser: [],
      brandSeriesModelIds: [],
      keyWord: null,
      projectId: null,
      purchaseProgress: [],
      type: [],
    }
  },
  getEmptyOrderChangeQuery: () => {
    return {
      brandSeriesModelIds: [],
      keyWord: null,
      changeType: null,
      purchaseState: [],
    }
  },
  // 空申请
  getEmptyPurchase: () => {
    return {
      applyCount: null,
      applyCountUnit: null,
      applyUser: null,
      categoryId: null,
      demandSourceCode: null,
      demanderUserCode: null,
      demanderUserName: null,
      expectedArrivalTime: null,
      orderRequirementSummary: null,
    }
  },

  /*
    采购申请
  */
  // 采购申请分页
  getPurchaseByPage: (params: PageParams) =>
    request.post(`/purchase/category/apply/pageList?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`, params.body || {}),
  // 采购申请详情
  getPurchaseById: (id: string) => request.get(`/purchase/category/apply/getDetailById/${id}`),
  // 采购申请基本信息
  verifyPurchaseApplyColor: (applyId: string, color: string) =>
    request.get(`/purchase/category/apply/selectorColor?applyId=${applyId}&color=${color}`),
  // 采购申请基本信息
  getPurchaseApplyInfo: (data: Recordable) => request.post('/purchase/category/apply/getApplyCategoryInfo', data),
  // 保存采购申请
  savePurchase: (data: Recordable) => request.post('/purchase/category/apply/save', data),
  // 修改采购申请
  updatePurchase: (data: Recordable) => request.post('/purchase/category/apply/edit', data),
  // 货品分类
  getAllCategory: () => request.get('/purchase/purchase/supply/service/type/categoryCascadeQuery'),
  // 货品分类
  getAllCategoryOptions: () => request.get('/purchase/purchase/supply/service/typeV2/typeCascadeQuery'),
  // 根据上级查询下级列表
  brandCascadeQueryByPid: (pid: string) => request.get(`/purchase/purchase/supply/service/type/brandCascadeQueryByPid?pId=${pid}`),
  // 分页查询所有物品配置
  getGoodPropByPage: (params: PageParams) =>
    request.post(`/purchase/purchase/properties/pageList?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`, params.body || {}),
  // 分页查询所有物品配置
  getCategoryBaseInfoByType: (type: string) => request.get(`/purchase/purchase/category/queryCategoryBaseInfo/${type}`),
  // 根据条件查询货品
  getGoodsByPage: (params: PageParams) =>
    request.post(
      `/purchase/purchase/category/queryCategory?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`,
      params.body || {}
    ),
  // 采购申请删除
  deleteApply: (id: string) => request.delete(`/purchase/category/apply/delete/${id}`),
  // 采购申请删除
  downloadApplyPageList: (data: Recordable) => request.post('/purchase/category/apply/downApplyPageList', data, { responseType: 'blob' }),
  // 品牌系列型号
  getBrandSeriesModelList: () => request.get('/purchase/purchase/supply/service/type/brandCascadeQuery'),

  // zeng add start
  // 获取同品类颜色列表
  // getColorsByCategoryId: (categoryId: string) => request.get(`/purchase/purchase/category/getCategory/${categoryId}`),
  getColorsByCategoryId: (categoryId: string) => request.get(`/purchase/purchase/categoryV2/getCategoryColors?categoryId=${categoryId}`),
  getColorsByCategoryIdParams: (params: any) => {
    return request.get(`/purchase/purchase/categoryV2/getCategoryColors`, { params }).then((res) => {
      return {
        ...res,
        data: (res.data || []).map((item: any) => {
          return {
            label: item.colorName,
            value: item.color,
            vid: item.color,
          }
        }),
      }
    })
  },
  // 根据型号id查询颜色列表
  getColorByModel: (modelId: string) => request.get(`/purchase/purchase/categoryV2/getColorsByModel?model=${modelId}`),
  // 根据型号id查询货品类别
  getCategoryInfoByModel: (modelId: string) => request.get(`/purchase/purchase/categoryV2/getCategoryInfoByModel?model=${modelId}`),
  // 查询所有申请人，过滤掉admin
  getSelectUser: (data: any) => request.post('/system/system/open/getSelectUser', data),
  // zeng add end

  // 更新颜色
  updateColor: (data: Recordable) => request.post('/purchase/category/apply/updateColor', data),
  // 更新备注
  updateRemark: (data: Recordable) => request.post('/purchase/category/apply/updateRemark', data),

  /*
    合作商
  */
  // 采购申请分页
  getPartnerByPage: (params: PageParams) =>
    request.post(`/purchase/category/apply/partnerPageList?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`, params.body || {}),
  // 采购合作商下载勾选项
  downPartnerPageList: (data: Recordable) => request.post('/purchase/category/apply/downPartnerPageList', data, { responseType: 'blob' }),
  // 采购合作商下载勾选项
  savePartnerDraftOrder: (data: Recordable) => request.post('/purchase/category/order/draftOrder', data),
  // 生成预订单
  pushPartnerPreOrder: (data: Recordable) => request.post('/purchase/category/order/pushPreOrder', data),

  /*
    预订单
  */
  // 采购申请分页
  getPreOrderByPage: (params: PageParams) =>
    request.post(
      `/purchase/category/order/preOrderPageList?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`,
      params.body || {}
    ),
  // 删除预订单
  deletePreOrder: (id: string) => request.delete(`/purchase/category/order/deletePreOrder/${id}`),
  // 更新预订单信息
  updatePreOrderInfo: (data: Recordable) => request.post('/purchase/category/order/updateOrderInfo', data),
  updateWarranty: (data: Recordable) => request.post('/purchase/category/order/updateWarranty', data),
  // 更新申请单预计到货时间
  editPossibleArriveTime: (data: Recordable) => request.post('/purchase/category/apply/editPossibleArriveTime', data),
  // 更新预订单预计到货时间
  editOrderPossibleArriveTime: (data: Recordable) => request.post('/purchase/category/order/editPossibleArriveTime', data),
  // 获取供货方合作商订单详情
  getPartnerConfirmList: (data: Recordable) => request.post('/purchase/category/order/confirmOrderPageList', data),
  // 确认下单
  generateOrder: (data: Recordable) => request.post('/purchase/category/order/generateOrder', data),
  // 供货方联系方式
  getPartnerContactList: (partnerId: string) => request.get(`/purchase/purchase/partner/queryAllContact/${partnerId}`),
  // 下载确认下单信息
  downloadOrder: (data: string[]) => request.post('/purchase/category/order/downOrderPageList', data, { responseType: 'blob' }),
  // 查询所有供应商
  getAllPartner: () => request.get('/purchase/purchase/partner/findAll'),
  // 查询订单货品详情
  queryOrderDetail: (id: string) => request.get(`/warehouse/receiveGoods/getArrangeDetailToPurchaseManager/${id}`),
  // 驳回
  rejectApply: (data: Recordable) => request.post('/purchase/category/apply/reject', data),
  // 取驳回原因
  getRejectReason: () => request.get('/system/diction/findByCode/purchase_reject_reason'),
  // 获取驳回信息
  getRejectDetail: (applyId: string) => request.get(`/purchase/category/project/getRejectDetail?id=${applyId}`),

  getSelfType2Id: () => request.post('/purchase/purchase/selfDev/product/getSelfDevCategoryType2'),
  getCategoryTagAndType3V2: (data: any) => request.post('/project/project/design/category/getCategoryTagAndType3V2', data),
  getCategoryBrand: (data: any) => request.post('/project/project/design/category/getCategoryBrand', data),
  getCategoryModel: (data: any) => request.post('/project/project/design/category/getCategoryModel', data),
  getCategoryName: (data: any) => request.post('/project/project/design/category/getCategoryName', data),

  saveSelfDev: (data: any) => request.post('/purchase/category/apply/saveSelfDev', data),
  editSelfDev: (data: any) => request.post('/purchase/category/apply/editSelfDev', data),
  getPartnerListByIds: (data: any) => request.post('/purchase/category/apply/getPartnerListByApplyIds', data),
  batchPushPreOrder: (data: any) => request.post('/purchase/category/apply/batchPushPreOrder', data),
  getSelfDetailByGroupId: (id: string) => request.get(`/purchase/category/apply/getSelfDevDetailByGroupId/${id}`),

  getCategoryColor: () => request.get('/system/diction/findByCode/purchase_category_color'),

  /**
   * 采购订单
   */
  getPurchaseOrderByPage: (params: PageParams) =>
    request.post(`/purchase/category/order/orderPageList?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`, params.body || {}),
  // 订单详情
  getPurchaseOrderInfoByCode: (orderCode: string) => request.get(`/purchase/category/order/orderItem?orderCode=${orderCode}`),
  // 申请人
  getApplyUsers: () => request.get('/purchase/category/order/getApplyUser'),
  // 采购项目
  getOrderProjects: () => request.get('/purchase/category/order/getOrderProject'),
  /**
   * 订单变更
   */
  getOrderChangeByPage: (params: PageParams) =>
    request.post(`/purchase/category/order/orderChange?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`, params.body || {}),
  getOrderChangeDetail: (orderId: string, type: string) =>
    request.get(`/purchase/category/order/orderChangeItem?orderId=${orderId}&type=${type}`),
  addInspection: (data: any) => request.post('/purchase/category/apply/addInspection', data),
  getInspection: (purchaseId: string) => request.get(`/purchase/category/apply/getInspection?id=${purchaseId}`),
  // 根据采购批次号查询订单
  getByOrderCode: (orderCode: string) => request.get(`/purchase/category/order/getByOrderCode?orderCode=${orderCode}`),

  // 更新采购申请
  changeApply: (data: any) => request.post(`/purchase/category/apply/changeApply`, data),
  // 获取原始数据提货信息
  getChangeOriginalApply: (applyId: string) => request.get(`/purchase/category/apply/getChangeOriginalApply?applyId=${applyId}`),
  // 获取当前数据提货信息
  getCurrentApply: (applyId: string) => request.get(`/purchase/category/apply/getCurrentApply?applyId=${applyId}`),
  // 申请变更历史查询
  changeApplyPageList: (params: PageParams) => request.post('/purchase/category/apply/changeApplyPageList', params.body),
}

import api from './api'
import type { ResponseResult } from '~/types/axios'

async function Middleware(req: (body: any) => Promise<ResponseResult<any>>, params: any, original = false) {
  const res = await req(params)

  if (original) {
    return res
  } else {
    return res.data
  }
}

export const service = {
  page: (params: PageParams) => Middleware(api.getPageListCategory, params || {}),
  add: (params?: any) => Middleware(api.addCategory, params || {}),
  delete: (params?: any) => Middleware(api.delForCategory, params.id || {}),
  info: (params?: any) => Middleware(api.getPartner, params.id || {}),
  update: (params?: any) => Middleware(api.editCategory, params || {}),
  list: (params?: any) => Middleware(api.brandCascade, params || {}),
  getDirectionByCode: (params?: any) => Middleware(api.getDirectionByCode, params || {}),
  getBrand: (params?: any) => Middleware(api.getBrand, params || {}),
  getProjectAll: (params?: any) => Middleware(api.getProjectAll, params || {}),
  getCategory: (params?: any) => Middleware(api.getCategory, params || {}),
  getChinaProvinceCityRegion: (params?: any) => Middleware(api.getChinaProvinceCityRegion, params || {}),
  partnerPageList: (params?: PageParams) => Middleware(api.partnerPageList, params || {}),
  pageListApprove: (params?: PageParams) => Middleware(api.pageListApprove, params || {}),
  pageListApprovePartnerDetail: (params?: PageParams) => Middleware(api.pageListApprovePartnerDetail, params || {}),
  getAllBrand: (params?: any) => Middleware(api.getAllBrand, params || {}),
  getCategoryCascade: () => Middleware(api.getCategoryCascade, {}),
  queryTypeInfoBySuccession: (params?: any) => Middleware(api.queryTypeInfoBySuccession, params || {}),
  getSelectUser: (params: any) => Middleware(api.getSelectUser, params),
  getSelectLabel: (bussCode: string) => Middleware(api.getSelectLabel, bussCode),
}

import { request } from '@/utils'

export default {
  getEmptyMenu: (type: boolean, pid: string | null = null) => {
    return {
      enabled: true,
      canShow: true,
      icon: '',
      id: null,
      name: '',
      pid,
      orderNo: null,
      type,
      permissionCode: '',
      backendPermissionCode: '',
      url: '',
      urlPage: '',
      redirect: '',
      title: '',
      keepAlive: false,
      isShowParent: false,
      vrMenu: false,
    }
  },
  getMenuTree: () => request.get('/system/menu/findTreeMenu'),
  saveMenu: (data: any) => request.post('/system/menu/addMenu', data),
  updateMenu: (data: any) => request.put('/system/menu', data),
  addOrUpdateBtn: (data: any) => request.post('/system/button/addOrUpdate', data),
  deleteMenu: (id: string) => request.delete(`/system/menu/delMenu/${id}`),
  deleteBtn: (id: string) => request.delete(`/system/button/${id}`),
}

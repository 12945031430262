export const purchasePhaseOptions = [
  {
    label: '未采购',
    value: '4',
  },
  {
    label: '采购中',
    value: '5',
  },
  {
    label: '部分采购',
    value: '6',
  },
  {
    label: '采购完成',
    value: '7',
  },
]

export const purchaseOrderStatusOptions = [
  {
    label: '待付款',
    value: '1',
  },
  {
    label: '采购中',
    value: '2',
  },
  {
    label: '已完成',
    value: '3',
  },
  {
    label: '已开票',
    value: '4',
  },
]

export const changeOptions: Recordable = {
  '1': '撤销订单',
  '2': '退换货',
}

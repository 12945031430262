import { request } from '@/utils'

export default {
  getSelectUser: (data: any) => request.post('/system/system/open/getSelectUser', data),
  getDirectionByCode: (code: string) => request.get(`/system/diction/findByCode/${code}`),
  getCategory: () => request.get('/purchase/purchase/supply/service/type/categoryCascadeQuery'),
  getChinaProvinceCityRegion: () => request.get('/system/system/open/getChinaProvinceCityRegion'),
  getBrand: () => request.get('/purchase/purchase/supply/service/type/brandCascadeQuery'),
  // 查询所有供应商
  getPurchase: (params: any) => request.get('/purchase/purchase/partner/findAll', { params }),
  // 查询供应商详情
  getPartnerDetail: (params: any) => request.get('/purchase/purchase/partnerV2/getDetail', { params }),

  getProjectReport: (data: any) => request.post('/data/project/report/info/page', data, { timeout: 300000 }),
  getProjectDownReport: (data: any) => request.post('/data/project/report/info/down', data, { responseType: 'blob', timeout: 300000 }),
  getProjectCollectInfo: (data: any) => request.post('/data/project/report/info/getCollectInfo', data, { timeout: 300000 }),
  getProjectTab: (params: any) => request.get('/data/data/open/getReportSalesDepts', { params }),
  getCustomerCompanyAll: () => request.get('/project/customer/org/queryOrg'),
  getProjectMoney: (data: any) => request.post('/data/project/report/money/page', data, { timeout: 300000 }),
  getProjectMoneyDetail: (params: any) => request.get('/data/project/report/money/getDetail', { params }),
  getProjectMoneyDown: (data: any) => request.post('/data/project/report/money/down', data, { responseType: 'blob', timeout: 300000 }),
  getProjectMoneyCollectInfo: (data: any) => request.post('/data/project/report/money/getCollectInfo', data, { timeout: 300000 }),
  getSaleMoneyCollectInfo: (data: any) => request.post('/data/sale/weekReport/getCollectInfo', data, { timeout: 300000 }),

  getPurchaseDown: (data: any) => request.post('/data/purchase/report/info/down', data, { responseType: 'blob', timeout: 300000 }),
  getPurchaseStatistics: (data: any) => request.post('/data/purchase/report/info/getCollectInfo', data, { timeout: 300000 }),
  getPurchasePage: (data: any) => request.post('/data/purchase/report/info/page', data, { timeout: 300000 }),

  getCategoryPurchaseDown: (data: any) => request.post('/data/category/report/info/down', data, { responseType: 'blob', timeout: 300000 }),
  getCategoryPurchaseStatistics: (data: any) => request.post('/data/category/report/info/getCollectInfo', data, { timeout: 300000 }),
  getCategoryPurchasePage: (data: any) => request.post('/data/category/report/info/page', data, { timeout: 300000 }),

  // 订单详情
  getPurchaseOrderInfoByCode: (orderCode: string) => request.get(`/purchase/category/order/orderItem?orderCode=${orderCode}`),
  // 采购订单创建人
  getOrderCreateUserList: (data: any) => request.post('/data/order/createUserList', data, { timeout: 300000 }),
  // 采购订单统计数据导出
  getOrderReportExport: (data: any) => request.post('/data/order/reportExport', data, { responseType: 'blob', timeout: 300000 }),
  // 采购订单统计查询
  getOrderReportList: (data: any) => request.post('/data/order/reportList', data, { timeout: 300000 }),
  // 订单合作商下拉列表
  getOrderReportPartner: (data: any) => request.post('/data/order/reportPartner', data, { timeout: 300000 }),
  // 采购订单统计数据
  getOrderReportStatistics: (data: any) => request.post('/data/order/reportStatistics', data, { timeout: 300000 }),

  // 机动任务详情
  getTaskByTaskId: (id: string) => request.get(`/ops/ops/mobiletask/getDetail/${id}`),
  // 驻场
  getZcTaskByTaskId: (id: string) => request.get(`/ops/ops/residentTask/getDetail/${id}`),

  getTaskCompanyName: () => request.get('/system/diction/findByCode/ops_mobile_task_company_name'),
  getCompanyNameList: () => request.get('/project/project/open/getCustomerCompany'),
  getCustomerList: (id: string) => request.get(`/ops/ops/taskCustomer/getCustomerDropList?unitId=${id}`),
  getTaskProjectExpired: () => request.get('/system/diction/findByCode/ops_mobile_task_project_expired'),
  getTaskProductExpired: () => request.get('/system/diction/findByCode/ops_mobile_task_product_expired'),
  getDeptList: () => request.post('/system/sys/department/list'),
  getOpsMobileUserTaskCount: () => request.get('/ops/ops/taskCustomer/getOpsMobileUserTaskCount'),
  getOpsResidentUserTaskCount: () => request.get('/ops/ops/taskCustomer/getOpsResidentUserTaskCount'),
  getProjectAll: () => request.get('/project/project/open/getProjectFormSelectList'),
  getUserBySelectCode: (selectCode: string) => request.get(`/ops/ops/taskCustomer/getUserBySelectCode?selectCode=${selectCode}`),

  // 运维报表(机动)
  getMobileTaskPage: (data: any) => request.post('/data/omTask/mobileTask/reportList', data, { timeout: 300000 }),
  getMobileTaskReportStatistics: (data: any) => request.post('/data/omTask/mobileTask/reportStatistics', data, { timeout: 300000 }),
  getMobileTaskReportExport: (data: any) =>
    request.post('/data/omTask/mobileTask/reportExport', data, { responseType: 'blob', timeout: 300000 }),
  // 运维报表(驻场)
  getResidentTaskPage: (data: any) => request.post('/data/omTask/residentTask/reportList', data, { timeout: 300000 }),
  getResidentTaskReportStatistics: (data: any) => request.post('/data/omTask/residentTask/reportStatistics', data, { timeout: 300000 }),
  getResidentTaskReportExport: (data: any) =>
    request.post('/data/omTask/residentTask/reportExport', data, { responseType: 'blob', timeout: 300000 }),
  // 查询运维服务下拉框人员(通用)
  getTaskUser: (data: any) => request.post('/data/omTask/taskUser', data, { timeout: 300000 }),

  // 销售周报
  getSaleWeekReportPage: (data: any) => request.post('/data/sale/weekReport/page', data, { timeout: 300000 }),
  getSaleWeekReportDown: (data: any) => request.post('/data/sale/weekReport/down', data, { responseType: 'blob', timeout: 300000 }),

  // 销售回款周报
  getSaleReturnMoneyweekReportPage: (data: any) => request.post('/data/sale/returnMoneyweekReport/page', data, { timeout: 300000 }),
  getSaleReturnMoneyweekReportStatistics: (data: any) =>
    request.post('/data/sale/returnMoneyweekReport/getCollectInfo', data, { timeout: 300000 }),
  getSaleReturnMoneyweekReportDown: (data: any) =>
    request.post('/data/sale/returnMoneyweekReport/down', data, { responseType: 'blob', timeout: 300000 }),
  // 获取项目情况说明
  getTagOpenToSelect: (id: string) => request.get(`project/sys/project/lifecycleTagGroup/getTagOpenToSelect?groupCode=${id}`),
  // 获取最近年份
  getLastestYear: () => request.get('/data/project/report/info/getLastestYear'),

  // 客户单位报表==========================

  // 签订日期下拉列表
  getSignYear: () => request.get('/data/project/customer/report/getSignYear'),
  // 获取用户详情
  getCustomerById: (id: string) => request.get(`/project/customer/profile/getDetail/${id}`),
  // 项目最新节点
  getProjLifecycl: () => request.post('/project/sys/project/projLifecycle/list'),
  // 客户单位合作统计
  getCustomerCompanyCollectInfo: (data: any) => request.post('/data/project/customer/report/statistics', data, { timeout: 300000 }),
  // 项目金额汇总信息
  getCustomerCompanyContractCollectInfo: (data: any) =>
    request.post('/data/project/customer/report/contract/getCollectInfo', data, { timeout: 300000 }),
  // 客户单位合作统计分页列表
  getCustomerCompanyReportPage: (data: any) => request.post('/data/project/customer/report/pageList', data, { timeout: 300000 }),
  // 项目分页列表
  getCustomerCompanyContractReportPage: (data: any) =>
    request.post('/data/project/customer/report/contract/page', data, { timeout: 300000 }),
  // 导出客户单位报表
  customerCompanyReportExport: (data: any) =>
    request.post('/data/project/customer/report/export', data, { responseType: 'blob', timeout: 300000 }),
  // 导出合作项目表
  customerCompanyContractReportExport: (data: any) =>
    request.post('/data/project/customer/report/contract/down', data, { responseType: 'blob', timeout: 300000 }),
  // 获取项目归属地
  getUserBelongToCode: () => request.get('/project/sys/project/infoApproval/getUserBelongToCode'),

  // 产品分析统计报表====================

  // 品牌下拉框
  getAllBrand: () => request.get('/purchase/purchase/supply/service/type/qeuryAllbrand'),
  // 品类详情
  getDetailCategory: (id: string) => request.get(`/purchase/purchase/categoryV2/getDetailCategory?id=${id}`),
}

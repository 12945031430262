import { request } from '@/utils'

export default {
  getEmptyRole: () => {
    return {
      name: null,
      enabled: true,
      code: null,
      menuIds: [],
      menus: [],
      summary: null,
    }
  },
  getAllRole: () => request.get('/system/role/listRole'),
  getRoleByPage: (params: PageParams) => {
    return request.post(`/system/role/pageList?pageSize=${params.pageSize}&pageNum=${params.pageNumber}`, params.body || {})
  },
  getRoleById: (id: string) => request.get(`/system/role/${id}`),
  saveRole: (data: any) => request.post('/system/role/add', data),
  updateRole: (data: any) => request.put('/system/role', data),
  deleteRole: (id: string) => request.delete(`/system/role/${id}`),
}

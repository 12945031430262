import { request } from '@/utils'

export default {
  getPurchaseList: (data: any) =>
    request.post(`project/sys/project/infoContract/getPurchaseList?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`, data),
  clearPurchaseCache: (projectId: string) => request.get(`/project/sys/project/infoContract/clearPurchaseCache/${projectId}`),
  savePurchaseCache: (data: any) => request.post('/project/sys/project/infoContract/savePurchaseCache', data),
  getPurchaseListCache: (data: any) =>
    request.post(`project/sys/project/infoContract/getPurchaseListCache?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`, data),
  getCategoryCascade: () => request.get('/purchase/purchase/supply/service/type/categoryCascadeQuery'),
  getBrandCascade: () => request.get('/purchase/purchase/supply/service/type/brandCascadeQuery'),
  getApproveRecodeList: (data: any) =>
    request.post(
      `project/sys/project/infoContract/getApproveRecodeList?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&pageQueryFlag=${data.pageQueryFlag}`,
      data
    ),
  getProjectLimitListBySale: (projectId: string, itemId: string) =>
    request.post(`/project/sys/project/infoContract/getProjectLimitListBySale?projectId=${projectId}&itemId=${itemId}`),
  getApproveRecodeListTotal: (data: any) => request.post('/project/sys/project/infoContract/getApproveRecodeListTotal', data),
  // 申请修改跟进销售
  applyModifyFlowSaleDate: (data: any, projectId: string) =>
    request.post(`/project/sys/project/infoApproval/applyModifyFlowSaleDate?projectId=${projectId}`, data),
  // 查询申请跟进销售记录
  queryApplyModifyFlowSaleRecord: (data: any) => request.post('/project/sys/project/infoApproval/queryApplyModifyFlowSaleRecord', data),
}

import '@/styles/reset.css'
import '@/styles/index.scss'
import 'uno.css'
import 'virtual:svg-icons-register'

import { createApp } from 'vue'
import App from './App.vue'
import { setupStore } from './store'
import { setupRouter } from './router'
import { setupGlobDirectives } from './directives'
import { setupCrud } from './crud'
import 'tdesign-vue-next/es/style/index.css'

async function setupApp() {
  const app = createApp(App)

  setupCrud(app)
  setupStore(app)
  // 注册全局指令
  setupGlobDirectives(app)
  await setupRouter(app)
  app.mount('#app')
}

setupApp()

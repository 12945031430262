import { request } from '@/utils'

export default {
  getProjectTab: (params: any) => request.get('/data/data/open/getReportSalesDepts', { params }),
  getSelectUser: (data: any) => request.post('/system/system/open/getSelectUser', data),

  // 工程实施报表
  getProjectImplementingReportStatistics: (data: any) => request.post('/data/project/implement/totalStatistics', data, { timeout: 300000 }),
  getProjectImplementingReportPage: (data: any) => request.post('/data/project/implement/queryImplementing', data, { timeout: 300000 }),
  getProjectAcceptReportPage: (data: any) => request.post('/data/project/implement/queryAccept', data, { timeout: 300000 }),
  getProjectCompletedReportPage: (data: any) => request.post('/data/project/implement/queryCompleted', data, { timeout: 300000 }),
  getProjectStopReportPage: (data: any) => request.post('/data/project/implement/queryStop', data, { timeout: 300000 }),

  exportProjectImplementingReportPage: (data: any) =>
    request.post('/data/project/implement/exportImplementing', data, { responseType: 'blob', timeout: 300000 }),
  exportProjectAcceptReportPage: (data: any) =>
    request.post('/data/project/implement/exportAccept', data, { responseType: 'blob', timeout: 300000 }),
  exportProjectCompletedReportPage: (data: any) =>
    request.post('/data/project/implement/exportCompleted', data, { responseType: 'blob', timeout: 300000 }),
  exportProjectStopReportPage: (data: any) =>
    request.post('/data/project/implement/exportStop', data, { responseType: 'blob', timeout: 300000 }),
}

import { request } from '@/utils'

export default {
  getCategoryTagAndType3V2: (data: any) => request.post('/project/project/design/category/getCategoryTagAndType3V2', data),
  getCategoryBrand: (data: any) => request.post('/project/project/design/category/getCategoryBrand', data),
  getCategoryModel: (data: any) => request.post('/project/project/design/category/getCategoryModel', data),
  getCategoryName: (data: any) => request.post('/project/project/design/category/getCategoryName', data),
  getCategoryMateV2: (data: any) => request.post('/project/project/design/category/getCategoryMate', data),
  getCategoryMateBatch: (data: any) => request.post('/project/project/design/category/getCategoryMateBatch', data),
  getCategoryPage: (data: any) => request.post('/project/project/design/category/getCategoryPage', data),
  getCategoryType2: (data: any) => request.post('/project/project/design/category/getCategoryType2', data),
  getCategoryImage: (params: any) => request.get('/project/project/design/category/getCategoryImage', { params }),
  getSelectType2: () => request.get('/project/project/design/category/getSelectCategoryType2'),
  getSelectType2Tree: () => request.get('/project/project/design/category/getSelectCategoryType2Tree'),
  getCategory3ByType2: (id: string, type3Id?: string) =>
    request.get(`/project/project/design/category/getCategoryTagAndType3?type2Id=${id}&selectType3Id=${type3Id}`),
  getBrandByType3: (id: string, modelId: string) =>
    request.get(`/project/project/design/category/getCategoryBrandAndModel?type3Id=${id}&selectModelId=${modelId}`),
  getMateByType3: (id: string) => request.get(`/project/project/design/category/getCategoryType3Mate?type3Id=${id}`),
  getCategoryByBrand: (id: string, categoryId: string) =>
    request.get(`/project/project/design/category/getCategory?modelId=${id}&selectCategoryId=${categoryId}`),
  onSave: (id: string, data: any) => request.post(`/project/project/design/require/add/${id}`, data),
  priceSave: (projectId: string, requireId: string, data: any) =>
    request.post(`/project/project/design/quote/save/${projectId}/${requireId}`, data),
  onUpdate: (projectId: string, requireId: string, data: any) =>
    request.post(`/project/project/design/require/update/${projectId}/${requireId}`, data),
  getAuth: (projectId: string) => request.get(`/project/project/design/require/getAuth/${projectId}`),
  updateVersion: (projectId: string) => request.get(`/project/project/design/require/updateRequireSchemeFileVersion/${projectId}`),
  getRequireList: (id: string) => request.get(`/project/project/design/require/list/${id}`),
  getDetailById: (projectId: string, requireId: string) => request.get(`/project/project/design/require/detail/${projectId}/${requireId}`),
  deleteById: (projectId: string, requireId: string) => request.get(`/project/project/design/require/delete/${projectId}/${requireId}`),
  getPreviewInfoById: (projectId: string, requireId: string) =>
    request.get(`/project/project/design/require/browse/${projectId}/${requireId}`),
  getPriceInfoById: (projectId: string, requireId: string, from: string) =>
    request.get(`/project/project/design/quote/detail/${projectId}/${requireId}?from=${from}`),
  updateFileVersion: (projectId: string) => request.get(`/project/project/design/quote/updateQuoteFileVersion/${projectId}`),
  updateTotalAmount: (projectId: string) => request.get(`/project/project/design/quote/getAutoQuoteDeviceTotalFee/${projectId}`),
  updateWorkHour: (projectId: string) => request.get(`/project/project/design/quote/getAutoQuoteDeviceTotalInstallWorkHour/${projectId}`),
  getDownloadConfig: (projectId: string) => request.get(`/project/project/design/quote/getConfig/${projectId}`),
  pricePreview: (projectId: string) => request.get(`/project/project/design/quote/getBrowseQuoteFile/${projectId}`),
  downFileNormal: (projectId: string, data: any) =>
    request.post(`/project/project/design/quote/downQuoteFileNormal/${projectId}`, data, { responseType: 'blob' }),
  getCollectFee: (projectId: string) => request.get(`/project/project/design/quote/getCollectFee/${projectId}`),
  getDownFileAuth: (projectId: string) => request.get(`/project/project/design/quote/getDownFileConfig/${projectId}`),
  getBrowseQuoteAll: (projectId: string) => request.get(`/project/project/design/quote/getBrowseQuoteAll/${projectId}`),
  saveDownFileAuth: (projectId: string, data: any) => request.post(`/project/project/design/quote/saveDownFileConfig/${projectId}`, data),
  saveCollectFee: (projectId: string, data: any) => request.post(`/project/project/design/quote/saveCollectFee/${projectId}`, data),
  getAttachmentsById: (projectId: string, requireId: string) =>
    request.get(`/project/project/design/require/getRequireFile/${projectId}/${requireId}`),
  saveAttachmentsById: (projectId: string, requireId: string, data: any) =>
    request.post(`/project/project/design/require/saveRequireFile/${projectId}/${requireId}`, data),
  downloadFile: (projectId: string, requireId: string, watermarkCompany: string) =>
    request.post(
      `/project/project/design/require/downRequireFile/${projectId}?requireId=${requireId}&watermarkCompany=${watermarkCompany}`,
      null,
      { responseType: 'blob' }
    ),
  downloadSchemeFile: (projectId: string) =>
    request.post(`/project/project/design/require/downRequireSchemeFile/${projectId}`, null, {
      responseType: 'blob',
      timeout: 10 * 60 * 1000,
    }),
  downloadPriceFile: (projectId: string, data: any) =>
    request.post(`/project/project/design/quote/downQuoteFile/${projectId}`, data, { responseType: 'blob' }),
  getUpdateLogs: (pageNumber: number, pageSize: number, data: any) =>
    request.post(`/project/project/design/log/list?pageNumber=${pageNumber}&pageSize=${pageSize}`, data),
  getRequireDetailById: (id: string) => request.get(`/project/project/design/log/getRequireDetail?id=${id}`),
  getQuoteDetailById: (id: string) => request.get(`/project/project/design/log/getQuoteDetail?id=${id}`),
  getCollectDetailById: (id: string) => request.get(`/project/project/design/log/getCollectFeeDetail?id=${id}`),
  getSectionTree: (logId: string) => request.get(`/project/project/design/require/customWord/section/log/${logId}`),
  getSectionContent: (logId: string, catelogId: string) =>
    request.get(`/project/project/design/require/customWord/content/log/${logId}/${catelogId}`),
  getCategoryInfo: (categoryId: string) => request.get(`/project/project/design/category/getCategoryInfo?categoryId=${categoryId}`),
  getDirectionByCode: (code: string) => request.get(`/system/diction/findByCode/${code}`),
  // 查询配套推荐
  getCategoryMate: (id: string, type: string) => request.get(`/project/design/category/getCategoryMate?id=${id}&type=${type}`),
}

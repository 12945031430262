import { request } from '@/utils'

export default {
  getReceiveGoodsList: (params: PageParams) =>
    request.post(`/warehouse/receiveGoods/list?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}`, params.body || {}),
  queryReceiveDetail: (id: string) => request.get(`/warehouse/receiveGoods/get/v2/${id}`),
  getGoodIds: (id: string) => request.get(`/warehouse/receiveGoods/getReceiveUserIds/${id}`),
  getGoodDetail: (id: string) => request.get(`/warehouse/receiveGoods/getUseGoodsInfo/${id}`),
  getTransferGoods: (id: string) => request.get(`/warehouse/receiveGoods/getTransferGoods/${id}`),
  saveGoodInfo: (data: any) => request.post('/warehouse/receiveGoods/saveGoodsInfo', data),
  saveTransferGood: (data: any) => request.post('/warehouse/receiveGoods/saveTransferGoods', data),
  getStoreHouses: () => request.get('warehouse/storehouse/getFormList'),
  confirmReceive: (data: any) => request.post('/warehouse/receiveGoods/saveArrangeGoods', data),
  getDirectionByCode: (code: string) => request.get(`/system/diction/findByCode/${code}`),
  getBrandCascade: () => request.get('/purchase/purchase/supply/service/type/brandCascadeQuery'),
  download: (params: string) => request.get(`/project/main/down?params=${params}`, { responseType: 'blob' }),
}

import api from './api'
import type { ResponseResult } from '~/types/axios'

async function Middleware(req: (body: any) => Promise<ResponseResult<any>>, params: any, original = false) {
  const res = await req(params)
  if (original) {
    return res
  } else {
    return res.data
  }
}

export const service = {
  getProjectImplementingReportStatistics: (params: any) => Middleware(api.getProjectImplementingReportStatistics, params),
  getProjectImplementingReportPage: (params: any) => Middleware(api.getProjectImplementingReportPage, params),
  getProjectAcceptReportPage: (params: any) => Middleware(api.getProjectAcceptReportPage, params),
  getProjectCompletedReportPage: (params: any) => Middleware(api.getProjectCompletedReportPage, params),
  getProjectStopReportPage: (params: any) => Middleware(api.getProjectStopReportPage, params),

  exportProjectImplementingReportPage: (params: any) => Middleware(api.exportProjectImplementingReportPage, params, true),
  exportProjectAcceptReportPage: (params: any) => Middleware(api.exportProjectAcceptReportPage, params, true),
  exportProjectCompletedReportPage: (params: any) => Middleware(api.exportProjectCompletedReportPage, params, true),
  exportProjectStopReportPage: (params: any) => Middleware(api.exportProjectStopReportPage, params, true),

  getSelectUser: (params: any) => Middleware(api.getSelectUser, params),
  getProjectTab: (params: any) => Middleware(api.getProjectTab, params),
}

import type { RouteType } from '~/types/router'
const Layout = () => import('@/layout/index.vue')

export default {
  name: 'product2',
  path: '/product',
  component: Layout,
  isHidden: true,
  meta: {
    order: 0,
  },
  children: [
    {
      name: 'goodsDetail2',
      path: 'goodsDetail2',
      isHidden: true,
      component: () => import('/src/views/product/partners/goods-detail/index.vue'),
      meta: {
        title: '合作商货品详情',
      },
    },
  ],
} as RouteType

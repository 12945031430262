import api from './api'
import type { ResponseResult } from '~/types/axios'

async function Middleware(req: (body: any) => Promise<ResponseResult<any>>, params: any, original = false) {
  const res = await req(params)
  if (original) {
    return res
  } else {
    return res.data
  }
}

export const service = {
  getSubDepartment: (params: any) => Middleware(api.getSubDepartment, params),
  getUserOfDepartment: (params: any) => Middleware(api.getUserOfDepartment, params),
  taskPlanGetDetail: (params: any) => Middleware(api.taskPlanGetDetail, params),
  taskPlanAddIndicators: (params: any) => Middleware(api.taskPlanAddIndicators, params),
  taskPlanEditIndicators: (params: any) => Middleware(api.taskPlanEditIndicators, params),
  taskPlanDeleteIndicators: (params: any) => Middleware(api.taskPlanDeleteIndicators, params),
  taskPlanPageDepartment: (params: any) => Middleware(api.taskPlanPageDepartment, params),
  taskPlanPageUser: (params: any) => Middleware(api.taskPlanPageUser, params),
}

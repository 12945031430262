import { request } from '@/utils'

export default {
  getEmptyPermissionRule: (outputcols: Array<string> = [], updatecol: Array<string> = []) => {
    return {
      targetColumn: 'create_by',
      ownType: 'all',
      owners: [],
      effectConditions: [],
      targetUserType: 'sameDepartment',
      targetUserCustomType: 'user',
      targeters: [],
      ruleList: [
        {
          ruleType: 'input',
          ruleProps: [
            {
              propName: 'input',
              isVisitable: 1,
            },
          ],
        },
        {
          ruleType: 'outputcol',
          ruleCols: outputcols,
          ruleProps: [],
        },
        {
          ruleType: 'update',
          ruleProps: [
            {
              propName: 'update',
              isVisitable: 1,
            },
          ],
        },
        {
          ruleType: 'updatecol',
          ruleCols: updatecol,
          ruleProps: [],
        },
        {
          ruleType: 'delete',
          ruleProps: [
            {
              propName: 'delete',
              isVisitable: 1,
            },
          ],
        },
      ],
    }
  },
  getResourceList: () => request.get('/system/datadomain/findAllDatadomainGroup'),
  getRulesByResourceId: (groupId: string) => request.get(`/system/datadomain/queryDatadomainRuleByGroupId?groupId=${groupId}`),
  getRuleOptionByResourceId: (groupId: string) => request.get(`/system/datadomain/findAllDatadomainGroupFields?groupId=${groupId}`),
  addRule: (data: Recordable) => request.post('/system/datadomain/newDatadomainRule', data),
  updateRule: (data: Recordable) => request.post('/system/datadomain/editRule', data),
  deleteRule: (ruleId: string) => request.delete(`/system/datadomain/delRule?ruleId=${ruleId}`),

  // 新
  getEmptyPermissionTree: (data: any) => {
    console.log('getEmptyPermissionTree', data)
    return {
      name: '',
      pname: data?.name,
      orderNo: 0,
      pid: data?.id,
      moduleCode: null,
      isDir: null,
      id: null,
    }
  },

  findAllDatadomainTreeGroup: () => request.get('/system/datadomain/findAllDatadomainTreeGroup'),
  editDataDomainName: (data: Recordable) => request.post('/system/datadomain/editDataDomainName', data),
  saveNodeDomain: (data: Recordable) => request.post('/system/datadomain/saveNodeDomain', data),
}

import { request } from '@/utils'

export default {
  // 货品分类
  getAllCategoryOptions: () => request.get('/purchase/purchase/supply/service/typeV2/typeCascadeQuery'),
  // 品牌系列型号
  getBrandSeriesModelOptions: () => request.get('/purchase/purchase/categoryV2/brandCascade'),

  // 审核申请单
  auditApply: (data: Recordable) => request.post('/purchase/category/apply/audit', data),
  // 根据申请单id查询供应商
  getPartnerListByIds: (data: any) => request.post('/purchase/category/apply/getPartnerListByApplyIds', data),
  // 批量提交预订单
  batchPushPreOrder: (data: any) => request.post('/purchase/category/apply/batchPushPreOrder', data),
  // 根据品类获取颜色
  getColorsByCategoryId: (categoryId: string) => request.get(`/purchase/purchase/categoryV2/getCategoryColors?categoryId=${categoryId}`),
  // 更新颜色
  updateColor: (data: Recordable) => request.post('/purchase/category/apply/updateColor', data),
  // 更新备注
  updateRemark: (data: Recordable) => request.post('/purchase/category/apply/updateRemark', data),
  // 采购申请基本信息
  getPurchaseApplyInfo: (data: Recordable) => request.post('/purchase/category/apply/getApplyCategoryInfo', data),

  /**
   * *********************  申请单  --  全部  *********************
   */
  getAllEmptyQuery: () => {
    return {
      applyUser: [],
      auditStatus: null,
      auditUser: [],
      brandSeriesModelIds: [],
      type: [],
      demandSourceCodes: [],
      invoiceStatus: null,
      payStatus: null,
      orderStatus: null,
      purchaseStatus: null,
      commitStartDate: null,
      commitEndDate: null,
      refuseReason: [],
      settleCase: null,
      settleCycle: null,
      shippingStatus: null,
      keyword: null,
      pp: null,
      xl: null,
      xh: null,
      sortName: null,
      sortOrder: null,
    }
  },
  getAllApplyByPage: (params: PageParams) => request.post(`/purchase/category/apply/applyAllPage`, params.body || {}),
  downloadAllApplyByPage: (data: Recordable) => request.post('/purchase/category/apply/exportApplyAll', data, { responseType: 'blob' }),

  /**
   * *********************  申请单  --  待审核  *********************
   */
  getToReviewedEmptyQuery: () => {
    return {
      applyUser: [],
      brandSeriesModelIds: [],
      type: [],
      demandSourceCodes: [],
      commitStartDate: null,
      commitEndDate: null,
      keyword: null,
      pp: null,
      xl: null,
      xh: null,
      sortName: null,
      sortOrder: null,
    }
  },
  getToReviewedApplyByPage: (params: PageParams) => request.post(`/purchase/category/apply/waitAuditPage`, params.body || {}),
  downloadToReviewedApplyByPage: (data: Recordable) =>
    request.post('/purchase/category/apply/exportWaitAudit', data, { responseType: 'blob' }),

  /**
   * *********************  申请单  --  待采购  *********************
   */
  getToPurchasedEmptyQuery: () => {
    return {
      genOrderStatus: null,
      applyUser: [],
      brandSeriesModelIds: [],
      type: [],
      demandSourceCodes: [],
      commitStartDate: null,
      commitEndDate: null,
      purchaseStatus: null,
      keyword: null,
      pp: null,
      xl: null,
      xh: null,
      sortName: null,
      sortOrder: null,
    }
  },
  getToPurchasedApplyByPage: (params: PageParams) => request.post(`/purchase/category/apply/waitPurchasePage`, params.body || {}),
  downloadToPurchasedApplyByPage: (data: Recordable) =>
    request.post('/purchase/category/apply/exportWaitPurchase', data, { responseType: 'blob' }),

  /**
   * *********************  申请单  --  已驳回  *********************
   */
  getRejectedEmptyQuery: () => {
    return {
      applyUser: [],
      brandSeriesModelIds: [],
      type: [],
      demandSourceCodes: [],
      commitStartDate: null,
      commitEndDate: null,
      refuseReason: [],
      keyword: null,
      pp: null,
      xl: null,
      xh: null,
      sortName: null,
      sortOrder: null,
    }
  },
  getRejectedApplyByPage: (params: PageParams) => request.post(`/purchase/category/apply/applyRefusePage`, params.body || {}),
  downloadRejectedApplyByPage: (data: Recordable) =>
    request.post('/purchase/category/apply/exportApplyRefuse', data, { responseType: 'blob' }),
}

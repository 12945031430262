import type { SettledFileInfo } from 'naive-ui/es/upload/src/interface'
import type { AxiosProgressEvent } from 'axios/index'
import { NProgress } from 'naive-ui'
import { request } from '@/utils'
interface Options {
  module?: string
  code?: string
}
export function useUpload(opts?: Options) {
  function imageUpload(blobInfo: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('file', blobInfo)
      request
        .post(`/system/sys/system/attachment/save?module=${opts?.module}&moduleEntityCode=${opts?.code}`, formData, {
          timeout: 1800000,
        })
        .then((res) => {
          resolve(res.data.url)
        })
        .catch((e) => {
          console.error(e)
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({ message: '网络错误', remove: true })
        })
    })
  }

  function uploadWithProgress({
    file,
    uploadUrl,
    handleRes,
    handleErr,
    handleFinal,
  }: {
    file: SettledFileInfo
    uploadUrl?: string
    handleRes?: (res?: any) => void
    handleErr?: (res: any) => void
    handleFinal?: () => void
  }) {
    if (!file) {
      window.$message?.error('请选择上传文件')
      return
    }
    const reqUrl = uploadUrl ?? `/system/sys/system/attachment/save?module=${opts?.module}&moduleEntityCode=${opts?.code}`
    const formData = new FormData()
    formData.append('file', file.file as File)
    const el = window.$notification?.create({
      type: 'info',
      title: file.name,
      description: '开始上传',
    })
    request
      .post(reqUrl, formData, {
        timeout: 3600000,
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          if (progressEvent.total && el) {
            const progressPercent = Math.trunc(Number((progressEvent.loaded / progressEvent.total) * 100)) - 1
            el.description = () =>
              h(NProgress, {
                type: 'line',
                percentage: progressPercent,
                processing: true,
              })
          }
        },
      })
      .then((res: any) => {
        handleRes && handleRes(res)
        if (el) {
          el.type = 'success'
          el.description = '上传成功'
        }
      })
      .catch((error) => {
        console.log(error)
        handleErr && handleErr(error)
        if (el) {
          el.type = 'error'
          el.description = '上传失败'
        }
      })
      .finally(() => {
        handleFinal && handleFinal()
        if (el && el.type !== 'error') {
          setTimeout(() => {
            el.destroy()
          }, 1500)
        }
      })
  }
  return {
    imageUpload,
    uploadWithProgress,
  }
}

import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import type { SelectOption } from 'naive-ui'
import { NTooltip } from 'naive-ui'
import { number } from 'echarts'
import { useAppStore } from '@/store'

type Time = undefined | string | Date

/** 获取服务器时间-毫秒值 */
export function getSystemTime(): number {
  return new Date().getTime() + useAppStore().serverTimeDiff
}

/** 获取服务器时间-dayjs */
export function getNow(): Dayjs {
  return dayjs(getSystemTime())
}

/** 格式化时间，默认格式：YYYY-MM-DD HH:mm:ss */
export function formatDateTime(time: Time, format = 'YYYY-MM-DD HH:mm:ss'): string {
  return dayjs(time).format(format)
}

/** 格式化日期，默认格式：YYYY-MM-DD */
export function formatDate(date: Time = undefined, format = 'YYYY-MM-DD') {
  if (!date) {
    return ''
  }
  return formatDateTime(date, format)
}

/** 使用枚举键迭代对象 */
export function objectKeys<Obj extends object>(obj: Obj): (keyof Obj)[] {
  return Object.keys(obj) as (keyof Obj)[]
}

/** 在树形结构数据中获取对应label全路径 */
export function getPathLabels(data: Array<any>, curKey: string) {
  if (!Array.isArray(data)) {
    return
  }
  let result: Array<any> = [] // 记录路径结果
  const traverse = (curKey: string, path: Array<any>, data: Array<any>) => {
    if (data.length === 0) {
      return
    }

    for (const item of data) {
      path.push(item)
      if (item.value === curKey) {
        result = JSON.parse(JSON.stringify(path))
        return
      }
      const children = Array.isArray(item.children) ? item.children : []
      traverse(curKey, path, children) // 遍历
      path.pop() // 回溯
    }
  }
  traverse(curKey, [], data)
  const labels = result.map((item) => item.label).join(' / ')
  return labels
}

/** cascader下拉选项增加提示功能 */
export function renderLabel(option: { value?: string | number; label?: string }) {
  return h(
    NTooltip,
    {},
    {
      default: () => option.label,
      trigger: () =>
        h(
          'div',
          {
            style: {
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            },
          },
          [option.label]
        ),
    }
  )
}

/** select下拉选项增加提示功能 */
export function renderOption({ node, option }: { node: VNode; option: SelectOption }) {
  window.console.log(option)

  return h(NTooltip, null, {
    trigger: () => node,
    default: () => option.name || option.value,
  })
}

/** 金额转中文大写 */
export function amountInWords(money: any) {
  const cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖']
  const cnIntRadice = ['', '拾', '佰', '仟']
  const cnIntUnits = ['', '万', '亿', '兆']
  const cnDecUnits = ['角', '分', '毫', '厘']
  const cnInteger = '整'
  const cnIntLast = '元'
  const maxNum = 999999999.99
  let integerNum
  let decimalNum
  let chineseStr = ''
  let n = ''
  let parts
  if (money >= maxNum || money === null) {
    return ''
  }

  if (money === 0) {
    chineseStr = cnNums[0] + cnIntLast + cnInteger
    return chineseStr
  }
  money = money.toString()
  if (!money.includes('.')) {
    integerNum = money
    decimalNum = ''
  } else {
    parts = money.split('.')
    integerNum = parts[0]
    decimalNum = parts[1].substr(0, 4)
  }
  if (parseInt(integerNum, 10) > 0) {
    let zeroCount = 0
    const IntLen = integerNum.length
    for (let i = 0; i < IntLen; i++) {
      n = integerNum.substr(i, 1)
      const p = IntLen - i - 1
      const q = p / 4
      const m = p % 4
      if (n === '0') {
        zeroCount++
      } else {
        if (zeroCount > 0) {
          chineseStr += cnNums[0]
        }

        zeroCount = 0
        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m]
      }
      if (m === 0 && zeroCount < 4) {
        chineseStr += cnIntUnits[q]
      }
    }
    chineseStr += cnIntLast
  }
  if (decimalNum !== '') {
    const decLen = decimalNum.length
    for (let i = 0; i < decLen; i++) {
      n = decimalNum.substr(i, 1)
      if (n !== '0') {
        chineseStr += cnNums[Number(n)] + cnDecUnits[i]
      }
    }
  }
  if (chineseStr === '') {
    chineseStr += cnNums[0] + cnIntLast + cnInteger
  } else if (decimalNum === '') {
    chineseStr += cnInteger
  }

  return chineseStr
}

/** 金额加千分位 */
export function parseCurrency(input: string) {
  // window.console.log('input', input)
  const nums = input.replace(/(,|¥|\s)/g, '').trim()
  if (/^\d+(\.(\d+)?)?$/.test(nums)) {
    return Number(nums)
  }

  return nums === '' ? null : Number.NaN
}

/** 金额转换成万 */
export function formatCurrencyToTenThousand(value: number | null | string) {
  if (value === null || value === undefined) {
    return ''
  }
  if (Number(value) < 9999.50) {
    return `¥` + String(value)
  }
  if (!String((Number(value) / 10000)).includes('.')) {
    return `¥` + (Number(value) / 10000) + '.00' + '万'
  } else {
    return `¥` + (Number(value) / 10000).toFixed(2) + '万'
  }
}

/** 金额加羊角符 */
export function formatCurrency(value: number | null) {
  // window.console.log('value', value)
  if (value === null || value === undefined) {
    return ''
  }

  let nums = `¥ ${value.toLocaleString('en-US')}`

  if (!nums.includes('.')) {
    nums = `${nums}.00`
  }
  // window.console.log('num', nums)
  else if (nums.indexOf('.') && nums.indexOf('.') === nums.length - 2) {
    nums = `${nums}0`
  }
  return nums
}
// 判断数据类型
function getDataType(data: any) {
  const temp = Object.prototype.toString.call(data)
  const type = temp.match(/\b\w+\b/g) ?? ''
  return type.length < 2 ? 'Undefined' : type[1]
}
/** 判断两个对象是否相同 */
export function isObjectChanged(source: any, comparison: any): boolean {
  // 由于'Object','Array'都属于可遍历的数据类型，所以我们提前定义好判断方法，方便调用
  const iterable = (data: any) => ['Object', 'Array'].includes(getDataType(data))

  // 如果源数据不是可遍历数据，直接抛错，主要用于判断首次传入的值是否符合判断判断标准。
  if (!iterable(source)) {
    throw new Error(`source should be a Object or Array , but got ${getDataType(source)}`)
  }

  // 如果数据类型不一致，说明数据已经发生变化，可以直接return结果
  if (getDataType(source) !== getDataType(comparison)) {
    return true
  }

  // 提取源数据的所有属性名
  const sourceKeys = Object.keys(source)

  // 将对比数据合并到源数据，并提取所有属性名。
  // 在这里进行对象合并，首先是要保证 对比数据>=源数据，好处一：后边遍历的遍历过程就不用做缺省判断了。
  const comparisonKeys = Object.keys({ ...source, ...comparison })

  // 好处二：如果属性数量不一致说明数据必然发生了变化，可以直接return结果
  if (sourceKeys.length !== comparisonKeys.length) {
    return true
  }
  // return comparisonKeys
  // 这里遍历使用some，some的特性一旦找到符合条件的值，则会立即return，不会进行无意义的遍历。完美符合我们当前的需求
  return comparisonKeys.some((key) => {
    // 如果源数据属于可遍历数据类型，则递归调用
    if (iterable(source[key])) {
      return isObjectChanged(source[key], comparison[key])
    } else {
      return source[key] !== comparison[key]
    }
  })
}
// 级联下拉框搜索不区分大小写
// option得到的是最末节点，故要向上寻找匹配的父级label，目前系统最多三级，此方法兼容三级以下的情况
export function filterSelect(filterWords: any, option: any, path: any) {
  // path为naive独有参数，需在path中寻找父级
  // window.console.log('filterWords', filterWords, option, path)
  if (path) {
    let isMatch = false
    for (const item of path) {
      isMatch = false
      if (!!~item?.label?.indexOf(filterWords) || !!~item?.label?.toUpperCase().indexOf(filterWords.toUpperCase())) {
        isMatch = true
        break
      }
    }
    return isMatch
  }
  // TDesign
  else {
    if (
      (option && (!!~option?.label.indexOf(filterWords) || !!~option?.label.toUpperCase().indexOf(filterWords.toUpperCase()))) ||
      (option?.parent &&
        (!!~option?.parent?.label.indexOf(filterWords) || !!~option?.parent?.label.toUpperCase().indexOf(filterWords.toUpperCase()))) ||
      (option?.parent?.parent &&
        (!!~option?.parent?.parent?.label.indexOf(filterWords) ||
          !!~option?.parent?.parent?.label.toUpperCase().indexOf(filterWords.toUpperCase())))
    ) {
      return true
    } else {
      return false
    }
  }
}

/**
 * 从props配置中获取默认值
 * @param props
 * @param overrideProps
 * @returns
 */

export function getDefaultFromProps<T = Record<string, any>>(props: Record<string, any>, overrideProps: T): T | Record<string, any> {
  const defaults = Object.entries(props).reduce((temp, [key, value]) => {
    // @ts-expect-error 禁用单行ts校验
    temp[key] = value?.default
    return temp
  }, {})

  return {
    ...defaults,
    ...overrideProps,
  }
}

/**
 * 友好的展示金额
 * @param amount 金额
 */
export function moneyFormat(num, decimal = 2, split = ',') {
  /*
    parameter：
    num：格式化目标数字
    decimal：保留几位小数，默认2位
    split：千分位分隔符，默认为,
    moneyFormat(123456789.87654321, 2, ',') // 123,456,789.88
  */
  function thousandFormat(num) {
    const len = num.length
    return len <= 3 ? num : thousandFormat(num.slice(0, len - 3)) + split + num.slice(len - 3, len)
  }
  if (isFinite(num)) {
    // num是数字
    if (num === 0) {
      // 为0
      return num.toFixed(decimal)
    } else {
      // 非0
      let res = ''
      const dotIndex = String(num).indexOf('.')
      if (dotIndex === -1) {
        // 整数
        if (decimal === 0) {
          res = thousandFormat(String(num))
        } else {
          res = `${thousandFormat(String(num))}.${'0'.repeat(decimal)}`
        }
      } else {
        // 非整数
        // js四舍五入 Math.round()：正数时4舍5入，负数时5舍6入
        // Math.round(1.5) = 2
        // Math.round(-1.5) = -1
        // Math.round(-1.6) = -2
        // 保留decimals位小数
        const numStr = String((Math.round(num * 10 ** decimal) / 10 ** decimal).toFixed(decimal)) // 四舍五入，然后固定保留2位小数
        const decimals = numStr.slice(dotIndex, dotIndex + decimal + 1) // 截取小数位
        res = thousandFormat(numStr.slice(0, dotIndex)) + decimals
      }
      return `${res}元`
    }
  } else {
    return '--'
  }
}

export function convertToCnNum(num: number): string {
  const arr1 = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
  const arr2 = ['', '十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千', '万', '十', '百', '千', '亿'] // 可继续追加更高位转换值
  if (!num || isNaN(num)) {
    return '零'
  }
  const english = num.toString().split('')
  let result = ''
  for (let i = 0; i < english.length; i++) {
    const des_i = english.length - 1 - i // 倒序排列设值
    result = arr2[i] + result
    const arr1_index = english[des_i]
    result = arr1[arr1_index as any] + result
  }
  // 将【零千、零百】换成【零】 【十零】换成【十】
  result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十')
  // 合并中间多个零为一个零
  result = result.replace(/零+/g, '零')
  // 将【零亿】换成【亿】【零万】换成【万】
  result = result.replace(/零亿/g, '亿').replace(/零万/g, '万')
  // 将【亿万】换成【亿】
  result = result.replace(/亿万/g, '亿')
  // 移除末尾的零
  result = result.replace(/零+$/, '')
  // 将【零一十】换成【零十】
  // result = result.replace(/零一十/g, '零十');//貌似正规读法是零一十
  // 将【一十】换成【十】
  result = result.replace(/^一十/g, '十')
  return result
}

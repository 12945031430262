import { defineStore } from 'pinia'

export const usePurchaseStore = defineStore('purchase', {
  state() {
    return {
      currentPurchaseTab: <string | null>null,
    }
  },
  actions: {
    setCurrentPurchaseTab(tab: any) {
      this.currentPurchaseTab = tab
    },
  },
})

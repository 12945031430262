import { request } from '@/utils'

export default {
  // 查询单个需求报价清单详情
  getContractInfoById: (projectId: string, requireId: string, dataId: string) =>
    request.get(`/project/project/contract/quote/v2/detail/${projectId}/${requireId}?dataId=${dataId}`),
  // 查询权限
  getAuth: (projectId: string) => request.get(`/project/project/contract/quote/v2/getAuth/${projectId}`),
  // 查询报价清单设置信息
  getDownloadConfig: (projectId: string) => request.get(`/project/project/contract/quote/v2/getConfig/${projectId}`),
  // 查询综合费用修改详情
  getCollectFee: (projectId: string, dataId: string) =>
    request.get(`/project/project/contract/quote/v2/getCollectFee/${projectId}?dataId=${dataId}`),
  // 下载报价清单文件
  downloadPriceFile: (projectId: string, data: any) =>
    request.post(`/project/project/contract/quote/v2/downQuoteFile/${projectId}`, data, { responseType: 'blob' }),
  // 查询二级分类或列表是否可以编辑
  getCanEdit: (projectId: string, siteId: string, id: string, type: string, dataId: string, editType = 'delete') =>
    request.get(
      `/project/project/contract/quote/v2/getCanEdit/${projectId}/${siteId}?id=${id}&type=${type}&dataId=${dataId}&editType=${editType}`
    ),
  // 保存单个需求对应信息
  priceSave: (projectId: string, siteId: string, data: any) =>
    request.post(`/project/project/contract/quote/v2/save/${projectId}/${siteId}`, data),
  // 保存综合费用
  saveCollectFee: (projectId: string, data: any) => request.post(`/project/project/contract/quote/v2/saveCollectFee/${projectId}`, data),
  // 合同审批表单数据提交保存
  saveSubmitForm: (
    projectId: string,
    data: {
      dataId: string
      fileList: {
        id: string
        name: string
        refId: string
        status: string
        type: string
        url: string
      }[]
      remark: string
    }
  ) => request.post(`/project/project/contract/quote/v2/saveSubmitForm?projectId=${projectId}`, data),
  updateTotalAmount: (projectId: string) => request.get(`/project/project/contract/quote/v2/getAutoQuoteDeviceTotalFee/${projectId}`),
  // 自动计算设备安装总工时
  updateWorkHour: (projectId: string, dataId: string) =>
    request.get(`/project/project/contract/quote/v2/getAutoQuoteDeviceTotalInstallWorkHour/${projectId}?dataId=${dataId}`),
  getUpdateLogs: (pageNumber: number, pageSize: number, data: any) =>
    request.post(`/project/project/contract/quote/v2/log/list?pageNumber=${pageNumber}&pageSize=${pageSize}`, data),
  getQuoteDetailById: (id: string) => request.get(`/project/project/contract/quote/v2/log/getQuoteDetail?id=${id}`),
  getCollectDetailById: (id: string) => request.get(`/project/project/contract/quote/v2/log/getCollectFeeDetail?id=${id}`),
  // 检查可编辑数量是否合规
  checkCount: (projectId: string, requireId: string, params: any) =>
    request.get(`/project/project/contract/quote/v2/checkCount/${projectId}/${requireId}`, { params }),
  getRequireList: (projectId: string, dataId: string) =>
    request.get(`/project/project/contract/quote/v2/getSchemeSite?projectId=${projectId}&dataId=${dataId}`),
  // 查询报价清单预览信息
  getBrowseQuoteAll: (projectId: string) => request.get(`/project/project/contract/quote/v2/getBrowseQuoteAll/${projectId}`),
  // 查询项目信息
  getProjectInfo: (projectId: string) => request.get(`/project/project/contract/quote/v2/getProjectInfo?projectId=${projectId}`),
  // 查询提交审核记录
  getSumbitRecord: (data: { projectId: string; pageNumber: number; pageSize: number }) =>
    request.post(
      `/project/project/contract/quote/v2/getSumbitRecord?projectId=${data.projectId}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`,
      data
    ),
  // 查询提交审核详情
  getSubmitDetail: (params: { projectId: string; verId: string }) =>
    request.get(`/project/project/contract/quote/v2/getSubmitDetail`, { params }),
  // 查询送审的报价清单
  getSubmitQuoteList: (params: { projectId: string; dataId: string }) =>
    request.get(`/project/project/contract/quote/v2/getSubmitQuoteList`, { params }),
  // 获取提交审核id
  getSubmitId: (projectId: string) => request.post(`/project/project/contract/quote/v2/getSubmitId?projectId=${projectId}`),
  // 查询合同清单标识列表
  getSchemeSiteListToTag: (data: { projectId: string }) =>
    request.get(`/project/project/contract/quote/v2/getIdentifyingList`, {
      params: {
        projectId: data.projectId,
      },
    }),

  addSite: (siteId: string, projectId: string, dataId: string) =>
    request.post(`/project/project/contract/quote/v2/addSite?siteId=${siteId}&projectId=${projectId}&dataId=${dataId}`),
  delSite: (siteId: string, projectId: string, dataId: string) =>
    request.post(`/project/project/contract/quote/v2/deleteSite?siteId=${siteId}&projectId=${projectId}&dataId=${dataId}`),
  getCanAddSiteList: (projectId: string, dataId: string) =>
    request.get(`/project/project/contract/quote/v2/getCanAddSiteList?dataId=${dataId}&projectId=${projectId}`),
}

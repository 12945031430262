import { request } from '@/utils'

export default {
  getEmptyUser: () => {
    return {}
  },
  saveUser: (data: any) => request.post('/system/user/add', data),
  getAllRole: () => request.get('/system/system/open/getRole'),

  entryPageList: (params: PageParams) =>
    request.post(`/hr/transaction/log/entryPageList?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}`, params.body),
  leavePageList: (params: PageParams) =>
    request.post(`/hr/transaction/log/leavePageList?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}`, params.body),
  regularPageList: (params: PageParams) =>
    request.post(`/hr/transaction/log/regularPageList?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}`, params.body),
  transferPageList: (params: PageParams) =>
    request.post(`/hr/transaction/log/transferPageList?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}`, params.body),
  getAllJob: () => request.get('/system/system/open/getJob'),
  getSelectUser: (data: any) => request.post('/system/system/open/getSelectUser', data),
  downloadEntry: (data: Recordable) =>
    request.post('/hr/transaction/log/entryPageListExport', data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),
  downloadLeave: (data: Recordable) =>
    request.post('/hr/transaction/log/leavePageListExport', data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),
  downloadRegular: (data: Recordable) =>
    request.post('/hr/transaction/log/regularExport', data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),
  downloadTransfer: (data: Recordable) =>
    request.post('/hr/transaction/log/transferExport', data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),
}

import { request } from '@/utils'

export default {
  getEmptyResidentTask: () => {
    return {
      id: null,
      taskName: null,
      source: null,
      formOfService: null,
      situationDesc: null,
      projectId: null,
      customerUnit: null,
      ownerCompany: null,
      serverTimeList: [
        {
          range: null,
          serviceStartTime: null,
          serviceEndTime: null,
        },
      ],
      imageIdList: [],
      customerContactId: [],
      mobileTaskAddressDTO: {
        country: null,
        province: null,
        city: null,
        region: null,
        address: null,
        completeAddress: null,
        longitude: null,
        latitude: null,
      },
      deptId: null,
      status: 0,
      followUserId: null,
      assistantUserId: [],
      meetingSurveyDatetime: null,
    }
  },
  getResidentTaskByPage: (params: PageParams) => {
    return request.post(`/ops/ops/residentTask/pageList?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`, params.body || {})
  },
  getResidentTaskById: (id: string) => request.get(`/project/customer/profile/getDetail/${id}`),

  getUserBySelectCode: (selectCode: string) => request.get(`/ops/ops/taskCustomer/getUserBySelectCode?selectCode=${selectCode}`),
  // saveResidentTask: (data: Recordable) => request.post('/om/resident/save', data),
  // updateResidentTask: (data: Recordable) => request.put('/om/resident/update', data),

  getSignList: (taskId: string, date: string) => request.get(`/om/resident/getSignList?id=${taskId}&date=${date}`),

  getInvoicingByTaskId: (id: string) => request.get(`/ops/ops/taskInvoicing/getInvoice?taskId=${id}`),
  addInvoicing: (data: any) => request.post('/ops/ops/taskInvoicing/openInvoice', data),
  editInvoicing: (data: any) => request.post('/ops/ops/taskInvoicing/editInvoice', data),

  getTaskSource: () => request.get('/system/diction/findByCode/ops_resident_task_source'),
  getTaskType: () => request.get('/system/diction/findByCode/ops_resident_task_service_style'),
  getTaskCompanyName: () => request.get('/system/diction/findByCode/ops_mobile_task_company_name'),
  getTaskStatus: () => request.get('/system/diction/findByCode/ops_resident_task_status'),
  getTaskProgression: () => request.get('/system/diction/findByCode/ops_task_progression'),
  getInvocingType: () => request.get('/system/diction/findByCode/ops_task_invocing_type'),
  getTaskSettleState: () => request.get('/system/diction/findByCode/ops_task_settle_state_code'),
  getDevicesBacktime: () => request.get('/system/diction/findByCode/ops_mobile_task_devices_backtime'),
  getUser: () => request.get('/system/user/findAll'),

  getCompanyTitle: () => request.post('/system/sys/department/list'),
  getCompanyNameList: () => request.get('/project/project/open/getCustomerCompany'),

  getOpsResidentUserTaskCount: () => request.get('/ops/ops/taskCustomer/getOpsResidentUserTaskCount'),
  getCustomerAll: () => request.get('/project/customer/profile/findAll'),
  getProjectAll: () => request.get('/project/project/open/getProjectFormSelectList'),

  getCustomerList: (id: string) => request.get(`/ops/ops/taskCustomer/getCustomerDropList?unitId=${id}`),
  addCustomer: (data: any) => request.post('/ops/ops/taskCustomer/saveCustomer', data),
  addResidentTask: (data: any) => request.post('/ops/ops/residentTask/submit', data),
  addResidentTaskDraft: (data: any) => request.post('/ops/ops/residentTask/saveDraft', data),
  editResidentTask: (data: any) => request.post('/ops/ops/residentTask/editTask', data),
  updateResidentTask: (data: any) => request.post('/ops/ops/residentTask/updateTask', data),
  getTaskByTaskId: (id: string) => request.get(`/ops/ops/residentTask/getDetail/${id}`),

  getServerReport: (id: string) => request.get(`/ops/ops/feedback/serverReport/${id}`),
  pushReport: (data: any) => request.post('/ops/ops/feedback/pushReport', data),
  getsHistoryServerReport: () => request.get('/ops/ops/feedback/queryHistoryServerReport'),
  taskSettle: (data: any) => request.post(`/ops/ops/confirmLetter/taskSettle?taskId=${data.taskId}&stateCode=${data.stateCode}`),
  getByResidentIdAndDate: (id: string, date: string, type: string) =>
    request.get(`/ops/ops/feedback/getByResidentId?residentId=${id}&residentDate=${date}&type=${type}`),
  getFeedbackOfTimePhase: (data: any) => request.post('/ops/ops/feedback/getFeedbackOfTimePhase', data),
  // zeng add
  getFeedBackNum: (id: string, date: string) => request.get(`ops/ops/feedback/getFeedBackNum?residentDate=${date}&residentId=${id}`),
  exportLog: (data: any) => request.post('/ops/ops/mobiletask/exportFeedBackData', data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),
  exportSign: (data: any) => request.post('/ops/ops/taskSign/export', data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),

  // 会议调试,会议保障汇总数据
  safeguardCollect: (params: any) => request.post('/ops/ops/residentTask/safeguardCollect', params),
  // 会议调试,会议保障查询列表
  safeguardPage: (params: any) =>
    request.post(`/ops/ops/residentTask/safeguardPage?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`, params.body || {}),
  // 会议调试,会议保障下载历史统计数据
  downAllData: (data?: any) => request.post(`/ops/ops/residentTask/downAllData`, data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),
  // 会议调试,会议保障下载勾选数据
  downSelectedData: (data?: any) =>
    request.post(`/ops/ops/residentTask/downSelectedData`, data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),

  // 配置任务的打卡时间段
  configTaskClockTimeSection: (data: any) => request.post('/ops/ops/sign_time_section/configTaskClockTimeSection', data),
  // 获取配置任务的打卡时间段
  getTaskClockTimeSection: (taskId: string) => request.get(`/ops/ops/sign_time_section/getTaskClockTimeSection/${taskId}`),
}

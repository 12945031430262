import type { FormItemRule } from 'naive-ui'

export default {
  empty: [
    {
      required: false,
    },
  ],
  required: [
    {
      type: 'string',
      required: true,
      message: '必填项',
      trigger: ['blur', 'change'],
    },
  ],
  arrayRequired: [
    {
      type: 'array',
      required: true,
      message: '必填项',
      trigger: ['blur', 'change'],
    },
  ],
  numberRequired: [
    {
      type: 'number',
      required: true,
      message: '必填项',
      trigger: ['blur', 'change'],
    },
  ],
  booleanRequired: [
    {
      type: 'boolean',
      required: true,
      message: '必填项',
      trigger: ['blur', 'change'],
    },
  ],
} as {
  empty: FormItemRule[]
  required: FormItemRule[]
  arrayRequired: FormItemRule[]
  numberRequired: FormItemRule[]
  booleanRequired: FormItemRule[]
}

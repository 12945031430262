<script setup lang="ts"></script>

<template>
  <n-grid x-gap="12" cols="9" item-responsive responsive="screen" overflow-hidden h-full>
    <n-grid-item span="s:3 m:2" class="h-full overflow-hidden">
      <n-card rounded-10 class="p-h h-full" content-style="overflow:hidden;">
        <n-scrollbar style="max-height: 100%">
          <slot v-if="$slots.tree" name="tree" />
        </n-scrollbar>
      </n-card>
    </n-grid-item>
    <n-grid-item span="s:6 m:7" class="h-full overflow-hidden">
      <n-card rounded-10 class="p-h h-full" content-style="overflow:hidden;">
        <n-scrollbar style="max-height: 100%">
          <slot />
        </n-scrollbar>
      </n-card>
    </n-grid-item>
  </n-grid>
</template>

import type { Router } from 'vue-router'
import { getToken, isNullOrWhitespace, refreshAccessToken } from '@/utils'
import { useUserStore } from '@/store'

const WHITE_LIST = [
  '/404',
  '/login',
  '/openWorkflow',
  '/om-report/conference-data-list',
  '/om-report/conference-debugging-list',
  '/om-report/inspection-report-list',
  '/om-report/defectior-equipment-list',
  '/om-report/fault-handle-list',
  '/om-report/other-work-list',
]
const RESET_PWD_PAGE = '/reset'
export function createPermissionGuard(router: Router) {
  router.beforeEach(async (to) => {
    const token = getToken()

    /** 没有token的情况 */
    if (isNullOrWhitespace(token)) {
      if (WHITE_LIST.includes(to.path)) {
        return true
      }

      return { path: 'login', query: { ...to.query, redirect: to.path } }
    }

    /** 有token的情况 */
    if (to.path === '/login') {
      return { path: '/' }
    }

    refreshAccessToken()
    return checkPwd(to.path)
  })
}

function checkPwd(path: string) {
  const userStore = useUserStore()
  if (!userStore.mustModifyPass || path === RESET_PWD_PAGE) {
    return true
  } else {
    return RESET_PWD_PAGE
  }
}

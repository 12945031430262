import type { RouteType, RoutesType } from '~/types/router'

// const useStaticRoute = import.meta.env.VITE_USE_STATIC_ROUTE === 'true'
const Layout = () => import('@/layout/index.vue')
const AppMain = () => import('@/layout/AppMain.vue')

function hasPermission(route: RouteType, authorities: Array<Recordable>) {
  // * 不需要权限直接返回true
  if (!route.meta?.requireAuth) {
    return true
  }

  const routePermissionCode = route.meta?.permissionCode ? route.meta.permissionCode : []

  // * 登录用户没有权限或者路由没有设置权限判定为没有权限
  if (!authorities.length || !routePermissionCode.length) {
    return false
  }

  // * 路由指定的权限编码包含任一登录用户权限则判定有权限
  return authorities.some((item) => routePermissionCode.includes(item.authority))
}

export function filterAsyncRoutes(routes: RoutesType = [], authorities: Array<Recordable>): RoutesType {
  const ret: RoutesType = []
  routes.forEach((route) => {
    if (hasPermission(route, authorities)) {
      const curRoute: RouteType = {
        ...route,
        children: [],
      }
      if (route.children && route.children.length) {
        curRoute.children = filterAsyncRoutes(route.children, authorities) || []
      } else {
        Reflect.deleteProperty(curRoute, 'children')
      }

      ret.push(curRoute)
    }
  })
  return ret
}

export function buildDynamicRoutes(modules: Recordable, menus: Array<Recordable>, baseUrl = ''): RoutesType {
  const ret: RoutesType = []
  menus.forEach((menu) => {
    const isVrMenu = menu.vrMenu || menu.name === 'test1_1'
    if (isVrMenu) {
      ret.push(...buildDynamicRoutes(modules, menu.children, menu.url ? `${menu.url}/` : ''))
      return
    }

    const component = menu.urlPage === 'Layout' ? Layout : menu.urlPage === 'AppMain' ? AppMain : modules[menu.urlPage]

    const curRoute: RouteType = {
      name: menu.name,
      path: baseUrl + menu.url,
      component,
      redirect: menu.redirect,
      isHidden: !menu.canShow,
      isShowParent: menu.isShowParent ?? false,
      children: [],
    }
    if (menu.icon || menu.title || menu.orderNo || menu.keepAlive) {
      curRoute.meta = {
        icon: menu.icon,
        title: menu.title,
        order: menu.orderNo,
        keepAlive: menu.keepAlive,
      }
    }
    if (menu.children && menu.children.length) {
      curRoute.children = buildDynamicRoutes(modules, menu.children) || []
    } else {
      Reflect.deleteProperty(curRoute, 'children')
    }

    ret.push(curRoute)
  })
  return ret
}

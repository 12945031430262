import { request } from '@/utils'

export default {
  getPurchasingBarQuery: () => {
    return {
      company: null,
      year: null,
      dimension: 'YEAR',
    }
  },
  getPurchasingOtherQuery: () => {
    return {
      company: null,
      year: null,
      quarter: null,
      month: null,
    }
  },
  getPurchasingBarData: (data: Recordable) => request.post(`/data/purchase/dashBord/order/purchaseAmountHistogram`, data),
  getPurchasingPieData: (data: Recordable) => request.post(`/data/purchase/dashBord/order/purchaseAmountProportion`, data),
  getPurchasingPayable: (data: Recordable) => request.post(`/data/purchase/dashBord/order/getAmountPayable`, data),
  getPurchasingPayInvoice: (data: Recordable) => request.post(`/data/purchase/dashBord/order/getReturnInvoice`, data),

  getPurchaseItemQuery: () => {
    return {
      companyCode: null,
      keyword: null,
      month: null,
      quarterly: null,
      year: null,
    }
  },
  getPurchaseItemTableData: (params: PageParams) =>
    request.post(`/data/purchase/dashBord/projects/statisticsForCompletedProject`, params.body || {}),

  getProjectPreProfitQuery: () => {
    return {
      keyword: null,
    }
  },
  getProjectPreProfitTableData: (params: PageParams) =>
    request.post(`/data/purchase/dashBord/projects/statisticsProjectEstimatedProfit`, params.body || {}),

  getSupplierQuery: () => {
    return {
      company: null,
      keyword: null,
      month: null,
      quarter: null,
      year: null,
      settleCycle: null,
      treeList: [],
      typeList: [],
    }
  },
  getSupplierTableData: (data: Recordable) => request.post(`/data/purchase/dashBord/order/topTenMoneyPartner`, data),
  getSupplierInfo: () => request.get(`/data/purchase/dashBord/order/partnerStatics`),
  getSupplierRatio: (data: Recordable) => request.post(`/data/purchase/dashBord/order/partnerRatio`, data),
  getSupplierDeliveryRatio: (data: Recordable) => request.post(`/data/purchase/dashBord/order/deliveryRatio`, data),

  getAnnualProductCategoys: () => request.post(`/data/purchase/dashBord/projects/statisticsPurchaseCategory`),
  getPurchaseInventory: () => request.post(`/data/purchase/dashBord/projects/statisticsPurchaseInventory`),
  getPurchaseServiceTree: () => request.get(`/purchase/purchase/supply/service/type/categoryCascadeQuery`),
}

import { request } from '@/utils'

export default {
  getEmptyDept: () => {
    return {
      id: null,
      name: null,
      pid: null,
      enabled: true,
      summary: null,
    }
  },
  getDeptTree: () => request.post('/system/sys/department/list'),
  getAllJob: () => request.get('/system/system/open/getJob'),
  saveDept: (data: any) => request.post('/system/sys/department/save', data),
  updateDept: (data: any) => request.post('/system/sys/department/update', data),
  deleteDept: (id: string) => request.get(`/system/sys/department/del/${id}`),
}

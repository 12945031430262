import type { RouteType } from '~/types/router'

export default {
  name: 'omReport',
  path: '/om-report',
  isHidden: true,
  meta: {
    order: 0,
    title: '报表',
  },
  children: [
    {
      name: 'conferenceDataList',
      path: 'conference-data-list',
      isHidden: true,
      component: () => import('./task/report/index.vue'),
      meta: {
        title: '会议数据',
      },
    },
    {
      name: 'conferenceDebuggingList',
      path: 'conference-debugging-list',
      isHidden: true,
      component: () => import('./task/report/index.vue'),
      meta: {
        title: '会议调试',
      },
    },
    {
      name: 'inspectionReportList',
      path: 'inspection-report-list',
      isHidden: true,
      component: () => import('./task/report/index.vue'),
      meta: {
        title: '巡检报告',
      },
    },
    {
      name: 'defectiorEquipmentList',
      path: 'defectior-equipment-list',
      isHidden: true,
      component: () => import('./task/report/index.vue'),
      meta: {
        title: '故障设备',
      },
    },
    {
      name: 'omFileManage',
      path: 'om-file-manage',
      isHidden: true,
      component: () => import('./task/report/index.vue'),
      meta: {
        title: '项目资料',
      },
    },
    {
      name: 'faultHandleList',
      path: 'fault-handle-list',
      component: () => import('./task/report/index.vue'),
      meta: {
        title: '故障处理',
      },
    },
    {
      name: 'otherWorkList',
      path: 'other-work-list',
      component: () => import('./task/report/index.vue'),
      meta: {
        title: '其他工作',
      },
    },
  ],
} as RouteType

import { useClipboard } from '@vueuse/core'
import { ref } from 'vue'
import { DialogPlugin, Input, MessagePlugin } from 'tdesign-vue-next'
import api from '../api'
import type { QuoteListType, RCopyData } from '../api'
export default function useCopy() {
  const { text, copy } = useClipboard({ legacy: true, read: true })
  const loading = ref(false)
  const pasteLoading = ref()
  function copyFun(
    type: 'QUOTE_SCHEME' | 'QUOTE_SITE' | 'QUOTE_LIST',
    copyData: Partial<QuoteListType[]>,
    quoteSchemeData?: { projectId: string; schemeId?: string; siteIdList?: string[] },
    siteData?: Partial<QuoteListType[]>
  ) {
    loading.value = true
    return api
      .getCopyCode({ quoteListData: { data: copyData }, quoteSchemeData, quoteSiteData: { data: siteData }, type })
      .then((res) => {
        copy(res.data.code)
        MessagePlugin.success('复制成功')
        return Promise.resolve(true)
      })
      .finally(() => {
        loading.value = false
        return Promise.resolve(true)
      })
  }

  function pasteFunApi(code: string, type: 'QUOTE_SCHEME' | 'QUOTE_SITE' | 'QUOTE_LIST') {
    pasteLoading.value = MessagePlugin.loading('正在获取复制数据')
    return api
      .getCopyData({ code })
      .then((res) => {
        if (res.data.type === type && res.data) {
          return Promise.resolve(res.data)
        } else {
          MessagePlugin.warning('无可用粘贴数据')
        }
      })
      .finally(() => {
        return Promise.resolve(false)
      })
  }

  function pasteFun(code: string, type: 'QUOTE_SCHEME' | 'QUOTE_SITE' | 'QUOTE_LIST'): Promise<boolean | RCopyData | undefined> {
    let codeStr = code
    if (!codeStr) {
      return new Promise((resolve) => {
        const str = ref('')
        const confirmDia = DialogPlugin({
          header: '提示',
          body: () =>
            h('div', {}, [
              h(Input, {
                autofocus: true,
                value: str.value,
                'onUpdate:value': (val: string) => (str.value = val),
                placeholder: '粘贴复制指令',
              }),
            ]),
          confirmBtn: '确定',
          cancelBtn: '取消',
          onConfirm: () => {
            codeStr = str.value
            if (!codeStr) {
              MessagePlugin.warning('没有复制指令，请复制数据')
              return
            }
            confirmDia.hide()
            resolve(pasteFunApi(codeStr, type))
          },
          onClose: () => {
            confirmDia.hide()
            resolve(false)
          },
        })
      })
    } else {
      return pasteFunApi(codeStr, type)
    }
  }

  return {
    pasteLoading,
    copyFun,
    pasteFun,
    loading,
    text,
  }
}

import { orderBy } from 'lodash-es'

export function deepFind(value: any, list: any[]) {
  function deep(arr: any[]): any | undefined {
    for (const e of arr) {
      if (e.value === value) {
        return e
      } else if (e.children) {
        const d = deep(e.children)
        if (d !== undefined) {
          return d
        }
      }
    }
    return undefined
  }

  return deep(list)
}

// 列表转树形
export function deepTree(list: any[], sort?: 'desc' | 'asc'): any[] {
  const newList: any[] = []
  const map: any = {}

  orderBy(list, 'orderNum', sort)
    .map((e) => {
      map[e.id] = e
      return e
    })
    .forEach((e) => {
      const parent = map[e.parentId]

      if (parent) {
        ;(parent.children || (parent.children = [])).push(e)
      } else {
        newList.push(e)
      }
    })

  return newList
}

import { useDebounceFn } from '@vueuse/core'
import { isFunction } from '@/utils'

interface PageParams {
  pageSize: number
  pageNumber: number
  // url查询
  query?: Recordable
  // 请求体查询
  body?: Recordable
  [key: string]: any
}

interface PageConfig {
  pageSize?: number
  pageNumber?: number
}

interface TableProps {
  api?: (...arg: any) => Promise<any>
  dataHandler?: (data: Recordable[]) => Recordable[]
  // 数据
  dataSource?: Recordable[]
  // url查询
  reqQuery?: Recordable
  // 请求体查询
  reqBody?: Recordable
  // 是否监听query
  watchQuery?: boolean
  // 监听回调函数
  watchFun?: () => void
  // 失败回调函数
  failFun?: (error: any) => void
  // 是否开启防抖
  debounced?: boolean
  debouncedTime?: number
  pageConfig?: PageConfig
}

export default function usePageTable(props: TableProps) {
  const tableLoading = ref<boolean>(false)
  const totalElements = ref<number>(0)
  const pageSize = ref<number>(props.pageConfig?.pageSize || 10)
  const pageNumber = ref<number>(props.pageConfig?.pageNumber || 1)
  const resultData = ref<Recordable[]>([])
  const colHeaders = ref<Recordable[]>()

  const tableData = computed(() => {
    if (props.dataHandler) {
      return props.dataHandler(resultData.value)
    } else return resultData.value
  })

  async function setupTable() {
    if (props) {
      if (props.api) {
        await loadDataByApi(true)
      } else
        watch(
          () => props.dataSource,
          () => (resultData.value = props.dataSource!)
        )
    }
  }

  async function loadDataByApi(reset?: boolean, body?: object) {
    const api = props.api
    if (!api || !isFunction(api)) {
      return
    }
    const cloneReqBody = props.reqBody ? JSON.parse(JSON.stringify(props.reqBody)) : {}
    try {
      tableLoading.value = true
      if (reset) {
        pageNumber.value = 1
        cloneReqBody.pageNumber = 1
        totalElements.value = 0
      }
      cloneReqBody.pageNumber = pageNumber.value
      cloneReqBody.pageSize = pageSize.value
      const params: PageParams = {
        pageSize: pageSize.value,
        pageNumber: pageNumber.value,
        query: props.reqQuery,
        body: cloneReqBody || body,
      }
      const res = await api(params)
      const { rows, total, pageNum, headers } = res.data
      resultData.value = rows || []
      totalElements.value = Number(total)
      pageNumber.value = pageNum
      cloneReqBody.pageNumber = pageNum
      colHeaders.value = headers
      // resultData.value = resolveRes(resp, [])
    } catch (error) {
      if (props.failFun) {
        props.failFun(error)
      } else {
        window.$message?.error('数据加载失败!')
      }
    } finally {
      tableLoading.value = false
    }
  }

  const debouncedFn = useDebounceFn(() => {
    loadDataByApi(true)
  }, props.debouncedTime || 800)

  watch(
    () => props.reqBody,
    () => {
      if (props.watchQuery) {
        debouncedFn()
        if (props.watchFun) {
          props.watchFun()
        }
      }
    },
    {
      deep: true,
    }
  )

  async function pageChange(page: number) {
    pageNumber.value = page
    await loadDataByApi()
  }

  async function sizeChange(size: number) {
    pageSize.value = size
    await loadDataByApi(true)
  }

  function clearTableData() {
    resultData.value = []
  }

  return {
    totalElements,
    pageSize,
    pageNumber,
    colHeaders,
    tableLoading,
    tableData,
    clearTableData,
    setupTable,
    pageChange,
    sizeChange,
    loadDataByApi,
    debouncedFn,
  }
}

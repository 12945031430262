import { request } from '@/utils'

export default {
  getContractInfoById: (projectId: string, requireId: string) =>
    request.get(`/project/project/contract/quote/detail/${projectId}/${requireId}`),
  getAuth: (projectId: string) => request.get(`/project/project/contract/quote/getAuth/${projectId}`),
  getDownloadConfig: (projectId: string) => request.get(`/project/project/contract/quote/getConfig/${projectId}`),
  getCollectFee: (projectId: string) => request.get(`/project/project/contract/quote/getCollectFee/${projectId}`),
  downloadPriceFile: (projectId: string, data: any) =>
    request.post(`/project/project/contract/quote/downQuoteFile/${projectId}`, data, { responseType: 'blob' }),
  getCanEdit: (projectId: string, requireId: string, id: string, type: string) =>
    request.get(`/project/project/contract/quote/getCanEdit/${projectId}/${requireId}?id=${id}&type=${type}`),
  priceSave: (projectId: string, requireId: string, data: any) => {
    return request.post(`/project/project/contract/quote/save/${projectId}/${requireId}`, data)
  },
  updateTotalAmount: (projectId: string) => request.get(`/project/project/contract/quote/getAutoQuoteDeviceTotalFee/${projectId}`),
  updateWorkHour: (projectId: string) => request.get(`/project/project/contract/quote/getAutoQuoteDeviceTotalInstallWorkHour/${projectId}`),
  saveCollectFee: (projectId: string, data: any) => request.post(`/project/project/contract/quote/saveCollectFee/${projectId}`, data),

  getUpdateLogs: (pageNumber: number, pageSize: number, data: any) =>
    request.post(`/project/project/contract/quote/log/list?pageNumber=${pageNumber}&pageSize=${pageSize}`, data),

  getQuoteDetailById: (id: string) => request.get(`/project/project/contract/quote/log/getQuoteDetail?id=${id}`),
  getCollectDetailById: (id: string) => request.get(`/project/project/contract/quote/log/getCollectFeeDetail?id=${id}`),

  // 检查可编辑数量是否合规
  checkCount: (projectId: string, requireId: string, params: any) =>
    request.get(`/project/project/contract/quote/checkCount/${projectId}/${requireId}`, { params }),
}

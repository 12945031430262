import { request } from '@/utils'

export default {
  /**
   * 空间查询
   */
  getEmptyQuery: (id?: string) => {
    return {
      name: undefined,
      id,
      createBy: [],
      startTime: '',
      endTime: '',
      fileFormat: [],
    }
  },
  /**
   * 我创建的查询
   */
  getEmptyCreateQuery: () => {
    return {
      name: undefined,
      createBy: [],
      startTime: '',
      endTime: '',
      fileFormat: [],
    }
  },
  /**
   * 我的收藏查询
   */
  getEmptyFavoriteQuery: () => {
    return {
      name: undefined,
      createBy: [],
      startTime: '',
      endTime: '',
      fileFormat: [],
    }
  },
  /**
   * 回收站查询
   */
  getEmptyRecycleQuery: () => {
    return {
      name: undefined,
      delUser: [],
      startTime: '',
      endTime: '',
      fileFormat: [],
    }
  },
  /**
   * 我的分享-我分享的查询
   */
  getEmptyIShareQuery: () => {
    return {
      name: undefined,
      startTime: '',
      endTime: '',
      fileFormat: [],
    }
  },
  /**
   * 我的分享-分享我的查询
   */
  getEmptyShareMeQuery: () => {
    return {
      name: undefined,
      startTime: '',
      endTime: '',
      fileFormat: [],
    }
  },
  /**
   * 空间列表
   * @param params 查询参数
   */
  getStorageTableData: (params: PageParams) => request.post('/file/doc/manager/childPageList', params.body || {}),
  /**
   * 导出空间列表
   * @param data 查询参数
   */
  exportStorageTableData: (data: Recordable) =>
    request.post('/file/doc/manager/export', data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),
  /**
   * 我创建的列表
   * @param params 查询参数
   */
  getStorageCreateTableData: (params: PageParams) => request.post(`/file/doc/manager/myCreate`, params.body || {}),
  /**
   * 我的收藏列表
   * @param params 查询参数
   */
  getStorageFavoriteTableData: (params: PageParams) => request.post('/file/doc/manager/collectPageList', params.body || {}),
  /**
   * 回收站列表
   * @param params 查询参数
   */
  getStorageRecycleTableData: (params: PageParams) => request.post('/file/doc/manager/cyclePageList', params.body || {}),
  /**
   * 我的分享-我分享的列表
   * @param params 查询参数
   */
  getStorageIShareTableData: (params: PageParams) => request.post('/file/doc/manager/mySharePageList', params.body || {}),
  /**
   * 我的分享-分享我的列表
   * @param params 查询参数
   */
  getStorageShareMeTableData: (params: PageParams) => request.post('/file/doc/manager/shareToMePageList', params.body || {}),
  /**
   * 详情-分享记录
   * @param params 查询参数
   */
  getStorageInfoShareTableData: (params: PageParams) => request.post('/file/doc/manager/shareRecord', params.body || {}),
  /**
   * 详情-下载记录
   * @param params 查询参数
   */
  getStorageInfoDownloadTableData: (params: PageParams) => request.post('/file/doc/manager/downPageList', params.body || {}),
  /**
   * 详情-浏览记录
   * @param params 查询参数
   */
  getStorageInfoBrowseTableData: (params: PageParams) => request.post('/file/doc/manager/viewPageList', params.body || {}),
  /**
   * 加载空间树
   * @param params 查询参数
   */
  loadSpaceTree: (params = {}) => request.post('/file/doc/manager/docTree', {}, { params }),
  /**
   * 加载文件详情
   * @param id 文件id
   */
  loadFileInfo: (id: string) => request.get(`/file/doc/manager/getDetail?id=${id}`),
  /**
   * 获取文件收藏状态
   * @param id 文件id
   */
  loadFavorite: (id: string) => request.get(`/file/doc/manager/hasCollected?id=${id}`),
  /**
   * 修改收藏状态
   * @param params 收藏dto
   */
  changeFavorite: (params: Recordable) => request.post(`/file/doc/manager/collect`, null, { params }),
  /**
   * 永久删除
   * @param id 文件id
   */
  foreverDel: (id: string) => request.post(`/file/doc/manager/permanentDel`, null, { params: { id } }),
  /**
   * 恢复回收站
   * @param id 文件id
   */
  recoverFile: (id: string) => request.post(`/file/doc/manager/recover`, null, { params: { id } }),
  /**
   * 文件夹预览
   * @param id 文件id
   */
  getPreviewInfo: (id: string) => request.get(`/file/doc/manager/folderAssistant?id=${id}`),
  /**
   * 重命名
   * @param data body
   */
  renameFile: (data: Recordable) => request.post(`/file/doc/manager/rename`, data),
  /**
   * 查询文件管理员
   * @param id 文件id
   */
  queryDocManager: (id: string) => request.post(`/file/doc/manager/queryDocManager?id=${id}`),
  /**
   * 更新文件管理员
   * @param data 更新save对象
   */
  updateDocManager: (data: Recordable) => request.post('/file/doc/manager/addDocManager', data),
  /**
   * 查询文件权限设置
   * @param id 文件id
   */
  queryDocScope: (id: string) => request.post(`/file/doc/manager/queryScope?id=${id}`),
  /**
   * 更新文件权限设置
   * @param data 更新save对象
   */
  updateDocScope: (data: Recordable) => request.post(`/file/doc/manager/addDocScope`, data),
  /**
   * 创建文件夹
   * @param data 更新save对象
   */
  createFolder: (data: Recordable) => request.post(`/file/doc/manager/createFolder`, data),
  /**
   * 下载文件/文件夹
   * @param id 文件id
   */
  downloadDoc: (id: string) => request.get(`/file/files/down?id=${id}`, { responseType: 'blob', timeout: 30 * 60 * 1000 }),
  /**
   * 下载分享文件/文件夹
   * @param id 文件id
   */
  downloadShareDoc: (id: string) => request.get(`/file/files/shareDown?shareLink=${id}`, { responseType: 'blob', timeout: 30 * 60 * 1000 }),
  /**
   * 生成分享链接
   * @param data 分享dto
   */
  genShareLink: (data: Recordable) => request.post('/file/doc/manager/share', data),
  /**
   * 获取文件基础描述
   * @param id 文件id
   */
  getFileInfo: (id: string) => request.get(`/file/doc/manager/getFileInfo?id=${id}`),
  /**
   * 更新文件基础描述
   * @param data 更新save对象
   */
  updateFileInfo: (data: Recordable) => request.post('/file/doc/manager/editFolder', data),
  /**
   * 校验文件充满
   * @param data 查询dto
   */
  checkUniqueFile: (data: Recordable) => request.post('/file/doc/manager/checkUniqueFile', data),
  /**
   * 复制或移动文件
   * @param data 查询dto
   */
  moveOrCopyFile: (data: Recordable) => request.post('/file/doc/manager/copyMove', data),
  /**
   * 普通删除
   * @param id 文件id
   */
  deleteFile: (id: string) => request.post(`/file/doc/manager/del?id=${id}`),
  /**
   * 查看分享链接
   * @param id 分享id
   */
  viewShareLink: (id: string) => request.get(`/file/doc/manager/shareLinkView?shareLink=${id}`),
  /**
   * 文件格式
   */
  selectFileFormat: () =>
    request.get(`/file/doc/manager/selectFileFormat`).then((res) => (res.data || []).map((item: string) => ({ label: item, value: item }))),
  /**
   * 查看分享子文件列表
   * @param params 查询参数
   */
  getStorageShareTableData: (params: PageParams) => request.post('/file/doc/manager/sharePageView', params.body || {}),
  /**
   * 作废分享
   * @param id 分享id
   */
  shareVoid: (id: string) => request.post(`/file/doc/manager/shareVoid?id=${id}`),
  /**
   * 评论列表
   * @param data 查询参数
   */
  commentPageList: (data: PageParams) => request.post(`/file/doc/manager/commentPageList`, data),
  /**
   * 添加评论
   * @param data 查询参数
   */
  addComment: (data: Recordable) => request.post(`/file/doc/manager/comment`, data),
  /**
   * 删除评论
   * @param id 评论id
   */
  delComment: (id: string) => request.post(`/file/doc/manager/delComment?id=${id}`),
  /**
   * 修改点赞状态
   * @param params 点赞参数
   */
  toggleLike: (params: Recordable) => request.post(`/file/doc/manager/postLike`, null, { params }),
  /**
   * 判断是否有一级文件夹权限
   */
  checkRootPermission: () => request.post(`/file/doc/manager/rootPermission`),
}

import { request } from '@/utils'

export default {
  queryDetail: (id: string) => request.get(`/warehouse/receiveGoods/getArrangeDetailToProjectManager/${id}`),
  confirmReceive: (data: any) => request.post('/purchase/category/project/saveArrangeDetail', data),
  revert: (ids: Array<string>) => request.post('/purchase/category/project/returnback', ids),
  refresh: (data: any) => request.post('/purchase/category/project/refreshMatch', data),
  getCategoryCascade: () => request.get('/purchase/purchase/supply/service/type/categoryCascadeQuery'),
  getBrandCascade: () => request.get('/purchase/purchase/supply/service/type/brandCascadeQuery'),
  getDirectionByCode: (code: string) => request.get(`/system/diction/findByCode/${code}`),
  download: (projectNo: string, projectName: string) =>
    request.post('/purchase/category/project/downloadTemplate', { projectNo, projectName }, { responseType: 'blob' }),
  getAllBrand: () => request.get('/purchase/purchase/supply/service/type/qeuryAllbrand'),
  getCategoryUnit: () => request.get('/system/diction/findByCode/purchase_category_unit'),
  pageListAll: (params: PageParams) =>
    request.post(
      `/purchase/purchase/category/opsApplyV2/pageListAll?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}`,
      params.body || {}
    ),
  pageListLaunched: (params: PageParams) =>
    request.post(
      `/purchase/purchase/category/opsApplyV2/pageListLaunched?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}`,
      params.body || {}
    ),
  pageListPurchased: (params: PageParams) =>
    request.post(
      `/purchase/purchase/category/opsApplyV2/pageListPurchased?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}`,
      params.body || {}
    ),
  pushOpsPurchase: (data: any) => request.post('/purchase/purchase/category/opsApplyV2/pushPurchase', data),
  getCategoryTagAndType3V2: (data: any) => request.post('/project/project/design/category/getCategoryTagAndType3V2', data),
  getCategoryBrand: (data: any) => request.post('/project/project/design/category/getCategoryBrand', data),
  getCategoryModel: (data: any) => request.post('/project/project/design/category/getCategoryModel', data),
  getCategoryImage: (params: any) => request.get('/project/project/design/category/getCategoryImage', { params }),
  getCategoryColors: (categoryId: string) => request.get(`/purchase/purchase/categoryV2/getCategoryColors?categoryId=${categoryId}`),
  cancelAuxiliary: (data: any) => request.post('/purchase/purchase/category/applyV2/cancelAuxiliary', data),
  getDetailLaunched: (id: any) => request.get(`/purchase/purchase/category/applyV2/getDetailLaunched?id=${id}`),
  editLaunched: (data: any) => request.post('/purchase/purchase/category/applyV2/editLaunched', data),
  getDetailCategory: (id: string) => request.get(`/purchase/purchase/categoryV2/getDetailCategory?id=${id}`),
  getOptAuthority: (projectId: string) => request.get(`/project/sys/project/infoContract/getOptAuthority?projectId=${projectId}`),
  getInspection: (purchaseId: string) => request.get(`/purchase/category/apply/getInspection?id=${purchaseId}`),
  getChangeOriginalApply: (applyId: string) => request.get(`/purchase/category/apply/getChangeOriginalApply?applyId=${applyId}`),
  getApplyAuditDetail: (applyId: string) => request.get(`/purchase/purchase/category/applyV2/getApplyAuditDetail?applyId=${applyId}`),
  getOpsPurchaseDetail: (applyId: string) =>
    request.get(`/purchase/purchase/category/opsApplyV2/getOpsPurchaseDetail?preApplyId=${applyId}`),
  editOpsPurchaseDetail: (data: any) => request.post('/purchase/purchase/category/opsApplyV2/editOpsPurchaseDetail', data),
  applyBack: (applyId: string) => request.get(`/purchase/purchase/category/applyV2/applyBack?applyId=${applyId}`),
}

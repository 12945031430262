import type { FormInst } from 'naive-ui'
import { cloneDeep } from 'lodash-es'
import { isNullOrWhitespace, renderIcon } from '@/utils'
import { useTabStore } from '@/store'

const ACTIONS = new Map([
  ['view', '查看'],
  ['edit', '编辑'],
  ['add', '新增'],
  ['del', '删除'],
])

export interface SomeThingOption {
  params: any
  // 多参数
  multi?: boolean
  doSomething: any
  content?: string
  successText?: string
  doRefresh?: any
  refreshParams?: any
}

export default function useCRUD(name: string, initForm: any = {}, convertForm?: any) {
  const rowKey = (row: any) => row.id
  const tabStore = useTabStore()
  const route = useRoute()
  const modalAction = ref('')
  const modalTitle = computed(() => ACTIONS.get(modalAction.value)! + name)
  const modalVisible = ref<boolean>(false)
  const formDisabled = ref<boolean>(false)
  const formLoading = ref<boolean>(false)
  const submitLoading = ref<boolean>(false)
  const formModelRef = ref<FormInst | null>(null)
  const formModel = ref({ ...initForm })
  const keyword = ref('')
  const tableLoading = ref<boolean>(false)
  const formSegmented = {
    content: 'soft',
    footer: 'soft',
  }

  const tableActions = [
    {
      label: '查看',
      key: 'view',
      icon: renderIcon('fa-solid:eye', { size: 14 }),
    },
    {
      label: '编辑',
      key: 'edit',
      icon: renderIcon('fa-solid:edit', { size: 14 }),
    },
    {
      label: '删除',
      key: 'delete',
      props: {
        style: 'color: #d03050;',
      },
      icon: renderIcon('fa-solid:trash-alt', { size: 14, color: '#d03050' }),
    },
  ]

  // 表格操作分发
  function handleSelect(actionMap: Map<string, any>, key: string, row: any) {
    const actionFn = actionMap.get(key)
    actionFn && actionFn(row)
  }

  /** 新增 */
  function handleAdd() {
    modalAction.value = 'add'
    modalVisible.value = true
    formDisabled.value = false
    if (convertForm) {
      formModel.value = convertForm(initForm)
    } else {
      formModel.value = cloneDeep(initForm)
    }
  }

  /** 修改 */
  function handleEdit(row: any) {
    modalAction.value = 'edit'
    modalVisible.value = true
    formDisabled.value = false
    if (convertForm) {
      formModel.value = convertForm(row)
    } else {
      formModel.value = { ...row }
    }
  }

  /** 查看 */
  function handleView(row: any) {
    modalAction.value = 'view'
    modalVisible.value = true
    formDisabled.value = true
    if (convertForm) {
      formModel.value = convertForm(row)
    } else {
      formModel.value = { ...row }
    }
  }

  /** 删除 */
  function handleDelete(id: string, doDelete: any, doRefresh?: any, refreshParams?: any) {
    if (isNullOrWhitespace(id)) {
      return
    }
    const d = window.$dialog?.warning({
      content: '确定删除？',
      positiveText: '确认',
      negativeText: '取消',
      onPositiveClick: async () => {
        if (d) {
          d.loading = true
        }
        try {
          await doDelete(id)
          window.$message?.success('删除成功')
          doRefresh && doRefresh(refreshParams)
        } catch (error) {
          if (d) {
            d.loading = false
          }
        }
      },
    })
  }

  /** 做些事情 */
  function handleSomeThing({
    params,
    multi,
    doSomething,
    content = '确定删除？',
    successText = '删除成功',
    doRefresh,
    refreshParams,
  }: SomeThingOption) {
    if (isNullOrWhitespace(params)) {
      return
    }
    const d = window.$dialog?.warning({
      content,
      positiveText: '确认',
      negativeText: '取消',
      onPositiveClick: async () => {
        if (d) {
          d.loading = true
        }
        try {
          if (multi) {
            await doSomething(...params)
          } else {
            await doSomething(params)
          }

          window.$message?.success(successText)
          doRefresh && doRefresh(refreshParams)
        } catch (error) {
          if (d) {
            d.loading = false
          }
        }
      },
    })
  }

  function navigateBack() {
    tabStore.removeTab(route.path)
  }

  return {
    keyword,
    modalVisible,
    modalAction,
    modalTitle,
    formDisabled,
    formModel,
    formModelRef,
    formLoading,
    submitLoading,
    formSegmented,
    tableActions,
    tableLoading,
    handleSelect,
    handleAdd,
    handleDelete,
    handleEdit,
    handleView,
    navigateBack,
    rowKey,
    handleSomeThing,
  }
}

import type { WorkLogTemplateFormItem } from '@/views/work-log/types'
import { request } from '@/utils'

export default {
  /**
   * 空会议调试内容表单
   */
  getEmptyDebugLog(formItem: WorkLogTemplateFormItem) {
    return {
      formCode: formItem.formCode,
      formId: formItem.id,
      _rangeTime: undefined,
      beginTime: '',
      customerName: '',
      endTime: '',
      attachments: [],
      meetingDebugRemark: '',
      meetingDebugResult: null,
      meetingName: '',
      meetingPlaceType: '',
      meetingRoom: '',
      meetingSite: '',
      meetingType: '',
      opsUserIds: [],
    }
  },
  /**
   * 空会议保障内容表单
   */
  getEmptyGuaranteeLog(formItem: WorkLogTemplateFormItem) {
    return {
      formCode: formItem.formCode,
      formId: formItem.id,
      beginTime: '',
      customerName: '',
      endTime: '',
      attachments: [],
      meetingGuaranteeRemark: null,
      meetingGuaranteeResult: null,
      meetingName: '',
      meetingPlaceType: '',
      meetingRoom: '',
      meetingSite: '',
      meetingType: '',
      opsUserIds: [],
    }
  },
  /**
   * 空会议巡检内容表单
   */
  getEmptyInspectionLog(formItem: WorkLogTemplateFormItem) {
    return {
      formCode: formItem.formCode,
      formId: formItem.id,
      beginTime: '',
      endTime: '',
      attachments: [],
      inspectionRemark: '',
      inspectionResult: null,
      inspectionSite: '',
      inspectionType: '',
      opsUserIds: [],
    }
  },
  /**
   * 空故障处理内容表单
   */
  getEmptyFaultDealLog(formItem: WorkLogTemplateFormItem) {
    return {
      formCode: formItem.formCode,
      formId: formItem.id,
      dealProcess: '',
      dealResult: null,
      faultRemark: '',
      attachments: [],
      meetingRoom: '',
      opsUserIds: [],
    }
  },
  /**
   * 空其他内容表单
   */
  getEmptyOtherLog(formItem: WorkLogTemplateFormItem) {
    return {
      formCode: formItem.formCode,
      formId: formItem.id,
      customerCanLook: '0',
      opsUserIds: [],
      workContent: '',
      serviceDept: '',
      workSubject: '',
    }
  },
  /**
   * 获取当前人相关的客户单位信息
   */
  getOpsUserList: (taskId?: string) =>
    request.get('/ops/log/userinfo/getUserOfOpsTask', {
      params: {
        taskId,
      },
    }),

  /**
   * 获取当前人相关项目-运维
   */
  getProjectList: (taskId?: string) => request.get('/ops/log/userinfo/getAllProjectOfCurrentUser', { params: { taskId } }),

  getJdProjectList: (taskId?: string) => request.get('/ops/log/userinfo/getMobileProjectOfCurrentUser', { params: { taskId } }),

  getZcProjectList: (taskId?: string) => request.get('/ops/log/userinfo/getResidentProjectOfCurrentUser', { params: { taskId } }),

  /**
   * 获取任务日志标题
   */
  getLogTitle: (data: Recordable) => request.post('/ops/log/ops_log/getOpsLogTitle', data),
}

import { defineStore } from 'pinia'
import { removeToken, setLocal, toLogin } from '@/utils'
import { usePermissionStore, useTabStore } from '@/store'
import { resetRouter } from '@/router'
import api from '@/api'
import { ResultEnum } from '@/enums/httpEnum'

interface UserInfo {
  id?: string
  account?: string
  name?: string
  nickName?: string
  avatar?: string
  mobile?: string
  mustModifyPass?: boolean
  role?: Array<string>
  menus?: Array<Recordable>
  authorities?: Array<Recordable>
  webSiteSet?: Recordable
}

export const useUserStore = defineStore('user', {
  state() {
    return {
      userInfo: <UserInfo>{},
    }
  },
  getters: {
    userId(): string {
      return this.userInfo.id || ''
    },
    account(): string {
      return this.userInfo.account || ''
    },
    name(): string {
      return this.userInfo.name || ''
    },
    nickName(): string {
      return this.userInfo.nickName || ''
    },
    mobile(): string {
      return this.userInfo.mobile || ''
    },
    avatar(): string {
      return this.userInfo.avatar || ''
    },
    role(): Array<string> {
      return this.userInfo.role || []
    },
    menus(): Array<Recordable> {
      return this.userInfo.menus || []
    },
    authorities(): Array<Recordable> {
      return this.userInfo.authorities || []
    },
    mustModifyPass(): boolean {
      return !!this.userInfo.mustModifyPass
    },
    webSiteSet(): Recordable {
      return this.userInfo.webSiteSet || {}
    },
  },
  actions: {
    async getUserInfo() {
      try {
        const res: any = await api.getLoginInfo()
        if (res.code === ResultEnum.SUCCESS) {
          const { id, account, name, nickName, avatar, role, menus, authorities, mobile, mustModifyPass, webSiteSet } = res.data
          this.userInfo = { id, account, name, nickName, avatar, role, menus, authorities, mobile, mustModifyPass, webSiteSet }
          this.asyncSiteCache()
          return Promise.resolve(res.data)
        } else {
          return Promise.reject(res)
        }
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async logout() {
      const { resetTabs } = useTabStore()
      const { resetPermission } = usePermissionStore()
      removeToken()
      resetPermission()
      resetTabs()
      await resetRouter()
      this.$reset()
      toLogin()
    },
    setUserInfo(userInfo = {}) {
      this.userInfo = { ...this.userInfo, ...userInfo }
    },
    getWatermarkProp(level: 'A' | 'B' | 'C') {
      return {
        text: `${this.name} ${this.mobile}   密级：${level}级`,
      }
    },
    asyncSiteCache() {
      if (this.userInfo.webSiteSet) {
        for (const key in this.userInfo.webSiteSet) {
          const data = this.userInfo.webSiteSet[key]
          if (key.startsWith('VXE_TABLE')) {
            localStorage.setItem(key, data)
          } else {
            setLocal(key, JSON.parse(data))
          }
        }
      }
    },
  },
})

import { request } from '@/utils'

export default {
  getEmptyUser: () => {
    return {}
  },
  getAllRole: () => request.get('/system/system/open/getRole'),

  announcementList: (params: PageParams) =>
    request.post(`/hr/proclamate/list?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}`, params.body),
  getReaderList: (params: PageParams) =>
    request.post(`/hr/proclamate/getReaderList?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}`, params.body),
  announcementAdd: (data: any) => request.post('/hr/proclamate/add', data),
  announcementUpdate: (data: any) => request.post('/hr/proclamate/update', data),
  announcementDelete: (id: string) => request.post(`hr/proclamate/delete?id=${id}`),
  announcementRecover: (id: string) => request.post(`hr/proclamate/recover?id=${id}`),
  getFileListByWorkflow: (data: any) => request.post('/hr/proclamate/getFileListByWorkflow', data),
  getUserProclamateList: (data: any) => request.post('/hr/proclamate/getUserProclamateList', data),
  publish: (id: string) => request.post(`hr/proclamate/publish?id=${id}`),
  recycle: (id: string) => request.post(`hr/proclamate/recycle?id=${id}`),
  downRecord: (fileRefId: string, id: string) => request.get(`hr/proclamate/downRecord?fileRefId=${fileRefId}&id=${id}`),
  announcementGet: (id: string) => request.get(`hr/proclamate/get?id=${id}`),
  getBrowseDetail: (id: string) => request.get(`hr/proclamate/getBrowseDetail?id=${id}`),
  getManageBrowseDetail: (id: string) => request.get(`hr/proclamate/getManageBrowseDetail?id=${id}`),
  getFileBrowseUrl: (id: string, type?: string) => request.get(`hr/proclamate/getFileBrowseUrl?id=${id}&type=${type}`),

  exportList: (data: Recordable) => request.post('/hr/proclamate/exportList', data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),
  getUserBySelectCode: (selectCode: string) => request.get(`/ops/ops/taskCustomer/getUserBySelectCode?selectCode=${selectCode}`),
  getSelectUser: (data: any) => request.post('/system/system/open/getSelectUser', data),
}

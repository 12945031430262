import { request } from '@/utils'

export default {
  getEmptyUser: () => {
    return {
      loginCode: '',
      address: '',
      userNo: '',
      birthdate: null,
      avatar: null,
      cardCode: '',
      cityId: '',
      countryId: '',
      depts: [],
      roles: [],
      jobs: [],
      directLeader: null,
      managerDepartmentIds: [],
      email: '',
      mobile: '',
      name: '',
      nickName: '',
      provinceId: '',
      pwd: '',
      qq: '',
      sex: null,
      weixin: '',
      enabled: 1,
    }
  },
  getUserByPage: (params: PageParams) => {
    if (params.body && Object.keys(params.body).length)
      return request.post(`/system/user/search?pageNum=${params.pageNumber}&pageSize=${params.pageSize}`, params.body)

    return request.get(`/system/user/list?pageNum=${params.pageNumber}&pageSize=${params.pageSize}`)
  },
  getUserList: (params: PageParams) =>
    request.post(`/system/user/getAllUser?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}`, params.body),
  getUserById: (id: string) => request.post('/system/user/search', { userId: id }),
  getUserByDeptId: (id: string) => request.post('/system/user/search', { deptId: id }),
  saveUser: (data: any) => request.post('/system/user/add', data),
  updateUser: (data: any) => request.post('/system/user/update', data),
  deleteUser: (id: string) => request.post(`/system/user/delete/${id}`),
  getUserRoles: () => request.get('system/user/listUserRoles?pageSize=500'),
  getUserDepts: () => request.get('system/user/listUserDepts?pageSize=500'),
  resetPassword: (id: string, newPassword: string) => request.post(`/system/user/pwd/reset/${id}?newPassword=${newPassword}`),
  restoreUser: (id: string) => request.post(`/system/user/restore/${id}`),
  getAllUser: () => request.post('/system/user/getAllUserNoPage'),
  getAllRole: () => request.get('/system/system/open/getRole'),
  getAllJob: () => request.get('/system/system/open/getJob'),
  unBindWxXcx: (userId: string) => request.post(`/system/user/cancelBindWxXcxOpenId?userId=${userId}`),
}

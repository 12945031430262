import type { TreeOption } from 'naive-ui'

import type { layoutProps } from '@/views/cloud/components/layout/context'

export type LayoutProps = ExtractPropTypes<typeof layoutProps>
export interface LayoutInjection {
  props: LayoutProps
}
export interface CollapseItem {
  label?: string
  value?: number
  type?: string
  icon?: string
  suffix?: VNode
}

export interface BreadcrumbItem {
  name: string
  icon?: string
  type: string
  id: string
}

export interface LayoutTreeItem extends TreeOption {
  id: string
}

export interface CloudDeptLevelItem {
  deptIds: string[]
  deptNames?: string
  level: string
  levelName?: string
}

export interface CloudTagItem {
  name: string
  color: string | null
}

export interface LayoutApi {
  getPreviewInfo: (id: string) => Promise<any>
  queryDocManager: (id: string) => Promise<any>
  updateDocManager: (data: Recordable) => Promise<any>
  renameFile: (data: Recordable) => Promise<any>
  queryDocScope: (id: string) => Promise<any>
  genShareLink: (data: Recordable) => Promise<any>
  getFileInfo: (id: string) => Promise<any>
  loadSpaceTree: (data: Recordable) => Promise<any>
  checkUniqueFile: (data: Recordable) => Promise<any>
  selectFileFormat: () => Promise<any>
  updateFileInfo: (data: Recordable) => Promise<any>
  commentPageList: (data: PageParams) => Promise<any>
  addComment: (data: Recordable) => Promise<any>
  delComment: (id: string) => Promise<any>
  toggleLike: (data: Recordable) => Promise<any>
  updateStudyTime: (data: Recordable) => Promise<any>
}

export interface DetailRow {
  label: string
  icon?: string
  [key: string]: any
}

export enum CollapseItemEnum {
  SPACE = 0,
  CREATE = 1,
  FAVORITE = 2,
  SHARE = 3,
  RECYCLE = 4,
  SHARE_SPACE = 9,
}

export interface AssistantDTOS {
  codes: string
  deptName: string
  id: string
  name: string
}


export interface ScopeVOS {
  codes: string
  scopeType: string
  id: string
  name: string
}

// 数组元素上移
export function upFn(index: number, array: any[]) {
  if (index === 0) {
    return array
  }
  // 将上一个数组元素值替换为当前元素值，并将被替换的元素值赋值给当前元素
  array[index] = array.splice(index - 1, 1, array[index])[0]
  return array
}

// 数组元素下移
export function downFn(index: number, array: any[]) {
  if (index === array.length - 1) {
    return array
  }
  // 将上下个数组元素值替换为当前元素值，并将被替换的元素值赋值给当前元素
  array[index] = array.splice(index + 1, 1, array[index])[0]
  return array
}

// 数组元素置顶
export function topFn(index: number, array: any[]) {
  if (index === 0) {
    return array
  }
  // 删除当前数组元素，并将被删除的值添加到数组开头
  array.unshift(array.splice(index, 1)[0])
  return array
}

// 数组元素置底
export function bottomFn(index: number, array: any[]) {
  if (index === array.length - 1) {
    return array
  }
  // 删除当前数组元素，并将被删除的值添加到数组末尾
  array.push(array.splice(index, 1)[0])
  return array
}

// 数组元素交换
export function exchangeFn(i: number, j: number, array: any[]) {
  array[i] = array.splice(j, 1, array[i])[0]
  return array
}

import type { PagerQuery } from './types'
import { request } from '@/utils'

export default {
  getEmptyDynamicForm: () => {
    return {
      id: '',
      name: '',
      remark: '',
    }
  },

  // 分页查询所有动态表单
  getDynamicFormPager: (query: PagerQuery) =>
    request.get(
      `/system/sys/dynamic/forms?pageNum=${query.pageNum}&pageSize=${query.pageSize}&name=${query.name}&num=${query.num ? query.num : ''}`
    ),

  getDynamicForm: (id: string) => request.get(`/system/sys/dynamic/forms/meta?id=${id}`),
  /* 查询动态表单文件下载信息 */
  getFormFileInfo: (id: string) => request.get(`/project/proj/file/getFormFileInfo?dictId=${id}`),
  /* 动态表单导出文件 */
  saveForm2File: (params: any) => request.post('/project/proj/file/saveForm2File', params),
  addDynamicForm: (params: any) => request.post('/system/sys/dynamic/forms', params),
  editDynamicForm: (params: any) => request.put('/system/sys/dynamic/forms', params),
  editDynamicFormTemplate: (params: any) => request.put('/system/sys/dynamic/forms/template', params),
  delDynamicForm: (id: string) => request.delete(`/system/sys/dynamic/forms/${id}`),

  getDynamicFormData: (formNum: number, businessId: string, extendId?: string) =>
    request.get(`/system/sys/dynamic/forms/datas?form_num=${formNum}&business_id=${businessId}&extend_id=${extendId || ''}`),
  saveDynamicFormData: (params: any) => request.post('/system/sys/dynamic/forms/datas', params),
}

import type { CreateAxiosDefaults } from 'axios'
import axios from 'axios'
import { reqReject, reqResolve, resReject, resResolve } from './interceptors'
import type { IAxiosInstance } from '~/types/axios'

export function createAxios(options: CreateAxiosDefaults = {}) {
  const defaultOptions = {
    timeout: 12000,
  }
  const service = axios.create({
    ...defaultOptions,
    ...options,
  })
  service.interceptors.request.use(reqResolve, reqReject)
  service.interceptors.response.use(resResolve, resReject)
  return service as IAxiosInstance
}

export const request = createAxios({
  baseURL: import.meta.env.VITE_BASE_API,
})

import { request } from '@/utils'
import type { RequestConfig } from '~/types/axios'

export default {
  // 根据项目查询会议室id
  getAssetPlaceList: (params: any) => request.get(`/ops/ops/asset/getAssetPlaceList?projectId=${params.projectId}`),

  // 会议调试,会议保障汇总数据
  safeguardCollect: (params: any) => request.post('/ops/ops/residentTask/safeguardCollect', params),
  // 会议调试,会议保障查询列表
  safeguardPage: (params: any) =>
    request.post(`/ops/ops/residentTask/safeguardPage?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`, params.body || {}),
  // 会场巡检列表
  inspectionPage: (params: any) =>
    request.post(`/ops/ops/residentTask/inspectionPage?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`, params.body || {}),
  // 会议调试,会议保障下载历史统计数据
  downAllData: (data?: any) => request.post(`/ops/ops/residentTask/downAllData`, data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),
  // 会议调试,会议保障下载勾选数据
  downSelectedData: (data?: any) =>
    request.post(`/ops/ops/residentTask/downSelectedData`, data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),
  // 下载历史统计数据(故障设备统计)
  downEquipmentAllData: (data?: any) =>
    request.post(`/ops/ops/residentTask/downEquipmentAllData`, data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),
  // 下载勾选数据(故障设备统计)
  downEquipmentData: (data?: any) =>
    request.post(`/ops/ops/residentTask/downEquipmentData`, data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),
  // 下载历史统计数据(故障维修记录)
  downEquipmentRepairAllData: (data?: any) =>
    request.post(`/ops/ops/residentTask/downEquipmentRepairAllData`, data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),
  // 下载勾选数据(故障维修记录)
  downEquipmentRepairData: (data?: any) =>
    request.post(`/ops/ops/residentTask/downEquipmentRepairData`, data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),
  // 下载勾选数据(会议调试)
  downMeetingDebugData: (data?: any) =>
    request.post(`/ops/ops/residentTask/downMeetingDebugData`, data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),
  // 下载勾选数据(会议调试)
  downMeetingDebugAllData: (data?: any) =>
    request.post(`/ops/ops/residentTask/downMeetingDebugAllData`, data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),
  // 下载巡检报告
  downInspectionReportData: (data?: any) =>
    request.post(`/ops/ops/residentTask/exportInspectionPage`, data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),

  // 故障设备记录数据统计
  equipmentCollect: (params: any) => request.post('/ops/ops/residentTask/equipmentCollect', params),
  // 故障设备记录列表
  equipmentPage: (params: any) =>
    request.post(`/ops/ops/residentTask/equipmentPage?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`, params.body || {}),
  // 设备维修数据详情分页列表
  assetRepairPage: (params: any) =>
    request.post(`/ops/ops/residentTask/assetRepairPage?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`, params.body || {}),
  // 设备维修数据详情
  equipmentRepairDetail: (params: any) => request.post(`/ops/ops/residentTask/equipmentRepairDetail`, params.body || {}),
  // 巡检列表上传人
  queryInspectionUploadUser: (params: any) => request.post(`/ops/ops/residentTask/queryInspectionUploadUser`, params.body || {}),

  getSelectUser: (data: any) => request.post('/system/system/open/getSelectUser', data),
  getFaultHandlingSum: (params: Recordable) => request.post('/ops/ops/residentTask/faultDealStatics', params),
  getFaultHandlingPage: (params: PageParams) => request.post('/ops/ops/residentTask/faultDealPage', params.body || {}),
  downFaultHandlingData: (data?: Recordable) =>
    request.post(`/ops/ops/residentTask/exportFaultDealStatics`, data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),

  getOtherWorkPage: (params: PageParams) => request.post('/ops/ops/residentTask/otherWorkPage', params.body || {}),
  downOtherWorkData: (data?: Recordable) =>
    request.post(`/ops/ops/residentTask/exportOtherWorkPage`, data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),

  getClientFaultHandlingPage: (params: PageParams) => request.post('/ops/client/customer/share/faultDealPage', params.body || {}),
  getClientFaultHandlingSum: (params: Recordable) => request.post('/ops/client/customer/share/faultDealStatics', params),
  downClientFaultHandlingData: (data?: Recordable) =>
    request.post(`/ops/client/customer/share/exportFaultDealStatics`, data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),

  getClientOtherWorkPage: (params: PageParams) => request.post('/ops/client/customer/share/otherWorkPage', params.body || {}),
  downClientOtherWorkData: (data?: Recordable) =>
    request.post(`/ops/client/customer/share/exportOtherWorkPage`, data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),

  // 设备维修数据详情分页列表(客户端)
  clientAssetRepairPage: (params: any) =>
    request.post(
      `/ops/client/customer/share/assetRepairPage?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`,
      params.body || {},
      { noNeedToken: true, noNeedTip: true } as RequestConfig
    ),
  // 会议调试,会议保障下载历史统计数据(客户端)
  clientDownAllData: (data?: any) =>
    request.post(`/ops/client/customer/share/downAllData`, data, {
      responseType: 'blob',
      timeout: 30 * 60 * 1000,
      noNeedToken: true,
      noNeedTip: true,
    } as RequestConfig),
  // 下载历史统计数据(故障设备统计)(客户端)
  clientDownEquipmentAllData: (data?: any) =>
    request.post(`/ops/client/customer/share/downEquipmentAllData`, data, {
      responseType: 'blob',
      timeout: 30 * 60 * 1000,
      noNeedToken: true,
      noNeedTip: true,
    } as RequestConfig),
  // 下载勾选数据(故障设备统计)(客户端)
  clientDownEquipmentData: (data?: any) =>
    request.post(`/ops/client/customer/share/downEquipmentData`, data, {
      responseType: 'blob',
      timeout: 30 * 60 * 1000,
      noNeedToken: true,
      noNeedTip: true,
    } as RequestConfig),
  // 下载历史统计数据(故障维修记录)(客户端)
  clientDownEquipmentRepairAllData: (data?: any) =>
    request.post(`/ops/client/customer/share/downEquipmentRepairAllData`, data, {
      responseType: 'blob',
      timeout: 30 * 60 * 1000,
      noNeedToken: true,
      noNeedTip: true,
    } as RequestConfig),
  // 下载勾选数据(故障维修记录)(客户端)
  clientDownEquipmentRepairData: (data?: any) =>
    request.post(`/ops/client/customer/share/downEquipmentRepairData`, data, {
      responseType: 'blob',
      timeout: 30 * 60 * 1000,
      noNeedToken: true,
      noNeedTip: true,
    } as RequestConfig),
  // 下载历史统计数据(会议调试)(客户端)
  clientDownMeetingDebugAllData: (data?: any) =>
    request.post(`/ops/client/customer/share/downMeetingDebugAllData`, data, {
      responseType: 'blob',
      timeout: 30 * 60 * 1000,
      noNeedToken: true,
      noNeedTip: true,
    } as RequestConfig),
  // 下载历史统计数据(会议调试)(客户端)
  clientDownMeetingDebugData: (data?: any) =>
    request.post(`/ops/client/customer/share/downMeetingDebugData`, data, {
      responseType: 'blob',
      timeout: 30 * 60 * 1000,
      noNeedToken: true,
      noNeedTip: true,
    } as RequestConfig),
  // 下载勾选数据,下载历史统计数据(客户端)
  clientDownSelectedData: (data?: any) =>
    request.post(`/ops/client/customer/share/downSelectedData`, data, {
      responseType: 'blob',
      timeout: 30 * 60 * 1000,
      noNeedToken: true,
      noNeedTip: true,
    } as RequestConfig),
  // 故障设备记录数据统计(客户端)
  clientEquipmentCollect: (params: any) =>
    request.post(`/ops/client/customer/share/equipmentCollect`, params || {}, { noNeedToken: true, noNeedTip: true } as RequestConfig),
  // 故障设备记录数据统计(客户端)
  clientEquipmentRepairDetail: (params: any) =>
    request.post(`/ops/client/customer/share/equipmentRepairDetail`, params || {}, { noNeedToken: true, noNeedTip: true } as RequestConfig),
  // 巡检列表上传人(客户端)
  clientQueryInspectionUploadUser: (params: any) =>
    request.post(`/ops/client/customer/share/queryInspectionUploadUser`, params || {}, {
      noNeedToken: true,
      noNeedTip: true,
    } as RequestConfig),
  // 会议调试,会议保障汇总数据(客户端)
  clientSafeguardCollect: (params: any) =>
    request.post(`/ops/client/customer/share/safeguardCollect`, params || {}, { noNeedToken: true, noNeedTip: true } as RequestConfig),
  // 故障设备记录列表(客户端)
  clientEquipmentPage: (params: any) =>
    request.post(
      `/ops/client/customer/share/equipmentPage?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`,
      params.body || {},
      { noNeedToken: true, noNeedTip: true } as RequestConfig
    ),
  // 会场巡检列表(客户端)
  clientInspectionPage: (params: any) =>
    request.post(
      `/ops/client/customer/share/inspectionPage?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`,
      params.body || {},
      { noNeedToken: true, noNeedTip: true } as RequestConfig
    ),
  // 会议调试,会议保障查询列表(客户端)
  clientSafeguardPage: (params: any) =>
    request.post(
      `/ops/client/customer/share/safeguardPage?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`,
      params.body || {},
      { noNeedToken: true, noNeedTip: true } as RequestConfig
    ),

  // 根据项目查询会议室id(客户端)
  clientAssetPlaceList: (params: any) => request.get(`/ops/client/customer/ops/asset/getAssetPlaceList?projectId=${params.projectId}`),
}

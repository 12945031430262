import type { FormInst } from 'naive-ui'
import { useDebounceFn } from '@vueuse/core'

export interface FormHandle {
  handleData?: Function
  handleRep?: Function
}

export interface FormOption<T> {
  primaryKey?: string
  saveApi?: (data: Recordable) => Promise<any>
  updateApi?: (data: Recordable) => Promise<any>
  submitHandle?: FormHandle
  ignoreTips?: boolean
  initForm: T
}

export default function useForm<T>(option: FormOption<T>) {
  // form实例
  const formRef = ref<FormInst | null>(null)
  // 加载状态
  const formLoading = ref<boolean>(false)
  // 绑定值
  const formModel = ref<T | Recordable>(option.initForm)

  /** 提交 */
  const handleSubmit = useDebounceFn(onSubmit, 500)

  function onSubmit() {
    formRef.value?.validate((errors: any) => {
      if (!errors) {
        formLoading.value = true
        let data: any = {}
        Object.assign(data, formModel.value)
        if (option.submitHandle && option.submitHandle.handleData) {
          const modifyData = option.submitHandle.handleData(data)
          if (modifyData) {
            data = modifyData
          }
        }
        let req
        let msg = '保存'
        const primaryKey = option.primaryKey ?? 'id'
        if (primaryKey) {
          // update
          if (data[primaryKey]) {
            if (option.updateApi) {
              msg = '修改'
              req = option.updateApi
            } else {
              throw new Error('updateApi not defind')
            }
          } else {
            // add
            console.log(option.saveApi)
            if (option.saveApi) {
              msg = '新增'
              req = option.saveApi
            } else {
              throw new Error('saveApi not defind')
            }
          }
        } else {
          throw new Error('primaryKey not defind')
        }
        if (req) {
          req(data)
            .then((res: any) => {
              if (res.code === 200) {
                if (!option.ignoreTips) {
                  window.$message?.success(`${msg}成功`)
                }

                option.submitHandle && option.submitHandle.handleRep && option.submitHandle.handleRep(res.data)
              }
            })
            .finally(() => {
              formLoading.value = false
            })
        }
      }
    })
  }

  return {
    formRef,
    formModel,
    formLoading,
    handleSubmit,
  }
}

import { request } from '@/utils'

export default {
  getDirectionByCode: (code: string) => request.get(`/system/diction/findByCode/${code}`),
  getBiddingNode: (projectId: string) => request.get(`/project/node/${projectId}`),
  // 招标准备工作
  addBiddingPrepare: (data: any) => request.post('/project/start', data),
  getBiddingPrepare: (projectId: string) => request.get(`/project/start/${projectId}`),
  editBiddingPrepare: (data: any) => request.put('/project/start', data),
  addJudge: (data: any) => request.post('/project/customer/profile/saveWithSysOut', data),
  getSelectUser: (data: any) => request.post('/system/system/open/getSelectUser', data),
  getCustomer: (data: any) => request.get(`/project/start/queryCustomer?bussModule=${data.bussModule}&projectId=${data.projectId}`),
  getMainPrincipal: (data: any) => request.post('/project/project/open/queryProjectUserAppoint', data),
  findAllWithSysOut: (projectId: string) => request.get(`/project/customer/profile/findAllWithSysOut/${projectId}`),
  // 生成招标文件
  addBiddingDocument: (data: any) => request.post('/project/document', data),
  getBiddingDocument: (projectId: string) => request.get(`/project/document/${projectId}`),
  editBiddingDocument: (data: any) => request.put('/project/document', data),
  // 标前会决议
  addBiddingMeeting: (data: any) => request.post('/project/before/meeting', data),
  getBiddingMeeting: (projectId: string) => request.get(`/project/before/meeting/${projectId}`),
  editBiddingMeeting: (data: any) => request.put('/project/before/meeting', data),
  exportMeeting: (projectId: string) => request.post(`/project/before/meeting/download/${projectId}`),
  // 标书制作管理
  addBiddingMade: (data: any) => request.post('/project/book/made', data),
  getBiddingMade: (projectId: string) => request.get(`/project/book/made/${projectId}`),
  editBiddingMade: (data: any) => request.put('/project/book/made', data),
  // 标书审核管理
  addBiddingAudit: (data: any) => request.post('/project/book/audit', data),
  getBiddingAudit: (projectId: string, bidRelationFile: any) =>
    request.get(`/project/book/audit?projectId=${projectId}&bidRelationFile=${bidRelationFile}`),
  editBiddingAudit: (data: any) => request.put('/project/book/audit', data),
  // 封标投标管理
  addBiddingArchive: (data: any) => request.post('/project/archive', data),
  getBiddingArchive: (projectId: string) => request.get(`/project/archive/${projectId}`),
  editBiddingArchive: (data: any) => request.put('/project/archive', data),

  getHistory: (data: any) =>
    request.get(
      `/project/change/log/queryLogs?bizCode=${data.bizCode}&id=${data.id}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`
    ),
  // getPickGoodsList: (params: PageParams) => request.post(`/purchase/category/project/pageList?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}`, params.body || {}),
  addBiddingEmployee: (data: any) => request.post('/project/employee', data),
  editBiddingEmployee: (data: any) => request.put('/project/employee', data),
  getBiddingEmployee: (projectId: string) => request.get(`/project/employee/${projectId}`),
  export: (projectId: string) => request.post(`/project/document/download/${projectId}`),
  getEvaluateType: (type: string) => request.get(`/project/rule/${type}`),
  getFileDetailOfDir: (data: any) => request.get(`/project/proj/file/getFileDetailOfDir/${data.projectId}/${data.dirCode}`),
  getBiddingPeople: (projectId: string) => request.get(`/project/archive/queryBidUsers?projectId=${projectId}`),
  getTendererPeople: (projectId: string) => request.get(`/project/project/open/getProjectJoinUserList?projectId=${projectId}`),
  calculateBasic: (data: any) => request.post('/project/book/made/basic/calculate', data),
  generaDocFile: (projectId: string) => request.post(`/project/document/generaDocFile/${projectId}`),
  downBidControllerDoc: (projectId: string) => request.get(`/project/start/downBidControllerDoc?projectId=${projectId}`),
  generaRequireDesc: (projectId: string) => request.post(`/project/document/generaRequireDesc/${projectId}`),
  getAllNodes: (projectId: string) => request.get(`/project/node/allNodes?projectId=${projectId}`),
  saveNodes: (data: any) => request.post('/project/node/saveOrUpdateProjNode', data),
  findApplyReport: (projectId: string) => request.post(`/project/start/findApplyReport?projectId=${projectId}`),
  getCanSelectQuoteScheme: (projectId: string) =>
    request.get(`/project/project/design/open/getCanSelectQuoteScheme?projectId=${projectId}`),
}

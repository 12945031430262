import { request } from '@/utils'

export default {
  getEmptyRecommendBrand: (categoryId?: string, name?: string) => {
    return {
      categoryId,
      brand: null,
      name,
    }
  },

  getCategory: () => request.get('/purchase/purchase/supply/service/type/categoryCascadeQuery'),
  getAllBrand: () => request.get('/purchase/purchase/supply/service/type/qeuryAllbrand'),
  getDetailPartnerPrice: (partnerId: string, categoryId: string) =>
    request.get(`/purchase/purchase/categoryV2/getDetailPartnerPrice?partnerId=${partnerId}&categoryId=${categoryId}`),
  getCategoryEnquiry: (params: any) =>
    request.get(
      `/purchase/purchase/categoryV2/categoryEnquiry?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}&categoryId=${params.categoryId}`,
      params.body || {}
    ),
  getDetailCategory: (id: string) => request.get(`/purchase/purchase/categoryV2/getDetailCategory?id=${id}`),
  getPreferential: () => request.get('/system/diction/findByCode/purchase_category_preferential'),
  getPartnerAll: () => request.get('/purchase/purchase/partner/findAll'),
  getTransportationExpenses: () => request.get('/system/diction/findByCode/purchase_category_transportation_expenses'),
  cascaderQueryByPid: (pId: any) => request.get(`/purchase/purchase/supply/service/type/brandCascadeQueryByPid?pId=${pId}`),
  queryTypeInfoBySuccession: (successionId: any) =>
    request.get(`/purchase/purchase/categoryV2/queryTypeInfoBySuccession?successId=${successionId}`),

  // 公共
  getDirectionByCode: (code: string) => request.get(`/system/diction/findByCode/${code}`),
  getCategoryCascade: () => request.get('/purchase/purchase/supply/service/type/categoryCascadeQuery'),
  getCanEditField: (categoryId: string, bussCode: string, partnerId: string | null) =>
    request.get(
      `/purchase/purchase/permission/config/getAllPermissionField?categoryId=${categoryId}&bussCode=${bussCode}&partnerId=${partnerId}`
    ),
  isAllFieldReadOnly: (categoryId: string, bussCode: string, partnerId: string | null) =>
    request.get(
      `/purchase/purchase/permission/config/isAllFieldReadOnly?categoryId=${categoryId}&bussCode=${bussCode}&partnerId=${partnerId}`
    ),

  // 货品列表
  toDoSupplementPageList: (body: PageParams) => {
    return request.post(
      `/purchase/purchase/supplement/toDoSupplementPageList?pageSize=${body.pageSize}&pageNumber=${body.pageNumber}`,
      body || {}
    )
  },
  exportToDoSupplementPageList: (data: any) =>
    request.post('/purchase/purchase/supplement/exportToDoSupplementPageList', data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),
  doneSupplementPageList: (body: PageParams) => {
    return request.post(
      `/purchase//purchase/supplement/doneSupplementPageList?pageSize=${body.pageSize}&pageNumber=${body.pageNumber}`,
      body || {}
    )
  },
  exportDoneSupplementPageList: (data: any) =>
    request.post('/purchase/purchase/supplement/exportDoneSupplementPageList', data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),

  editCategory: (data?: any) => request.post('/purchase/purchase/supplement/editCategory', data),

  // 报价表
  addPartnerPrice: (data?: any) => request.post('/purchase/purchase/supplement/addPartnerPrice', data),
  deletePartnerPrice: (data?: any) => request.post('/purchase/purchase/supplement/deletePartnerPrice', data),
  editPartnerPrice: (data?: any) => request.post('/purchase/purchase/supplement/editPartnerPrice', data),
}

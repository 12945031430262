import { defineStore } from 'pinia'
import { computed, reactive } from 'vue'
import { isArray } from 'lodash-es'
import { deepFind } from './utils'
import type { Dict } from './index.d'
import api from '@/api'

const useDict = defineStore('dict', () => {
  // 对象数据
  const data = reactive<Dict.Data>({})

  // 获取
  function get(name: string) {
    return computed(() => data[name] || [])
  }

  // 查找
  function find(name: string, value: any | any[]) {
    const arr = isArray(value) ? value : [value]
    return arr.filter((e) => e !== undefined).map((v) => deepFind(v, get(name).value))
  }

  // 刷新
  async function refresh(code?: string) {
    if (!code) {
      return
    }
    return api.getDictByCode(code).then((res) => {
      const d = {
        [code]: res.data.map((item: any) => ({
          label: item.name,
          value: item.code,
        })),
      }

      Object.assign(data, d)

      return data
    })
  }

  return {
    data,
    get,
    find,
    refresh,
  }
})

export { useDict }

import { request } from '@/utils'

export default {
  getDirectionByCode: (code: string) => request.get(`/system/diction/findByCode/${code}`),
  getSelectUser: (data: any) => request.post('/system/system/open/getSelectUser', data),

  getQueryOptions: () => request.get(`/data/data/repairReport/repairSelectData`),
  getRepairStartDeptOptions: () => request.post(`/system/sys/department/list`),
  getRepairApplyReportStatistics: (data: any) => request.post('/data/data/repairReport/repairNumPrjNumTaskNum', data),
  getRepairApplyReportPageData: (data: any) =>
    request.post(`/data/data/repairReport/allRepairReport?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`, data, {
      timeout: 300000,
    }),
  getRepairApplyReportExport: (data: any) =>
    request.post('/data/data/repairReport/repairReportExport', data, { responseType: 'blob', timeout: 300000 }),

  getEmptyOnSiteQuery: () => {
    return {
      followUserId: null,
      status: null,
      source: null,
      formOfService: null,
      serviceTimeStart: null,
      serviceTimeEnd: null,
      keyword: null,
    }
  },
  getOnSiteStatisticsTableData: (params: PageParams) => request.post(`/data/resident/report/work/content/reportList`, params.body || {}),
  downOnSiteStatisticsTableData: (data?: Recordable) =>
    request.post(`/data/resident/report/work/content/export`, data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),
  getOpsResidentUserTaskCount: () => request.get('/ops/ops/taskCustomer/getOpsResidentUserTaskCount'),
  getFaultHandlingSum: (params: Recordable) => request.post('/ops/ops/residentTask/faultDealStatics', params),
  getFaultHandlingPage: (params: PageParams) => request.post('/ops/ops/residentTask/faultDealPage', params.body || {}),
  downFaultHandlingData: (data?: Recordable) =>
    request.post(`/ops/ops/residentTask/exportFaultDealStatics`, data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),

  getOtherWorkPage: (params: PageParams) => request.post('/ops/ops/residentTask/otherWorkPage', params.body || {}),
  downOtherWorkData: (data?: Recordable) =>
    request.post(`/ops/ops/residentTask/exportOtherWorkPage`, data, { responseType: 'blob', timeout: 30 * 60 * 1000 }),
}

import { defineStore } from 'pinia'
import { useFullscreen } from '@vueuse/core'
import api from '@/api'
import type { TabItem } from '@/store'
import { ResultEnum } from '@/enums/httpEnum'

interface AppState {
  /** 重载页面 */
  reloadFlag: boolean
  /** 项目配置的抽屉可见状态 */
  showSettingDrawer: boolean
  /** 主体内容 */
  mainRef: any
  mainPageRef: any
  /** 服务器时差 */
  serverTimeDiff: any
  /** keepAlive路由的key，重新赋值可重置keepAlive */
  aliveKeys: any
  /** 关闭keepalive组件，利用exclude的优先级 */
  closeAliveKeys: any[]
}

export const useAppStore = defineStore('app', {
  state: (): AppState => ({
    reloadFlag: <boolean>true,
    showSettingDrawer: <boolean>false,
    mainRef: <any>null,
    mainPageRef: <any>null,
    serverTimeDiff: <number>0,
    aliveKeys: <any>{},
    closeAliveKeys: <any[]>[],
  }),
  actions: {
    /** 重载页面 */
    async reloadPage() {
      window.$loadingBar?.start()
      this.reloadFlag = false
      await nextTick()
      this.reloadFlag = true

      setTimeout(() => {
        document.documentElement.scrollTo({ left: 0, top: 0 })
        window.$loadingBar?.finish()
      }, 100)
    },
    /** 修改keepalive组件key */
    setAliveKeys(key: string, val: number) {
      this.aliveKeys[key] = val
    },
    /** 利用exclude的优先级动态修改exclude销毁keepalive组件 */
    removeAlive(tabItems: Array<TabItem>) {
      const keepAliveTabs = tabItems.filter((tabItem) => tabItem.meta?.keepAlive).map((tabItem) => tabItem.name)
      if (keepAliveTabs && keepAliveTabs.length) {
        this.closeAliveKeys.push(...keepAliveTabs)
        // 不能使用nextTick
        setTimeout(() => {}, 100)
      }
    },
    /** 打开内容全屏 */
    async contentFull() {
      const { toggle } = useFullscreen(this.mainRef)
      await toggle()
    },
    /** 获取系统配置 */
    async loadAppConfig() {
      try {
        const res: any = await api.getServerTime()
        if (res.code === ResultEnum.SUCCESS) {
          const serverTime = res.data
          this.serverTimeDiff = Number(serverTime) - new Date().getTime()
        }
      } catch (error) {
        console.error(error)
      }
    },
    /** 放入主题内容元素 */
    setMainRef(mainRef: any) {
      this.mainRef = mainRef
    },
    setMainPageRef(mainPageRef: any) {
      this.mainPageRef = mainPageRef
    },
    /** 打开设置抽屉 */
    openSettingDrawer() {
      this.showSettingDrawer = true
    },
    /** 关闭设置抽屉 */
    closeSettingDrawer() {
      this.showSettingDrawer = false
    },
  },
})

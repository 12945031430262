import { request } from '@/utils'

export default {
  /** 项目报价 */
  // 查询报价清单预览信息
  getBrowseQuoteAll: (projectId: string, schemeId: string) =>
    request.get('/project/project/design/quote/getBrowseQuoteAll', {
      params: {
        projectId,
        schemeId,
      },
    }),
  // 查询单个报价清单预览信息
  getQuoteDetail: (id: string) =>
    request.get('/project/project/design/log/getQuoteDetail', {
      params: {
        id,
      },
    }),
  // 查询报价清单设置信息
  getConfig: (projectId: string, schemeId: string) =>
    request.get('/project/project/design/quote/getConfig', {
      params: {
        projectId,
        schemeId,
      },
    }),
  downQuoteFileNormal: (projectId: string, schemeId: string, data: Recordable) =>
    request.post('/project/project/design/quote/downQuoteFileNormal', data, {
      params: {
        projectId,
        schemeId,
      },
      responseType: 'blob',
      timeout: 10 * 60 * 1000,
    }),
  /** 项目需求 */
  // 预览需求详情
  previewProjectRequire: (projectId: string, schemeId: string, siteId: string) =>
    request.get(`/project/project/design/require/browse`, {
      params: {
        projectId,
        schemeId,
        siteId,
      },
    }),
  // 查询项目方案下所有会场
  getSchemeSiteList: (projectId: string, schemeId: string) =>
    request.get('/project/project/design/scheme/getSchemeSiteList', {
      params: {
        projectId,
        schemeId,
      },
    }),
  downRequireFile: (params: Recordable) =>
    request.post('/project/project/design/require/downRequireFile', null, {
      params,
      responseType: 'blob',
      timeout: 10 * 60 * 1000,
    }),
}

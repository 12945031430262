import type { GlobalThemeOverrides } from 'naive-ui'
import type { RgbColor } from 'colord'
import { colord } from 'colord'
import themeSetting from '~/settings/theme.json'
import { addColorAlpha, getColorPalette, getLocal } from '@/utils'

type ColorType = 'primary' | 'info' | 'success' | 'warning' | 'error'
type ColorScene = '' | 'Suppl' | 'Hover' | 'Pressed' | 'Active'
type ColorKey = `${ColorType}Color${ColorScene}`
type ThemeColor = Partial<Record<ColorKey, string>>

interface ColorAction {
  scene: ColorScene
  handler: (color: string) => string
}

/** 初始化主题配置 */
export function initThemeSettings(): Theme.Setting {
  const storageSettings = getLocal('themeSettings')
  if (storageSettings) {
    return storageSettings as Theme.Setting
  }

  const isMobile = themeSetting.isMobile || false
  const darkMode = themeSetting.darkMode || false
  const layout = themeSetting.layout || { mode: 'vertical' }
  const sider = themeSetting.sider || { width: 220, collapsedWidth: 64, collapsed: false, inverted: false }
  const header = themeSetting.header || { visible: true, height: 60, inverted: false }
  const tab = themeSetting.tab || { visible: true, height: 50, mode: 'default' }
  const primaryColor = themeSetting.primaryColor || '#316C72'
  const otherColor = themeSetting.otherColor || {
    info: '#0099ad',
    success: '#52c41a',
    warning: '#faad14',
    error: '#f5222d',
  }
  const menuMsg = {}
  return { isMobile, darkMode, sider, layout, header, tab, primaryColor, otherColor, menuMsg }
}

/** 获取naive的主题颜色 */
export function getNaiveThemeOverrides(colors: Record<ColorType, string>): GlobalThemeOverrides {
  const { primary, info, success, warning, error } = colors

  const themeColors = getThemeColors([
    ['primary', primary],
    ['info', info],
    ['success', success],
    ['warning', warning],
    ['error', error],
  ])

  const colorLoading = primary

  return {
    common: {
      ...themeColors,
      placeholderColorDisabled: '#c2c2c2',
      textColorDisabled: '#c2c2c2',
    },
    LoadingBar: {
      colorLoading,
    },
  }
}

/** 获取主题颜色的各种场景对应的颜色 */
function getThemeColors(colors: [ColorType, string][]) {
  const colorActions: ColorAction[] = [
    { scene: '', handler: (color) => color },
    { scene: 'Suppl', handler: (color) => color },
    { scene: 'Hover', handler: (color) => getColorPalette(color, 5) },
    { scene: 'Pressed', handler: (color) => getColorPalette(color, 7) },
    { scene: 'Active', handler: (color) => addColorAlpha(color, 0.1) },
  ]

  const themeColor: ThemeColor = {}

  colors.forEach((color) => {
    colorActions.forEach((action) => {
      const [colorType, colorValue] = color
      const colorKey: ColorKey = `${colorType}Color${action.scene}`
      themeColor[colorKey] = action.handler(colorValue)
    })
  })

  return themeColor
}

/**
 * 将带有透明度的颜色转换成相近的没有透明度的颜色
 * @param color - 颜色
 * @param alpha - 透明度(0 - 1)
 * @param bgColor 背景颜色(一般是白色或者黑色)
 */
export function transformColorWithOpacity(color: string, alpha: number, bgColor = '#ffffff') {
  const originColor = addColorAlpha(color, alpha)
  const { r: oR, g: oG, b: oB } = colord(originColor).toRgb()

  const { r: bgR, g: bgG, b: bgB } = colord(bgColor).toRgb()

  function calRgb(or: number, bg: number, al: number) {
    return bg + (or - bg) * al
  }

  const resultRgb: RgbColor = {
    r: calRgb(oR, bgR, alpha),
    g: calRgb(oG, bgG, alpha),
    b: calRgb(oB, bgB, alpha),
  }

  return colord(resultRgb).toHex()
}

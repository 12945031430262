import { request } from '@/utils'

export default {
  // 货品分类
  getAllCategory: () => request.get('/purchase/purchase/supply/service/typeV2/typeCascadeQuery'),
  // 品牌系列型号
  getBrandSeriesModelList: () => request.get('/purchase/purchase/categoryV2/brandCascade'),

  // 根据采购批次获取供货方订单明细
  getBatchOrderPartnerDetailByOrderCode: (data: Recordable) => request.post('/purchase/category/order/queryBatchOrder', data),
  confirmPartnerConfirmListByOrderCode: (data: Recordable) => request.post('/purchase/category/order/commitBatchOrder', data),
  rollbackOrder: (orderCode: string) => request.get(`/purchase/category/order/waitPayOrderDraw?orderCode=${orderCode}`),
  submitInvoice: (data: Recordable) => request.post('/purchase/category/order/doInvoice', data),

  /**
   * *********************  采购订单  --  待付款  *********************
   */
  getWaitPaidEmptyQuery: () => {
    return {
      brandSeriesModelIds: [],
      type: [],
      createBy: [],
      payStatus: null,
      settleCycle: null,
      shippingStatus: null,
      keyword: null,
      pp: null,
      xl: null,
      xh: null,
      sortName: null,
      sortOrder: null,
    }
  },
  getWaitPaidOrderByPage: (params: PageParams) => request.post(`/purchase/category/order/waitPayPage`, params.body || {}),
  downloadWaitPaidOrderByPage: (data: Recordable) =>
    request.post('/purchase/category/apply/exportApplyAll', data, { responseType: 'blob' }),

  /**
   * *********************  采购订单  --  采购中  *********************
   */
  getInPurchasingEmptyQuery: () => {
    return {
      brandSeriesModelIds: [],
      type: [],
      createBy: [],
      settleCase: null,
      payPurchaseName: null,
      keyword: null,
      pp: null,
      xl: null,
      xh: null,
      sortName: null,
      sortOrder: null,
    }
  },
  getInPurchasingOrderByPage: (params: PageParams) => request.post(`/purchase/category/order/purchasingPage`, params.body || {}),
  downloadInPurchasingOrderByPage: (data: Recordable) =>
    request.post('/purchase/category/apply/exportApplyAll', data, { responseType: 'blob' }),

  /**
   * *********************  采购订单  --  已完成  *********************
   */
  getCompletedEmptyQuery: () => {
    return {
      brandSeriesModelIds: [],
      type: [],
      createBy: [],
      settleCase: null,
      invoiceStatus: null,
      payPurchaseName: null,
      keyword: null,
      pp: null,
      xl: null,
      xh: null,
      sortName: null,
      sortOrder: null,
    }
  },
  getCompletedOrderByPage: (params: PageParams) => request.post(`/purchase/category/order/orderCompletePage`, params.body || {}),
  downloadCompletedOrderByPage: (data: Recordable) =>
    request.post('/purchase/category/apply/exportApplyAll', data, { responseType: 'blob' }),

  /**
   * *********************  采购订单  --  已开票  *********************
   */
  getHasInvoiceEmptyQuery: () => {
    return {
      brandSeriesModelIds: [],
      type: [],
      createBy: [],
      settleCase: null,
      receiveEndDate: null,
      receiveStartDate: null,
      keyword: null,
      pp: null,
      xl: null,
      xh: null,
      sortName: null,
      sortOrder: null,
    }
  },
  getHasInvoiceOrderByPage: (params: PageParams) => request.post(`/purchase/category/order/billingPage`, params.body || {}),
  downloadHasInvoiceOrderByPage: (data: Recordable) =>
    request.post('/purchase/category/apply/exportApplyAll', data, { responseType: 'blob' }),

  /**
   * *********************  采购订单  --  开票记录  *********************
   */
  getInvoiceHistoryEmptyQuery: () => {
    return {
      auditStatus: null,
      keyword: null,
      sortName: null,
      sortOrder: null,
    }
  },
  getInvoiceHistoryOrderByPage: (params: PageParams) => request.post(`/purchase/category/order/invoiceHistory`, params.body || {}),

  getBatchPayItems: (data: string[]) => request.post(`/purchase/category/order/queryBatchPayItem`, data),
  saveBatchPayItems: (data: Recordable) => request.post(`/purchase/category/order/batchPay`, data),
  getInvoiceCheckList: (data: string[]) => request.post(`/purchase/category/order/selectInvoiceCheckList`, data),
}

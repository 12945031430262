import { router } from '@/router'

export function toLogin(needRedirectParams = false) {
  const currentRoute = unref(router.currentRoute)
  // TODO 暂时用不上 const needRedirect = !currentRoute.meta.requireAuth && !['/404', '/login'].includes(router.currentRoute.value.path)
  const needRedirect = needRedirectParams
  router.replace({
    path: '/login',
    query: needRedirect ? { ...currentRoute.query, redirect: currentRoute.path } : {},
  })
}

export function toFourZeroFour() {
  router.replace({
    path: '/404',
  })
}
